/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in a user
 * settings flow.
 */

import routes from "../routes";

export default {
  states: {
    [routes.user.convertToEmployer]: {
      meta: {},
      on: {
        PREVENT_CONVERSION: routes.applications.getReady,
      },
    },
    [routes.user.convertToEmployee]: {
      meta: {},
      on: {
        PREVENT_CONVERSION: routes.employers.applications,
      },
    },
    [routes.user.consentToDataSharing]: {
      meta: {},
      on: {
        ENABLE_MFA: routes.twoFactor.smsIndex,
        // Route to Applications page to support users who are re-consenting.
        // If they're new users with no claims, the Applications page will
        // handle redirecting them
        CONTINUE: routes.applications.index,
      },
    },
    [routes.user.settings]: {
      on: {},
    },
    [routes.user.confirmPassword]: {
      on: {
        CONTINUE: routes.user.changeEmail,
        ENABLE_MFA: routes.user.changeEmail,
        VERIFY_CODE: routes.user.verifySMS,
      },
    },
    [routes.user.verifySMS]: {
      on: {
        CONTINUE: routes.user.changeEmail,
      },
    },
    [routes.user.changeEmail]: {
      on: {
        CONTINUE: routes.user.verifyNewEmail,
        REAUTH: routes.user.confirmPassword,
      },
    },
    [routes.user.verifyNewEmail]: {
      on: {
        CONTINUE: routes.user.settings,
      },
    },
    [routes.user.contactInfo]: {
      on: {
        CONTINUE: routes.employers.welcome,
      },
    },
    [routes.user.taxDocuments]: {
      on: {
        START_MFA_SETUP: routes.twoFactor.smsSetup,
      },
    },
  },
};

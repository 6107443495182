import { Auth, CognitoUser } from "@aws-amplify/auth";
import {
  CognitoAuthError,
  DFPRejectError,
  ValidationError,
  isCognitoError,
} from "../errors";
import {
  NullableQueryParams,
  createRouteWithQuery,
} from "../utils/routeWithParams";
import { useMemo, useState } from "react";

import { ErrorsLogic } from "./useErrorsLogic";
import FraudProtectionApi from "../api/FraudProtectionApi";
import { FraudProtectionType } from "src/models/RiskAssessment";
import { MMGToPortalLanguageMap } from "src/models/LanguageEnum";
import OAuthApi from "src/api/OAuthApi";
import OAuthFlowStart from "../models/OAuth";
import OAuthTokenApi from "src/api/OAuthTokenApi";
import { PortalFlow } from "./usePortalFlow";
import UsersApi from "../api/UsersApi";
import assert from "assert";
import combineValidationIssues from "src/utils/combineValidationIssues";
import getLoginError from "src/utils/getLoginError";
import { isFeatureEnabled } from "../services/featureFlags";
import oidc from "src/services/openIdConnect";
import routes from "../routes";
import tracker from "../services/tracker";
import validateCode from "../utils/validateCode";
import validatePassword from "../utils/validatePassword";
import validateUsername from "../utils/validateUsername";

interface MFAChallenge {
  challengeName: string;
  challengeParam: { CODE_DELIVERY_DESTINATION: string };
}
type CognitoMFAUser = CognitoUser & {
  preferredMFA: "NOMFA" | "SMS";
} & MFAChallenge;

const useAuthLogic = ({
  errorsLogic,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const usersApi = useMemo(() => new UsersApi(), []);
  const fraudProtectionApi = useMemo(() => new FraudProtectionApi(), []);
  const oAuthApi = useMemo(() => new OAuthApi(), []);
  const oAuthTokenApi = useMemo(() => new OAuthTokenApi(), []);
  const statusTypes = new Map([
    ["Approve", "Approved"],
    ["Reject", "Rejected"],
  ]);

  // TODO (CP-872): Rather than setting default values for authLogic methods,
  // instead ensure they're always called with required string arguments

  // deprecating this, leave empty until it can be removed
  const authData = {};

  const [cognitoUser, setCognitoUser] = useState<CognitoMFAUser>();

  /**
   * @property isLoggedIn - Whether the user is logged in or not, or null if logged in status has not been checked yet
   */
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  /**
   * Check if the phone number used by the user for MFA has been verified.
   * You can't rely on the presence of MFA preference or the phone number to signify this.
   */
  const isPhoneVerified = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const phone_number_verified = attributes.phone_number_verified;

    tracker.trackEvent("Checked phone_number_verified", {
      // Useful for identifying how common it is for someone to not have
      // a verified phone number on pages where we check this.
      phone_number_verified,
    });

    return phone_number_verified;
  };

  /**
   * Log in to Portal with the given username (email) and password.
   * If the user has MFA configured, an SMS with a 6-digit verfication code will be sent
   * to the phone number on file in Cognito.
   * If there are any errors, set app errors on the page.
   * @param password Password
   * @param [next] Redirect url after login
   * @param trackingContext context for tracker in new relic for how method is being called
   */
  const login = async (
    username = "",
    password: string,
    deviceContext: string,
    next?: string,
    trackingContext?: string
  ) => {
    errorsLogic.clearErrors();
    const trimmedUsername = username ? username.trim() : "";

    const validationIssues = combineValidationIssues(
      validateUsername(trimmedUsername),
      validatePassword(password)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    try {
      trackingContext === "login" && tracker.trackAuthRequest("signIn");
      const currentUser = await Auth.signIn(trimmedUsername, password);
      setCognitoUser(currentUser);
      tracker.markFetchRequestEnd();

      const enableFraudProtection = isFeatureEnabled("enableFraudProtection");

      if (enableFraudProtection) {
        // Perform Risk Assessment
        const trimmedEmail = username.trim();
        const requestDataLoginAccount = {
          email_address: trimmedEmail,
          device_context_id: deviceContext,
          risk_assessment_type: FraudProtectionType.loginAccount,
          locale: "en",
        };
        const response = await fraudProtectionApi.assessRisk(
          requestDataLoginAccount
        );

        // Report Risk Assessment Status Back To DFP
        if (response?.fraud?.decision) {
          const requestDataLoginAccountStatus = {
            email_address: trimmedEmail,
            device_context_id: deviceContext,
            risk_assessment_type: FraudProtectionType.loginAccountStatus,
            status_type: statusTypes.get(response.fraud.decision),
            reason_type: "None",
            challenge_type: "None",
            login_id: response?.fraud.login_id,
            locale: "en",
          };
          await fraudProtectionApi.assessRisk(requestDataLoginAccountStatus);

          const enableDFPRejectAction = isFeatureEnabled(
            "enableDFPRejectAction"
          );

          if (enableDFPRejectAction && response.fraud.decision === "Reject") {
            if (trackingContext === "changeEmail") {
              /* This tracking context "changeEmail" is actually from confirm password page. 
                 If the DFP call fails there, then the user is logged out.
              */
              await logout({ sessionTimedOut: false, isRedirect: true });
              errorsLogic.catchError(
                new DFPRejectError({
                  type: "dfpRejectAction",
                  namespace: "dfp",
                })
              );
              return;
            }
            await logout({ sessionTimedOut: false, isRedirect: false });
            errorsLogic.catchError(
              new DFPRejectError({ type: "dfpRejectAction", namespace: "dfp" })
            );
            return;
          }
        }
        // Track Event
      }
      const mfaChallenge =
        currentUser.challengeName && currentUser.challengeName === "SMS_MFA";
      const mfaEnabled = currentUser.mfa_delivery_preference;
      trackingContext === "changeEmail" &&
        tracker.trackEvent("Change Email - Verified Password", {
          mfaEnabled: mfaEnabled !== "SMS" ? "false" : "true",
          mfaChallenge: mfaChallenge ? "true" : "false",
        });

      if (mfaChallenge) {
        portalFlow.goToPageFor("VERIFY_CODE", {}, { next });
      } else {
        const apiUser = await usersApi.getCurrentUser();
        const shouldSetMFA = apiUser.user.mfa_delivery_preference === null;
        finishLoginAndRedirect(next, shouldSetMFA);
      }
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }

      if (error.code === "UserNotConfirmedException") {
        portalFlow.goToPageFor("UNCONFIRMED_ACCOUNT");
        return;
      }

      const authError = getLoginError(error);
      errorsLogic.catchError(authError);
    }
  };

  const enableTrustDevice = async () => {
    try {
      await Auth.rememberDevice();
      tracker.trackEvent("trust_device success");
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Verifies the 6-digit MFA code and logs the user into the Portal.
   * If there are any errors, set app errors on the page.
   * @param code The 6-digit MFA verification code
   * @param [next] Redirect url after login
   * @param trackingContext context for tracker in new relic for how method is being called
   */
  const verifyMFACodeAndLogin = async (
    code: string,
    next?: string,
    trustDevice?: boolean,
    trackingContext?: string
  ) => {
    errorsLogic.clearErrors();
    const trimmedCode = code ? code.trim() : "";
    const validationIssues = combineValidationIssues(validateCode(trimmedCode));
    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    try {
      trackingContext === "login" && tracker.trackAuthRequest("confirmSignIn");
      await Auth.confirmSignIn(cognitoUser, trimmedCode, "SMS_MFA");
      tracker.markFetchRequestEnd();
      trackingContext === "login" &&
        tracker.trackEvent("checked trust_device", {
          // Useful for identifying how common it is for someone to trust their device.
          trust_device:
            trustDevice != null ? trustDevice.toString() : "undefined",
        });
      trackingContext === "changeEmail" &&
        tracker.trackEvent("Change Email - Verified MFA Code", {
          mfaEnabled: "true",
        });
      if (trustDevice) {
        enableTrustDevice();
      }
    } catch (error) {
      if (!isCognitoError(error)) {
        errorsLogic.catchError(error);
        return;
      }
      if (error.message.includes("User temporarily locked.")) {
        errorsLogic.catchError(
          new CognitoAuthError(error, {
            field: "code",
            type: "attemptsExceeded",
            namespace: "auth",
          })
        );
        return;
      }
      errorsLogic.catchError(
        new CognitoAuthError(error, {
          field: "code",
          type: "invalidMFACode",
          namespace: "auth",
        })
      );
      return;
    }
    finishLoginAndRedirect(next);
  };

  /**
   * Log out of the Portal
   * @param options.sessionTimedOut Whether the logout occurred automatically as a result of session timeout.
   */
  const logout = async (
    options = { sessionTimedOut: false, isRedirect: true }
  ) => {
    const { sessionTimedOut } = options;
    let { isRedirect } = options;

    const params: NullableQueryParams = {};
    if (sessionTimedOut) {
      params["session-timed-out"] = "true";
      if (oidc.isOIDCAuthenticated()) {
        // persist this for the oauth return
        localStorage.setItem("IS_SESSION_TIMEOUT", "true");
      }
    }
    let redirectUrl = createRouteWithQuery(routes.auth.oAuthStart, params);

    // Set global: true to invalidate all refresh tokens associated with the user on the Cognito servers
    // Notes:
    // 1. This invalidates tokens across all user sessions on all devices, not just the current session.
    //    Cognito currently does not support the ability to invalidate tokens for only a single session.
    // 2. The access token is not invalidated. It remains active until the end of the expiration time.
    //    Cognito currently does not support the ability to invalidate the access token.
    // See also:
    //    - https://dzone.com/articles/aws-cognito-user-pool-access-token-invalidation-1
    //    - https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html
    //    - https://github.com/aws-amplify/amplify-js/issues/3435
    try {
      tracker.trackAuthRequest("signOut");
      if (oidc.isOIDCAuthenticated()) {
        const oidcLogoutUrl = oidc.getEndSessionUrl();
        oidc.signOut();
        if (oidcLogoutUrl) {
          // only grab the redirect URL here
          // do the redirect later after tracker actions are complete
          redirectUrl = oidcLogoutUrl;
          isRedirect = true;
        }
      } else {
        await Auth.signOut({ global: true });
      }
      tracker.markFetchRequestEnd();
    } catch (error) {
      tracker.noticeError(error);
    }

    if (isRedirect) {
      window.location.assign(redirectUrl);
    } else {
      // setting this triggers a react state refresh
      // it's not necessary if we're doing a full page redirect
      setIsLoggedIn(false);
    }
  };

  const getOAuthFlowStart = async (authServer: string, flow: string) => {
    try {
      let language = window.Localize ? window.Localize.getLanguage() : "en";
      // MMG returns some alternate language codes, check map and convert if necessary
      const mapping = Object.entries(MMGToPortalLanguageMap).find(
        ([_, val]) => val === language
      );
      if (mapping) {
        language = mapping[0];
      }
      const data = await oAuthApi.getOAuthFlowStart(
        authServer,
        flow,
        language.toString()
      );
      return data;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const getOAuthToken = async (
    oAuthFlowRequest: OAuthFlowStart,
    code: string,
    state: string
  ) => {
    // let error raise, it will be handled later
    const data = await oAuthTokenApi.getOAuthToken(
      oAuthFlowRequest,
      code,
      state
    );
    return data;
  };

  /**
   * Sets the current user as logged in, and redirects them to the next page.
   * @param [next] Redirect url after login
   * @param [shouldSetMFA] Should a user be redirected to set up MFA?
   * @private
   */
  function finishLoginAndRedirect(next?: string, shouldSetMFA?: boolean) {
    setIsLoggedIn(true);

    if (shouldSetMFA) {
      portalFlow.goToPageFor("ENABLE_MFA");
    } else if (next) {
      portalFlow.goTo(next);
    } else {
      portalFlow.goToNextPage();
    }
  }

  /**
   * Check current session for current user info. If user is logged in,
   * set isLoggedIn to true or false depending on whether the user is logged in.
   * If the user is not logged in, redirect the user to the login page.
   */
  const requireLogin = async () => {
    let tempIsLoggedIn = isLoggedIn;
    if (isLoggedIn === null) {
      // Check if the user is logged in with OAuth token
      tempIsLoggedIn = oidc.isOIDCAuthenticated();

      // Fall back to Cognito/Amplify state
      if (!tempIsLoggedIn) {
        const cognitoUserInfo = await Auth.currentUserInfo();
        tempIsLoggedIn = !!cognitoUserInfo;
      }

      setIsLoggedIn(tempIsLoggedIn);
    }

    assert(tempIsLoggedIn !== null);

    // TODO (CP-733): Update this comment once we move logout functionality into this module
    // Note that although we don't yet have a logout function that sets isLoggedIn to false,
    // the logout (signOut) functionality in AuthNav.js forces a page reload which will
    // reset React in-memory state and set isLoggedIn back to null.

    if (tempIsLoggedIn) return;
    if (!tempIsLoggedIn && !portalFlow.pathname.match(routes.auth.oAuthStart)) {
      const { pathWithParams } = portalFlow;

      portalFlow.goTo(routes.auth.oAuthStart, { next: pathWithParams });
    }
  };

  return {
    authData,
    cognitoUser,
    enableTrustDevice,
    getOAuthFlowStart,
    getOAuthToken,
    login,
    logout,
    isCognitoError,
    isLoggedIn,
    isPhoneVerified,
    requireLogin,
    verifyMFACodeAndLogin,
  };
};

export default useAuthLogic;
export type AuthLogic = ReturnType<typeof useAuthLogic>;

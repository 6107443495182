import { Issue } from "../errors";
import { compact } from "lodash";

export default function combineValidationIssues(
  ...issues: Array<Issue | undefined>
) {
  const combinedIssues = compact(issues);
  if (combinedIssues.length === 0) return;
  return combinedIssues;
}

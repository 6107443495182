import BaseApi from "./BaseApi";
import { UserDevice } from "src/models/User";
import routes from "src/routes";

export default class DevicesApi extends BaseApi {
  get basePath() {
    return routes.api.devices;
  }

  get namespace() {
    return "devices";
  }

  list = async () => {
    const { data } = await this.request<UserDevice[]>("GET");
    return { devices: data };
  };

  remove = async (device_key: string) => {
    const { data } = await this.request<UserDevice[]>(
      "DELETE",
      `/${device_key}`
    );
    return { devices: data };
  };
}

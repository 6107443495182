/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the application flow.
 */
import type { GuardFn } from "./index";
import { UserLeaveAdministrator } from "../models/User";
import routes from "../routes";

export interface EmployerFlowContext {
  leaveAdmin?: UserLeaveAdministrator;
}

/**
 * @see https://xstate.js.org/docs/guides/guards.html
 */
export const guards: { [guardName: string]: GuardFn } = {
  isMissingVerificationData: ({ leaveAdmin }) => {
    return !leaveAdmin?.has_verification_data;
  },
  isVerified: ({ leaveAdmin }) => {
    return !!leaveAdmin?.verified;
  },
};

export default {
  states: {
    [routes.employers.welcome]: {
      on: {
        EDIT_USER_INFO: routes.user.contactInfo,
        VERIFY_ORGS: routes.employers.organizations,
      },
    },
    [routes.employers.applications]: {
      on: {
        VERIFY_ORG: routes.employers.organizations,
        VIEW_CLAIM: routes.employers.status,
      },
    },
    [routes.employers.dashboard]: {
      on: {
        REDIRECT: routes.employers.applications,
      },
    },
    [routes.employers.addOrganization]: {
      on: {
        CONTINUE: routes.employers.verifyContributions,
      },
    },
    [routes.employers.confirmation]: {},
    [routes.employers.organizations]: {
      on: {
        ADD_ORG: routes.employers.addOrganization,
        SHOW_ORG: [
          {
            target: routes.employers.orgDetails,
            cond: "isVerified",
          },
          {
            target: routes.employers.cannotVerify,
            cond: "isMissingVerificationData",
          },
          {
            target: routes.employers.verifyContributions,
          },
        ],
      },
    },
    [routes.employers.review]: {
      on: {
        CONTINUE: routes.employers.success,
        REDIRECT_REVIEWED_CLAIM: routes.employers.status,
      },
    },
    [routes.employers.orgDetails]: {
      on: {
        ADD_LA: routes.employers.addLA,
        BACK: routes.employers.organizations,
        REMOVE_LA: routes.employers.removeLA,
      },
    },
    [routes.employers.removeLA]: {
      on: {
        REDIRECT: routes.employers.orgDetails,
        REDIRECT_FROM_SELF_REMOVAL: routes.employers.organizations,
      },
    },
    [routes.employers.addLA]: {
      on: {
        REDIRECT: routes.employers.orgDetails,
      },
    },
    [routes.employers.status]: {
      on: {
        REDIRECT_REVIEWABLE_CLAIM: routes.employers.review,
      },
    },
    [routes.employers.success]: {
      on: {
        BACK: routes.employers.applications,
      },
    },
    [routes.employers.newApplication]: {
      on: {
        REDIRECT: routes.employers.status,
      },
    },
    [routes.employers.cannotVerify]: {},
    [routes.employers.verificationSuccess]: {
      on: {
        APPLICATIONS: routes.employers.applications,
        CONTINUE: routes.employers.organizations,
        ORG_DETAILS: routes.employers.orgDetails,
      },
    },
    [routes.employers.verifyContributions]: {
      on: {
        CONTINUE: routes.employers.verificationSuccess,
      },
    },
  },
};

/**
 *
 * @file US English language file for i18next-based i18n.
 * @see docs/internationalization.md
 *
 */

interface I18nContentGroup {
  [contentKey: string]: string;
}

// When editing this file, be mindful that many of its strings use non-breaking
// hyphen and non-breaking space characters.
// https://alignedonline.com/non%E2%80%91breaking-spaces
const chars: { [key: string]: string } = {
  // Non-breaking hyphen characters are used in phone and fax numbers to avoid
  // the numbers awkwardly wrapping to multiple lines.
  nbhyphen: "‑",
  // Non-breaking space characters are used to keep cardinal adjectives on the
  // same line as the nouns they describe or between the last two words of a
  // sentence to prevent line breaks that would cause orphaned words.
  nbsp: " ",
};

const data = {
  user: {
    any: "{{value}}",
  },
};

// Our other i18n keys are limited to three levels, but errors is an exception
// since the format of these mirror the field path, which could possibly be more
// than three levels deep (e.g `errors.claim.leave_details.start_date.required`).
const errors = {
  appeals: {
    appeal_phone_number: {
      phone_number: {
        invalid_masked_field: "Enter the full phone number to make a change.",
        pattern: "Enter your 10 digit phone number, with the area code first.",
      },
      required: "Enter a phone number.",
    },
    appeal_representative_option: {
      required: "Select Yes if you'll have a representative.",
    },
    had_preventing_circumstances: {
      required:
        "Select Yes if you had any circumstances that prevented you from appealing the decision sooner.",
    },
    has_read_notices: {
      required: "Select Yes if you read the notices sent to you.",
    },
    interpreter_language_requested: {
      required: "Select a language.",
    },
    needs_interpreter: {
      required: "Select Yes if you need an interpreter.",
    },
    originally_decided_at: {
      format: "You must include a valid month, day, and year.",
      future_date:
        "Date can not be in the future. Enter a valid date from the past.",
      invalid_year_range: "The date entered must be more recent.",
      required: "A date is required.",
    },
    originally_decided_at_reason_for_past_due: {
      required: "An explanation is required.",
    },
    other_interpreter_language_requested: {
      required: "Enter a language.",
    },
    reason_for_not_reading_notices: {
      required: "An explanation is required.",
    },
    rules: {
      phone_number_must_be_valid_number: "Enter a valid phone number.",
    },
  },
  applicationImports: {
    absence_case_id: {
      duplicate:
        "You already have access. You can find this application in <applications-list>your list of applications</applications-list>.",
      exists:
        "This application cannot be added. You will need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to work on your application or get status updates.",
      required: "Application ID is required.",
    },
    conflicting: "$t(shared.applicationImportError_noMatch)",
    employment_status: {
      invalid: "$t(shared.applicationImportError_unsupported)",
    },
    incorrect: "$t(shared.applicationImportError_noMatch)",
    leave_details: {
      reason: {
        invalid: "$t(shared.applicationImportError_unsupported)",
      },
    },
    mfa_delivery_preference: {
      required: "Your login is not verified.",
    },
    more_paid_leave_cases_than_leave_requests:
      "Your application was started through the Contact Center and cannot be transferred to the application website. Call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to continue your application.",
    tax_identifier: {
      required: "Social Security Number is required.",
      pattern: "Your Social Security Number or ITIN must be 9 digits.",
    },
  },
  applications: {
    additional_user_not_found_info: {
      currently_employed: {
        required: "Your current employment status is required.",
      },
      date_of_hire: {
        maximum: "The date of hire must be in the past.",
        required: "The date of hire is required.",
      },
      date_of_separation: {
        maximum: "The date of separation must be in the past.",
        minimum: "The date of separation must be after the date of hire.",
        required: "The date of separation is required.",
      },
      employer_name: {
        required: "The employer name is required.",
      },
    },
    claim: {
      exists:
        "You have already submitted this portion of your application. If you need to edit your information, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    },

    date_of_birth: {
      format: "Your date of birth must include a valid month, day, and year.",
      invalid_age: "The person taking leave must be at least 14 years old.",
      invalid_year_range: "Your date of birth must include a valid year.",
      required: "Enter your date of birth.",
    },
    employee_has_invalid_benefit_year:
      "We are currently processing updates to your leave dates. Come back in 1 to 2 business days to continue your application.<br /><br />You do not need to call the Contact Center to correct this issue.",
    employer_benefits: {
      benefit_amount_dollars: {
        minimum:
          "The benefit amount you will receive must be greater than 0. If you don’t know how much you will receive, go back and answer “No” to the previous question.",
        required: "Enter the amount you will receive.",
      },
      benefit_amount_frequency: {
        required: "Enter how often you will receive this amount.",
      },
      benefit_end_date: {
        employer_benefit_end_date_after_pfml_end_date:
          "You only need to report employer benefits you will be receiving before {{pfml_end_date}}. You do not need to report employer benefits after this date.",
        format:
          "Date you stop receiving this benefit must include a valid month, day, and year.",
        invalid_date_range:
          "Date you stop using this benefit must be on or after the date you start using this benefit.",
        minimum: "Benefit end date must be after December 31, 2020",
        required: "Enter the date your benefit ends.",
      },
      benefit_start_date: {
        conflicting:
          "Only report employer benefits you will be receiving on dates that fall after your 7 day waiting period. You do not need to report any employer benefits prior to or during your waiting period.",
        employer_benefit_start_date_after_pfml_end_date:
          "You only need to report employer benefits you will be receiving between {{day_after_waiting_period_end}} and {{pfml_end_date}}. You do not need to report employer benefits you receive after these dates.",
        format:
          "Date you start receiving this benefit must include a valid month, day, and year.",
        minimum: "Benefit start date must be after December 31, 2020",
        required: "Enter the date you will start using this benefit.",
      },
      benefit_type: {
        required: "Select the kind of benefit you will use.",
      },
      is_full_salary_continuous: {
        required:
          "Select Yes if this employer-sponsored benefit fully replaces your wages.",
      },
    },
    employer_fein: {
      pattern:
        "Enter the Employer Identification Number in the correct format.",
      // TODO (PFMLPB-4447) Remove this translation
      require_contributing_employer:
        "We couldn’t find the employer you entered in our system. Check that you entered your employer’s Employer Identification Number (EIN) correctly. If you continue to get this error, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Common reasons for this error include that your employer is statutorily exempt, non-compliant, or hasn’t filed wage information yet.",
      required: "Enter your employer’s Employer Identification Number.",
    },
    employer_notified: {
      required:
        "Select Yes if you told your employer that you are taking leave.",
    },
    employment_status: {
      required: "Enter your employment status.",
    },
    fineos_case_creation_issues:
      "We couldn’t find you in our system. Check that you entered your employer’s Employer Identification Number (EIN) correctly. If you continue to get this error, <mass-gov-form-link>follow these instructions</mass-gov-form-link> and we’ll set up your application through our Contact Center.",
    fineos_case_error:
      "There was a problem when trying to send your information to our system. Try submitting this information again.",
    first_name: {
      maxLength: "First name must be 50 characters or fewer.",
      required: "Enter a first name.",
    },
    has_continuous_leave_periods: {
      required: "Select Yes if you are taking continuous leave.",
    },
    has_employer_benefits: {
      required:
        "Select Yes if you will use any employer-sponsored benefits during your leave.",
    },
    has_intermittent_leave_periods: {
      required: "Select Yes if you are taking intermittent leave.",
    },
    has_mailing_address: {
      required: "Select Yes if you get your mail at this address.",
    },
    has_other_incomes: {
      required:
        "Select Yes if you will receive income from any other sources during your leave.",
    },
    has_previous_leaves: {
      required:
        "Select Yes if you have taken any other previous leave that you must report.",
    },
    has_previous_leaves_other_reason: {
      required:
        "Select Yes if you have taken previous leave that you must report.",
    },
    has_previous_leaves_same_reason: {
      required:
        "Select Yes if you have taken any other leave for the same qualifying reason.",
    },
    has_proof_of_birth: {
      required: "Select yes if you have proof of birth",
    },
    has_reduced_schedule_leave_periods: {
      required: "Select Yes if you are working a reduced schedule.",
    },
    has_state_id: {
      required:
        "Select Yes if you have a Massachusetts driver's license or ID card.",
    },
    hours_worked_per_week: {
      maximum: "The average hours you work each week must be less than 168.",
      minimum: "The average hours you work each week must be greater than 1.",
      required: "Enter how many hours you work on average each week.",
      type: "The average hours you work each week must be a number.",
    },
    is_withholding_tax: {
      duplicate:
        "You have already submitted your tax withholding preference. If you need to edit your preference, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
      required: "Select your tax withholding preference.",
    },
    language: {
      required: "Select a language for future written communications.",
    },
    last_name: {
      maxLength: "Last name must be 50 characters or fewer.",
      required: "Enter a last name.",
    },
    leave_details: {
      caring_leave_metadata: {
        family_member_date_of_birth: {
          format:
            "Your family member’s date of birth must include a valid month, day, and year.",
          future_birth_date:
            "Family member's date of birth must be less than 7 months from now.",
          invalid_year_range:
            "Your family member’s date of birth must include a valid year.",
          required: "Enter your family member’s date of birth.",
        },
        family_member_first_name: {
          required: "Enter your family member's first name.",
        },
        family_member_last_name: {
          required: "Enter your family member's last name.",
        },
        relationship_to_caregiver: {
          required:
            "Select your relationship with the family member you are caring for.",
        },
      },
      child_birth_date: {
        format: "Date of birth must include a valid month, day, and year.",
        required: "Enter your child’s date of birth or due date.",
      },
      child_placement_date: {
        format: "Placement date must include a valid month, day, and year.",
        required: "Enter the date your child arrived in your home.",
      },
      continuous_leave_periods: {
        end_date: {
          format: "End date must include a valid month, day, and year.",
          minimum:
            "Last day of leave must be on or after the first day of leave.",
          required: "Enter the last day of your continuous leave.",
        },
        start_date: {
          format: "Start date must include a valid month, day, and year.",
          minimum:
            "Paid Family and Medical Leave cannot be taken before Jan 1, 2021. Enter a date after December$t(chars.nbsp)31,$t(chars.nbsp)2020.",
          required: "Enter the first day of your continuous leave.",
        },
      },
      employer_notification_date: {
        format:
          "Date you notified your employer must include a valid month, day, and year.",
        maximum: "Date you notified your employer cannot be in the future.",
        minimum:
          "Year you notified your employer must be within the past two years.",
        required: "Enter the date you notified your employer.",
      },
      employer_notified: {
        required:
          "Select Yes if you told your employer that you are taking leave",
      },
      intermittent_leave_periods: {
        duration: {
          days_absent_per_intermittent_interval_maximum:
            "The total amount of time you may be absent is greater than the length of the frequency interval you have selected. Check the frequency and duration.",
          intermittent_duration_hours_maximum:
            "An absence should be 23 hours or fewer. For absences that are 24 hours or longer, select that the absence will last at least a full day.",
          minimum: "Absence duration must be greater than 0.",
          required: "Enter how long each absence may last.",
        },
        duration_basis: {
          required:
            "Select if absences may last at least a day or less than a full workday.",
        },
        end_date: {
          format: "End date must include a valid month, day, and year.",
          minimum:
            "Last day of leave must be on or after the first day of leave.",
          required: "Enter the last day of your intermittent leave.",
        },
        frequency: {
          required: "Enter how often absences may occur.",
          minimum: "Frequency must be greater than 0.",
        },
        frequency_interval_basis: {
          intermittent_interval_maximum:
            "Sorry, our intermittent leave request service is a work in progress. Use our <intermittent-leave-guide>step-by-step guide to intermittent leave</intermittent-leave-guide> to complete your application. You can also complete your application by calling our Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
          required: "Select how often absences may occur.",
        },
        start_date: {
          format: "Start date must include a valid month, day, and year.",
          minimum:
            "Paid Family and Medical Leave cannot be taken before Jan 1, 2021. Enter a date after December$t(chars.nbsp)31,$t(chars.nbsp)2020.",
          required: "Enter the first day of your intermittent leave.",
        },
      },
      pregnant_or_recent_birth: {
        required:
          "Select Yes if are you taking medical leave because you are pregnant or recovering from birth.",
      },
      reason: {
        required: "Select the reason for taking paid leave.",
      },
      reason_qualifier: {
        required:
          "Select the reason for taking family leave to bond with a child.",
      },
      reduced_schedule_leave_periods: {
        end_date: {
          format: "End date must include a valid month, day, and year.",
          minimum:
            "Last day of leave must be on or after the first day of leave.",
          required: "Enter the last day of your reduced leave schedule.",
        },
        friday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Friday.",
        },
        monday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Monday.",
        },
        saturday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Saturday.",
        },
        start_date: {
          format: "Start date must include a valid month, day, and year.",
          minimum:
            "Paid Family and Medical Leave cannot be taken before Jan 1, 2021. Enter a date after December$t(chars.nbsp)31,$t(chars.nbsp)2020.",
          required: "Enter the first day of your reduced leave schedule.",
        },
        sunday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Sunday.",
        },
        thursday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Thursday.",
        },
        tuesday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Tuesday.",
        },
        wednesday_off_minutes: {
          maximum: "$t(shared.maximumReducedLeaveMinutes)",
          minimum: "$t(shared.minimumReducedLeaveMinutes)",
          required: "Enter your hours off for Wednesday.",
        },
      },
    },
    mailing_address: {
      city: {
        required: "Enter a city for your mailing address.",
      },
      line_1: {
        required: "Enter the street address for your mailing address.",
      },
      required: "Enter a mailing address.",
      state: {
        required: "Enter a state for your mailing address.",
      },
      zip: {
        invalid_masked_field: "Enter the full ZIP code to make a change.",
        pattern: "Mailing address ZIP code must be 5 or 9 digits.",
        required: "Enter a ZIP code for your mailing address.",
      },
    },
    mass_id: {
      pattern:
        "License or ID number must be 9 characters, and may begin with S or SA.",
      required: "Enter your license or ID number.",
    },
    middle_name: {
      maxLength: "Middle name must be 50 characters or fewer.",
    },
    other_incomes: {
      income_amount_dollars: {
        minimum:
          "The income amount you will receive must be greater than 0. If you don’t know how much you will receive, go back and answer “No” to the previous question.",
        required: "Enter the amount you will receive.",
      },
      income_amount_frequency: {
        required: "Enter how often you will receive this amount.",
      },
      income_end_date: {
        format:
          "Date you stop receiving this income must include a valid month, day, and year.",
        invalid_date_range:
          "Date you stop receiving this income must be on or after the date you start receiving this income.",
        minimum: "Income end date must be after December 31, 2020",
        required: "Enter the date you will stop receiving this income.",
      },
      income_start_date: {
        format:
          "Date you start receiving this income must include a valid month, day, and year.",
        minimum: "Income start date must be after December 31, 2020",
        required: "Enter the date you will start receiving this income.",
      },
      income_type: {
        required: "Select the kind of income.",
      },
    },
    payment_preference: {
      account_number: {
        invalid_masked_field: "Enter the full account number to make a change.",
        maxLength: "Account number must be 17 digits or fewer.",
        minLength: "Account number must be at least 6 digits.",
        required: "Enter an account number.",
      },
      bank_account_type: {
        required: "Select if this is a savings or checking account.",
      },
      payment_method: {
        required: "Select how you want to get your weekly benefit.",
        exists:
          "You have already submitted your payment preference. If you need to edit your preference, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
      },
      routing_number: {
        checksum:
          "Enter a valid routing number. Check that you entered your routing number correctly. It is a 9-digit number located in the bottom left-hand corner of a check which uniquely identifies your bank.",
        pattern: "Enter your routing number in the correct format.",
        required: "Enter a routing number.",
      },
    },
    phone: {
      phone_number: {
        invalid_masked_field: "Enter the full phone number to make a change.",
        invalid_phone_number:
          "That phone number is invalid. Check the number and try again.",
        pattern: "Enter your 10 digit phone number, with the area code first.",
        required: "Enter a phone number.",
      },
      phone_type: {
        required: "Select a number type.",
      },
    },
    previous_leaves: {
      is_continuous: {
        required:
          "Select Yes if you were completely off work for the duration of this leave.",
      },
      is_for_current_employer: {
        required:
          "Select Yes if the leave you are reporting is from this employer.",
      },
      leave_end_date: {
        invalid:
          "The last day of your previous leave must be before {{calculated_end_date, dateFull}}.",
        invalid_date_range: "Previous leave dates reported cannot overlap.",
        required: "Enter the date your leave ended.",
      },
      leave_minutes: {
        required: "Enter the total number of hours you took off from work.",
      },
      leave_reason: {
        required: "Select the reason this leave was taken.",
      },
      leave_start_date: {
        invalid:
          "The first day of your previous leave must be after {{calculated_start_date, dateFull}}.",
        invalid_date_range: "Previous leave dates reported cannot overlap.",
        required: "Enter the date your leave began.",
      },
      worked_per_week_minutes: {
        maximum:
          "The number of hours worked per week must be less than or equal to 168.",
        minimum:
          "The number of hours worked per week must be equal to or greater than 1.",
        required:
          "Enter the number of hours per week you normally worked prior to taking this leave.",
      },
    },
    previous_leaves_other_reason: {
      is_for_current_employer: {
        required:
          "$t(shared.claimsPreviousLeaveErrors.isForCurrentEmployer_required)",
      },
      leave_end_date: {
        format: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_format)",
        invalid_date_range:
          "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_invalidDateRange)",
        minimum: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_minimum)",
        required: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_required)",
      },
      leave_minutes: {
        maximum: "$t(shared.claimsPreviousLeaveErrors.leaveMinutes_maximum)",
        required: "$t(shared.claimsPreviousLeaveErrors.leaveMinutes_required)",
      },
      leave_reason: {
        required: "$t(shared.claimsPreviousLeaveErrors.leaveReason_required)",
      },
      leave_start_date: {
        format: "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_format)",
        minimum: "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_minimum)",
        required:
          "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_required)",
      },
      worked_per_week_minutes: {
        maximum:
          "$t(shared.claimsPreviousLeaveErrors.workedPerWeekMinutes_maximum)",
        required:
          "$t(shared.claimsPreviousLeaveErrors.workedPerWeekMinutes_required)",
      },
    },
    previous_leaves_same_reason: {
      is_for_current_employer: {
        required:
          "$t(shared.claimsPreviousLeaveErrors.isForCurrentEmployer_required)",
      },
      leave_end_date: {
        format: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_format)",
        invalid_date_range:
          "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_invalidDateRange)",
        minimum: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_minimum)",
        required: "$t(shared.claimsPreviousLeaveErrors.leaveEndDate_required)",
      },
      leave_minutes: {
        maximum: "$t(shared.claimsPreviousLeaveErrors.leaveMinutes_maximum)",
        required: "$t(shared.claimsPreviousLeaveErrors.leaveMinutes_required)",
      },
      leave_start_date: {
        format: "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_format)",
        minimum: "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_minimum)",
        required:
          "$t(shared.claimsPreviousLeaveErrors.leaveStartDate_required)",
      },
      worked_per_week_minutes: {
        maximum:
          "$t(shared.claimsPreviousLeaveErrors.workedPerWeekMinutes_maximum)",
        required:
          "$t(shared.claimsPreviousLeaveErrors.workedPerWeekMinutes_required)",
      },
    },
    residential_address: {
      city: {
        maxLength: "City must be 40 characters or fewer.",
        required: "Enter a city for your residential address.",
      },
      line_1: {
        maxLength: "Address must be 40 characters or fewer.",
        required: "Enter the street address for your residential address.",
      },
      line_2: {
        maxLength: "Address line 2 must be 40 characters or fewer.",
      },
      required: "Enter a residential address.",
      state: {
        maxLength: "State must be 40 characters or fewer.",
        required: "Enter a state for your residential address.",
      },
      zip: {
        invalid_masked_field: "Enter the full ZIP code to make a change.",
        pattern: "Residential address ZIP code must be 5 or 9 digits.",
        required: "Enter a ZIP code for your residential address.",
      },
    },
    rules: {
      disallow_12mo_continuous_leave_period:
        "$t(shared.disallow12moLeavePeriod)",
      disallow_12mo_intermittent_leave_period:
        "$t(shared.disallow12moLeavePeriod)",
      disallow_12mo_leave_period: "$t(shared.disallow12moLeavePeriod)",
      disallow_12mo_reduced_leave_period: "$t(shared.disallow12moLeavePeriod)",
      disallow_attempts:
        "We already have an account set up for you. Please sign in with that account. If that doesn’t sound familiar to you, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
      disallow_caring_leave_before_july:
        "Leave start dates must be after June 30, 2021.",
      disallow_hybrid_intermittent_leave:
        "You cannot request intermittent leave in the same application as your continuous or reduced schedule leave. Create a separate application for your <intermittent-leave-guide>intermittent leave</intermittent-leave-guide> dates.",
      disallow_overlapping_leave_period_from_other_applications:
        "Do not report any leave you have previously taken within the PFML program.",
      disallow_overlapping_leave_period_with_previous_leave:
        "Your previous leave dates cannot overlap with the PFML leave dates you are applying for. Check that you’ve entered the correct start and end dates for your leave details and previous leave.",
      disallow_overlapping_leave_periods:
        "Your reduced leave schedule cannot overlap with your continuous or intermittent leave. Check whether you’ve entered the correct start and end dates for each leave period.",
      disallow_submit_over_60_days_before_start_date:
        "The date your leave begins is more than 60 days in the future. Submit your application within 60 days of your leave start date.",
      max_ssn_fein_update_attempts:
        "You’ve exceeded the number of allowed attempts to enter your SSN and EIN. Please call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
      min_leave_periods:
        "You must choose at least one kind of leave (<scheduling-leave-guide>continuous, reduced schedule, or intermittent</scheduling-leave-guide>).",
      min_reduced_leave_minutes:
        "The total time entered for your hours off must be greater than 0.",
      require_contributing_employer:
        "We couldn’t find the employer you entered in our system. Check that you entered your employer’s Employer Identification Number (EIN) correctly. If you continue to get this error, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Common reasons for this error include that your employer is statutorily exempt, non-compliant, or hasn’t filed wage information yet.",
      require_employee:
        "We couldn’t find a match between your Social Security Number and the employer you entered in our system. Check that you entered your Social Security Number and your employer’s Employer Identification Number (EIN) correctly. If you continue to get this error, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to provide proof of wages.",
      require_employer_notified:
        "You must tell your employer that you’re taking leave before you can submit an application. If you’ve told your employer, update your application with the date that you notified them.",
    },
    tax_identifier: {
      invalid_masked_field:
        "Enter the full Social Security Number or ITIN to make a change.",
      pattern: "Your Social Security Number or ITIN must be 9 digits.",
      required: "Enter a Social Security Number or ITIN.",
    },
    upload_docs_options: {
      required: "Select the kind of document you will be uploading.",
    },
    work_pattern: {
      work_pattern_days: {
        minimum: "Enter the hours you work.",
        minutes: {
          maximum: "The hours entered are more than possible hours.",
          required: "Enter the hours you work.",
        },
      },
      work_pattern_type: {
        required: "Select your work schedule.",
      },
    },
  },
  auth: {
    account_type: {
      required: "Please select which account type you would like to create.",
    },
    attemptsLimitExceeded_forgotPassword:
      "Your account is temporarily locked because of too many forget password requests. Wait 15 minutes before trying again.",
    attemptsLimitExceeded_login:
      "Your account is temporarily locked because of too many failed login attempts. Wait 15 minutes before trying again.",
    attemptsLimitExceeded_updatePhone:
      "We couldn't add your phone number because you've exceeded the number of allowed attempts. You can try again later.",
    code: {
      attemptsExceeded:
        "Your account is temporarily locked because of too many failed verification attempts. Wait 15 minutes before trying again.",
      attemptsExceeded_confirmPhone:
        "We can't confirm your phone number because of too many failed verification attempts. Wait 15 minutes before trying again.",
      deliveryFailure:
        "We encountered an error while sending the verification code. Try again.",
      expired: "Your verification code has expired or has already been used.",
      invalidMFACode:
        "The security code you entered is invalid. Make sure the code matches the security code we texted to you.",
      mismatchException:
        "Invalid verification code. Make sure the code matches the code emailed to you.",
      pattern:
        "Enter the 6-digit code sent to your email and ensure it does not include any punctuation.",
      required: "Enter the 6-digit code sent to your email",
    },
    expiredVerificationCode:
      "Invalid verification code. Please request a new code.",
    incorrectEmailOrPassword: "Incorrect email or password",
    invalidParametersFallback: "Enter all required information",
    invalidParametersIncludingMaybePassword:
      "Check the requirements and try again. Ensure all required information is entered and the password meets the requirements.",
    invalidPhoneFormat: "Invalid phone number",
    password: {
      insecure: "$t(shared.auth.passwordError_insecure)",
      invalid: "$t(shared.auth.passwordError_invalid)",
      required: "$t(shared.auth.passwordError_required)",
      resetRequiredException:
        'Your password must be reset before you can log in again. Click the "Forgot your password?" link below to reset your password.',
    },
    repeatPassword: {
      mismatched: "$t(shared.auth.repeatPasswordError_mismatched)",
      required: "$t(shared.auth.repeatPasswordError_required)",
    },
    userNotConfirmed:
      "Confirm your account by following the instructions in the verification email sent to your inbox.",
    userNotFound: "Incorrect email",
    username: {
      exists: "$t(shared.auth.emailError_exists)",
      required: "$t(shared.auth.emailError_required)",
    },
  },
  caughtError:
    "Sorry, an unexpected error in our system was encountered. If this continues to happen, you may call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  caughtError_DocumentDownloadError: "$t(shared.documentDownloadError)",
  caughtError_DocumentsLoadError: "$t(shared.documentsLoadError)",
  caughtError_DocumentsUploadError: "$t(shared.documentsUploadError)",
  caughtError_ForbiddenError:
    "Sorry, an authorization error was encountered. Please log out and then log in to try again.",
  caughtError_NetworkError: "$t(shared.networkError)",
  caughtError_NotFoundError:
    "Sorry, we were unable to retrieve what you were looking for. Check that the link you are visiting is correct. If this continues to happen, please log out and try again.",
  caughtError_TaxDocumentDownloadError: "$t(shared.taxDocumentDownloadError)",
  caughtError_TaxDocumentsLoadError: "$t(shared.taxDocumentsLoadError)",
  change_email: {
    email_address: {
      duplicate:
        "The email address you entered is already associated with your account.",
      email_address: {
        exists: "An account with the given email already exists",
      },
      exists: "$t(shared.auth.emailError_exists)",
      format: "$t(shared.auth.emailError_format)",
      required: "$t(shared.auth.emailError_required)",
      too_many_attempts:
        "We could not change your email address because it was changed within the last 24 hours. You can try again later.",
    },
    object_not_found: "Your verification code has expired.",
    verification_code: {
      invalid:
        "Invalid verification code. Make sure the code matches the code emailed to you.",
      refresh_auth_token:
        "Your verification code has expired or already been used.",
      required:
        "Enter the 6-digit code sent to your email and ensure it does not include any punctuation.",
    },
  },
  change_request: {
    change_request_type: {
      required: "Select what kind of change you’d like to make.",
    },

    date_of_birth: {
      required: "Enter your child’s date of birth or expected due date.",
      format: "Enter a valid date of birth.",
    },
    documents_submitted_at: {
      required:
        "Upload a certification document that covers your requested leave dates.",
    },
    end_date: {
      change_end_date_same_as_approved_end_date:
        "Requested last day of leave can't be the same as your current last day of leave.",
      change_request_end_date_same_as_start:
        'Requested last day of leave must be after your first day of leave. Choose "cancel my entire leave application" if you no longer need to take any PFML leave.',
      disallow_12mo_extension:
        "Your additional leave cannot be 12 months or more.",
      format:
        "Requested last day of leave must include a valid month, day, and year.",
      maximum:
        "Requested last day of leave must be after your first day of leave.",
      required: "Enter your requested last day of leave.",
    },
  },
  claimStatus: {
    fineos_claim_withdrawn:
      "Application <strong>{{absenceId}}</strong> has been withdrawn and is no longer being processed.  If you believe this application has been withdrawn in error, check if any additional applications in your account cover the same leave period.  If you have additional questions, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  },
  dfp: {
    dfpRejectAction:
      "Your request can not be completed at this time. If this continues to occur, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  },
  documents: {
    certification_upload_method: {
      required: "You must make a selection to continue.",
    },
    file: {
      clientSideError_size:
        "We could not upload: {{disallowedFileNames}}. Files must be smaller than {{ sizeLimit }} MB.<br />You may fax or mail color copies of your documents if you prefer. <mail-fax-instructions>Follow the fax and mail instructions</mail-fax-instructions>.",
      clientSideError_sizeAndType:
        "We could not upload: {{disallowedFileNames}}. Choose a PDF or an image file (.jpg, .jpeg, .png) that is smaller than {{ sizeLimit }} MB.<br />You may fax or mail color copies of your documents if you prefer. <mail-fax-instructions>Follow the fax and mail instructions</mail-fax-instructions>.",
      clientSideError_type:
        "We could not upload: {{disallowedFileNames}}. Choose a PDF or an image file (.jpg, .jpeg, .png).<br />You may fax or mail color copies of your documents if you prefer. <mail-fax-instructions>Follow the fax and mail instructions</mail-fax-instructions>.",
      file_size:
        "We could not upload all files. Files must be smaller than {{ fileSizeMaxMB }} MB.<br />You may fax or mail color copies of your documents if you prefer. <mail-fax-instructions>Follow the fax and mail instructions</mail-fax-instructions>.",
      required: "Upload at least one file to continue.",
    },
    fineos_client: "$t(shared.documentsUploadError)",
    health_certification: {
      required: "Upload all missing pages to save and continue.",
    },
    mass_id: {
      required:
        "You must upload both the front and back of your Massachusetts ID to continue.",
    },
    modification: {
      required:
        "Upload a certification document that covers your requested leave dates.",
    },
    rules: {
      document_requirement_already_satisfied:
        "You have already satisfied the requirements for this document. If you would like to update the existing documentation, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    },
    uploadCertificationType: {
      required: "Choose which type of form you're uploading",
    },
  },
  employers: {
    comment: {
      maxLength: "$t(shared.maxEmployerCommentLengthError)",
    },
    contains_v1_and_v2_eforms:
      "<h3>Call the Contact Center to review this application</h3><p>We can’t display this application for review online. To review this application, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p><p><strong>What you will need to know when you call the agent:</strong></p><ul><li>The employee’s name and application ID. You can find these on your list of applications, or in the email we sent to tell you that you can review this application.</li><li>The error code for this error: <strong>V-12</strong>.</li></ul><strong>Why you are seeing this error:</strong> Your employee told us about some other leave and benefits they are receiving in addition to paid leave from PFML. This information was added to their application recently. In some cases, when an employee who submitted an application before July 15th then adds other leave and benefits information later, it can cause this error.<p>This is a rare error that will not happen with new applications.</p>",
    employer_benefit_checkbox: {
      required:
        "Select the checkbox in the other disability, family, or medical leave benefits section.",
    },
    employer_benefits: {
      benefit_end_date: {
        format: "End date must include a valid month, day, and year.",
        minimum:
          "Last day of benefit must be on or after the first day of benefit.",
      },
      benefit_start_date: {
        duplicate: "The data you entered is already in the table.",
        format: "Start date must include a valid month, day, and year.",
      },
    },
    employer_fein: {
      duplicate:
        "The employer ID you entered is already associated with your account.",
      employer_requires_verification_data:
        "Your account can’t be verified yet, because your organization has not made any paid leave contributions. Once this organization pays quarterly taxes, you can verify your account and review applications. <file-a-return-link>Learn more about filing returns and sending contributions</file-a-return-link>.",
      pattern:
        "Enter your 9-digit Employer Identification Number in the correct format.",
      require_contributing_employer:
        "Enter a valid Employer Identification Number (EIN). Check that you entered your EIN correctly and the associated business is contributing to Paid Family and Medical Leave.",
      require_employer:
        "Enter a valid Employer Identification Number (EIN). Check that you entered your EIN correctly.",
      required: "Enter your 9-digit Employer Identification Number.",
    },
    employer_review_checkbox: {
      required:
        "Please check all boxes to validate that you have reviewed the application to proceed.",
    },
    forms: {
      incomplete: "Select confirm or cancel.",
    },
    fraud: {
      required: "Select whether you believe this application is fraud.",
    },
    hours_worked_per_week: {
      maximum: "Average weekly hours must be 168 or fewer.",
      minimum: "Enter the average weekly hours.",
    },
    outstanding_information_request_required:
      "This application has already been reviewed.",
    previous_leave_checkbox: {
      required: "Select the checkbox in the previous leave section.",
    },
    previous_leaves: {
      leave_start_date: {
        conflicting:
          "The dates you entered overlap with previous PFML leave from {{start_date}} to {{end_date}}.",
        duplicate: "The data you entered is already in the table.",
        minimum:
          "Only report previous leave that occurs between {{previous_year_start}} and {{previous_year_end}}.",
      },
      leave_end_date: {
        minimum:
          "Last day of previous leave must be on or after the first day.",
      },
    },
    relationship_inaccurate_reason: {
      maxLength: "$t(shared.maxEmployerCommentLengthError)",
    },
    unauthorized_leave_admin: "$t(shared.unauthorizedLeaveAdminError)",
    withholding_amount: {
      incorrect: "The amount does not match our records. Please try again.",
      incorrect_close_to_limits:
        "The amount does not match our records. If you enter the wrong contribution amount too many times, you will have to wait 24 hours before trying again.",
      too_many_attempts:
        "Verification was blocked for 24 hours because the wrong contribution amount was entered too many times. Please try again later.",
    },
  },
  intermittent_leave_reporting: {
    episodic_period: {
      maximum:
        "Hours of leave taken in a day can’t be more than 24 hours. If your leave lasted multiple days, enter each day separately.",
      minimum: "Time entered must be greater than 0.",
      type: "Enter the hours of leave you took.",
    },
    future_date: "You can’t report leave for days in the future.",
    maximum:
      "Hours of leave taken in a day can’t be more than 24 hours. If your leave lasted multiple days, enter each day separately.",
    requested_date: {
      date_already_reported:
        "You have already reported hours for {{requested_date}}. Call <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link> to add additional time or correct your reported hours.",
      date_not_approved:
        "You can’t report hours outside of your approved leave schedule. Call <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to extend or modify your leave dates.",
      format: "Date of leave must include a valid month, day and year.",
      future_date: "You can’t report leave for days in the future.",
      required: "Date of leave must include a valid month, day and year.",
      type: "Date of leave must include a valid month, day and year.",
    },
    required: "Date of leave must include a valid month, day and year.",
  },
  leaveAdmins: {
    email_address: {
      exists:
        "An account with the given email already has access to this organization.",
      duplicate:
        "An account with the given email already has access to this organization.",
    },
    employer_role: {
      required:
        "The given email was used to create an employee account. Check that you've entered the correct email address for a leave administrator account.",
    },
    unauthorized_leave_admin: "$t(shared.unauthorizedLeaveAdminError)",
  },
  mfa: {
    code: {
      pattern:
        "Enter the 6-digit code sent to your phone number and ensure it does not include any punctuation.",
      required: "Enter the 6-digit code sent to your phone number",
    },
    enterMFASetupFlow: {
      required:
        "Select Yes if you want to add a phone number for verifying logins.",
    },
  },
  network:
    "Sorry, an error was encountered. This may occur for a variety of reasons, including temporarily losing an internet connection or an unexpected error in our system. If this continues to happen, you may call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
  payments: {
    payment_preference: {
      retype_account_number: {
        required: "Retype your account number",
        mismatch:
          "The account numbers you entered do not match. Make sure the numbers are exactly the same.",
      },
    },
  },
  settings: {
    email_address: {
      exists: "$t(shared.auth.emailError_exists)",
      format: "$t(shared.auth.emailError_format)",
      required: "$t(shared.auth.emailError_required)",
    },
    refresh_auth_token: "refresh_auth_token",
    verification_code: {
      incorrect: "",
      required: "",
    },
  },
  users: {
    email_address: {
      exists: "$t(shared.auth.emailError_exists)",
      format: "$t(shared.auth.emailError_format)",
      required: "$t(shared.auth.emailError_required)",
    },
    first_name: {
      required: "First name is required.",
    },
    last_name: {
      required: "Last name is required.",
    },
    mfa_phone_number: {
      phone_number: {
        // Used by the mfa service, more than 12 digits
        international_number:
          "We don't support international phone numbers yet. Enter a U.S. phone number to set up additional login verifications.",
        // There are 10 digits
        pattern: "Enter a valid phone number",
        // Used by the mfa service, field cant be blank
        required: "Enter a phone number",
      },
    },
    password: {
      insecure: "$t(shared.auth.passwordError_insecure)",
      invalid: "$t(shared.auth.passwordError_invalid)",
      required: "$t(shared.auth.passwordError_required)",
    },
    phone: {
      phone_number: {
        invalid_masked_field: "Re-enter the full value to make a change.",
        invalid_phone_number:
          "That phone number is invalid. Check the number and try again.",
        pattern: "Enter your 10 digit phone number, with the area code first.",
        required: "Phone number is required.",
      },
    },
    repeatPassword: {
      invalid: "$t(shared.auth.repeatPasswordError_mismatched)",
      mismatched: "$t(shared.auth.repeatPasswordError_mismatched)",
      required: "$t(shared.auth.repeatPasswordError_required)",
    },
    user_leave_administrator: {
      employer_fein: {
        pattern:
          "Enter your 9-digit Employer Identification Number in the correct format.",
        require_contributing_employer:
          "Enter a valid Employer Identification Number (EIN). Check that you entered your EIN correctly and the associated business is contributing to Paid Family and Medical Leave.",
        require_employer:
          "Enter a valid Employer Identification Number (EIN). Check that you entered your EIN correctly.",
        required: "Enter your 9-digit Employer Identification Number.",
      },
    },
  },
  // Generic fallbacks, when there isn't content defined for an error's namespace.
  // These may also be used if we add a new field/validation on the backend, without
  // also adding its i18n error message to this file.
  validationFallback: {
    fineos_claim_withdrawn:
      "Application has been withdrawn and is no longer being processed.",
    invalid: "Field ({{field}}) has invalid value.",
    invalid_masked_field: "Re-enter the full value to make a change.",
    // Field's value didn't match an expected regex pattern:
    pattern: "Field ({{field}}) didn’t match expected format.",
  },
};

const shared: {
  // Keys are limited to three levels deep, for example `shared.auth.emailMissing`.
  // This makes the structure easier to navigate and the process of finding a specific element more consistent
  [contentKey: string]: string | I18nContentGroup;
} = {
  achTypeChecking: "Checking",
  achTypeSavings: "Savings",
  addApplication: "Add an existing application",
  amountFrequencyLabel: "Frequency",
  amountFrequency_daily: "Daily",
  amountFrequency_inTotal: "All at once",
  amountFrequency_monthly: "Monthly",
  amountFrequency_unknown: "Unknown",
  amountFrequency_weekly: "Weekly",
  amountPerFrequency_daily: "{{amount, currency}} per day",
  amountPerFrequency_inTotal: "{{amount, currency}} all at once",
  amountPerFrequency_monthly: "{{amount, currency}} per month",
  amountPerFrequency_unknown: "{{amount, currency}} (frequency unknown)",
  amountPerFrequency_weekly: "{{amount, currency}} per week",
  applicationImportDescription:
    "Some applications started on the phone can be added to this website. Once added, you can: <ul><li>Upload documents</li><li>See your application's status</li><li>Track payments online</li><li>File an appeal</li></ul>",
  applicationImportError_noMatch:
    "No match found. Check that you entered your application ID correctly. Applications started on the phone with a Contact Center agent can take up to 24 hours to appear in our online system. Check back again. If you’re still unable to find your application, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  applicationImportError_unsupported:
    "This application cannot be added at this time. If you are applying for <applying-to-military-leave>military-related paid family leave benefits</applying-to-military-leave>, or you are currently <applying-self-or-unemployed>self-employed or unemployed</applying-self-or-unemployed>, your application cannot be managed by this website. You will need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to work on your application or get status updates.",
  auth: {
    emailError_exists: "An account with the given email already exists",
    emailError_format:
      "Enter an email address in the correct format, like name@example.com",
    emailError_required: "Enter your email address",
    passwordError_insecure:
      "Choose a different password. Avoid commonly used passwords and avoid using the same password on multiple websites.",
    passwordError_invalid:
      "Your password does not meet the requirements. Please check the requirements and try again.",
    passwordError_required: "Enter your password",
    repeatPasswordError_mismatched: "Passwords do not match",
    repeatPasswordError_required: "Confirm your password",
    skip: "Skip this step",
  },
  backToLoginLink: "Back to log in",
  backToYourApplicationDetailsLink: "Back to your application details",
  backToYourApplicationLink: "Back to your application",
  backToYourApplicationsLink: "Back to your applications",
  certificationFormCare:
    "Certification of Your Family Member’s Serious Health Condition",
  certificationFormMedical: "Certification of Your Serious Health Condition",
  changeEmailBackButtonLabel: "Back to Settings",
  changeEmailTitle: "Change your email address",
  choiceHint_familyOrMedicalLeave: "For example, a paid parental leave policy",
  choiceHint_paidTimeOff: "Vacation days, sick days, or personal time",
  choiceHint_shortTermDisability: "Short-term or long-term disability",
  choiceNo: "No",
  choiceYes: "Yes",
  claimDurationTypeContinuous: "Continuous leave",
  claimDurationTypeIntermittent: "Intermittent leave",
  claimDurationTypeReduced: "Reduced leave",
  claimDurationTypeReducedSchedule: "Reduced leave schedule",
  claimsEmploymentInfoTitle: "Employment information",
  claimsLeaveDetailsTitle: "Leave details",
  claimsLeaveDurationTitle: "Leave duration",
  claimsLeaveSpansBenefitYears: {
    bothCanBeSubmittedBenefitsCalculatedSeparately:
      "The Department will calculate your weekly benefit amount for each application separately. You may receive different payment amounts for each application. Learn more about <how-benefit-amounts-are-calculated-link>how PFML weekly benefit amounts are calculated</how-benefit-amounts-are-calculated-link>.",
    bothCanBeSubmittedDeterminationMayBeDifferent:
      "The Department's determination for one application may be different from its determination for the other.",
    bothCanBeSubmittedSevenDayWaitingPeriod:
      "You'll have two 7-day waiting periods, one at the start of each period of your leave. Learn more about the <seven-day-waiting-period-link>7-day waiting period</seven-day-waiting-period-link>.",
    bothCannotBeSubmittedCurrent:
      "You will be able to submit Part 1 of your current benefit year application on <strong>{{submittableDate}}</strong>. This is 60 days before the start of your leave in the current benefit year.",
    bothCannotBeSubmittedNew:
      "You will be able to submit Part 1 of your new benefit year application on <strong>{{submittableDate}}</strong>. This is 60 days before the start of your leave in the new benefit year.",
    bothCannotBeSubmittedReminder:
      "Applications cannot be submitted earlier than 60 days before the start of leave.",
    currentBenefitYearLeaveDates:
      "<strong>Current benefit year</strong>: leave dates from {{startDate}} to {{endDate}}",
    introduction:
      "Your current <benefit-year-guide-link>benefit year</benefit-year-guide-link> is {{startDate}} to {{endDate}}. After you submit Part 1 of your application, we'll automatically split this application into two, one for each benefit year. You can upload the same supporting documentation for both applications.",
    newBenefitYearLeaveDates:
      "<strong>New benefit year</strong>: leave dates from {{startDate}} to {{endDate}}",
    secondCannotBeSubmittedCurrent:
      "You will be able to submit your current benefit year application right away.",
    secondCannotBeSubmittedNew:
      "You will be able to submit your new benefit year application on <strong>{{submittableDate}}</strong>. This is 60 days before the start of your leave in the new benefit year. Applications cannot be submitted earlier than 60 days before the start of leave.",
    title: "Your leave dates extend into a new benefit year.",
  },
  claimsLeaveSpansBenefitYearsMessageInterstitial: {
    bothList_1:
      "You will have <by-leave-spans-two-link>an application that spans two benefit years</by-leave-spans-two-link>.",
    bothList_2: "DFML’s decision may be different for each benefit year.",
    bothList_3:
      "DFML will calculate your weekly benefit amount for each benefit year separately. You may receive different payment amounts for each benefit year. Learn more about <how-benefit-amounts-are-calculated-link>how PFML weekly benefit amounts are calculated</how-benefit-amounts-are-calculated-link>.",
    bothList_4:
      "You'll have another unpaid 7-day waiting period at the start of your new benefit year. Learn more about the <seven-day-waiting-period-link>7-day waiting period</seven-day-waiting-period-link>.",
    continueLabel: "Continue",
    currentBenefitYear:
      "<strong>Your current benefit year is <leaveDatesRange /></strong> .",
    introduction:
      "The leave dates you're requesting <leaveDatesRange />, extend into a new <benefit-year-guide-link>benefit year</benefit-year-guide-link>. Your benefit year is unique to you. It determines your benefit rate and how much leave you have available.",
    learnMore:
      "<by-importance>Learn more about the importance of the benefit year.</by-importance>",
    leaveDateReview:
      "DFML will review the following leave request dates: {{leaveStartDate}} to {{currentBYEndDate}}.",
    newBYCannotBeApproved:
      "<strong>Your leave request dates from {{newBYStartDate}} to {{newBYEndDate}} cannot be approved at this time.</strong> DFML cannot approve dates in a new benefit year that start more than 60 days in the future.",
    partOfYourLeaveHeader:
      "<strong>Part of your leave cannot be approved at this time</strong>",
    thisMeansThatHeader: "<strong>This means that:</strong> ",
    title: "Your leave dates extend into a new benefit year.",
    titleMoreThan60:
      "Your leave dates extend into a new benefit year that starts more than 60 days in the future.",
    whatYouCanDo: "<strong>What you can do</strong>",
    whatYouCanDoParagraph:
      "If the leave request for your current benefit year is approved, you can submit a request to extend your leave to {{leaveEndDate}}.",
    whatYoucanDoBullet:
      "You can submit the extension request on or after {{sixtyDaysBeforeNewBYStartDate}}",
  },
  claimsLeaveSpansBenefitYearsMessageInterstitialV2: {
    currentBenefitYear:
      "<strong>Your current benefit year is <leaveDatesRange /></strong>.",
    introduction:
      "The leave dates you're requesting, <leaveDatesRange />, <benefit-year-guide-link>extend into your new benefit year</benefit-year-guide-link>. Your benefit year determines your benefit rate and how much leave you have available.",
    learnMore:
      "<by-importance>Learn more about the importance of the benefit year.</by-importance>",
    thisMeansThatHeader: "<strong>This means that:</strong> ",
    thisMeansThatList:
      "<ul><li>DFML <strong>will</strong> review the leave request dates that are part of your current benefit year: {{leaveStartDate}} to {{currentBYEndDate}}.</li><li>DFML <strong>will not</strong> review the leave request dates: {{newBYStartDate}} to {{leaveEndDate}} because we cannot approve dates in a new benefit year that starts more than 60 days in the future.</li></ul>",
    tipHeader:
      "<strong>Tip: How to get your new benefit year leave request reviewed</strong> ",
    tipList:
      "<ol><li>Wait to see if your leave request for your current benefit year is approved. If it is, go to the next step.</li><li>On or after <strong>{{sixtyDaysBeforeNewBYStartDate}}</strong>, submit a separate application to cover the part of your leave that is in your new benefit year: <strong>{{newBYStartDate}} to {{leaveEndDate}}</strong>. </li><li>Wait to see if your new benefit year leave request is approved. Keep in mind that if it is, you’ll have another unpaid <seven-day-waiting-period-link>7-day waiting period</seven-day-waiting-period-link> at the start of your new benefit year. </li></ol>",
    titleMoreThan60:
      "We cannot review your full leave request. Your leave dates extend into a new benefit year that starts more than 60 days in the future.",
  },
  claimsLeaveSpansBenefitYearsV2: {
    appSpansBenefitYears:
      "You will have <app-spans-two-by>an application that spans two benefit years</app-spans-two-by>.",
    bothBYSpansTwo:
      "You will have <by-leave-spans-two-link>an application that spans two benefit years</by-leave-spans-two-link>.",
    bothLeaveDates:
      "<strong>Your leave dates are {{leaveStartDate}} to {{leaveEndDate}}</strong>.",
    bothLeaveDatesMoreThan60Days:
      "<strong>Your leave request dates are {{leaveStartDate}} to {{leaveEndDate}}</strong>.",
    bothList_1: "DFML will review each benefit year leave request.",
    bothList_2: "The decision may be different for each benefit year.",
    bothList_3:
      "DFML will calculate your weekly benefit amount for each benefit year separately. You may receive different payment amounts for each benefit year. Learn more about <how-benefit-amounts-are-calculated-link>how PFML weekly benefit amounts are calculated</how-benefit-amounts-are-calculated-link>.",
    bothList_4:
      "You'll have another unpaid 7-day waiting period at the start of your new benefit year. Learn more about the <seven-day-waiting-period-link>7-day waiting period</seven-day-waiting-period-link>.",
    currentBenefitYearLeaveDates:
      "<strong>Current benefit year</strong>: leave dates from {{startDate}} to {{endDate}}",
    heading_more_than_60:
      "Your leave dates extend into a new benefit year that starts more than 60 days in the future.",
    heading_more_than_60V2:
      "We cannot review your full leave request. Your leave dates extend into a new benefit year that starts more than 60 days in the future.",
    introduction:
      "<strong>Your current <benefit-year-guide-link>benefit year</benefit-year-guide-link> is {{startDate}} to {{endDate}}</strong>.",
    listHeader: "<strong>This means that:</strong> ",
    newBenefitYearLeaveDates:
      "<strong>New benefit year</strong>: leave dates from {{startDate}} to {{endDate}}",
    onlyFirstList_1:
      "DFML will review the following leave request dates: {{leaveStartDate}} to {{currentBYEndDate}}.",
    onlyFirstList_2:
      "<strong>Your leave dates from {{newBYStartDate}} to {{leaveEndDate}} cannot be approved at this time</strong>. DFML cannot approve dates in a new benefit year that start more than 60 days in the future.",
    onlyFirstList_3:
      "If the leave request for your current benefit year is approved, you can submit a request to extend your leave to {{leaveEndDate}} on or after {{sixtyDaysBeforeNewBYStartDate}}.",
    onlyFirstList_3_V2:
      "If the leave request for your current benefit year is approved, you will need to submit a separate application on or after <strong>{{sixtyDaysBeforeNewBYStartDate}}</strong> to cover the part of your leave request in your new benefit year: <strong>{{newBYStartDate}} to {{leaveEndDate}}.<strong>",
    onlyFirstList_4:
      "You'll have another unpaid 7-day waiting period at the start of your new benefit year. Learn more about the <seven-day-waiting-period-link>7-day waiting period</seven-day-waiting-period-link>.",
    secondCannotBeSubmittedNew:
      "You will be able to submit your new benefit year application on <strong>{{submittableDate}}</strong>. This is 60 days before the start of your leave in the new benefit year. Applications cannot be submitted earlier than 60 days before the start of leave.",
    title: "Your leave dates extend into a new benefit year.",
  },
  claimsModifyTitle: "Change your application",
  claimsOtherLeaveTitle: "Other leave, benefits, and income",
  claimsPreviousLeaveDetails: {
    addButton: "Add another previous leave",
    isForCurrentEmployerHint:
      "This is the same Employer Identification Number you entered earlier in your application. After you submit your application, this employer will be able to review it. We won’t share any leave you took from other employers.",
    isForCurrentEmployerLabel:
      "Did you take leave from this employer? EIN:$t(chars.nbsp){{employer_fein, ein}}",
    leaveEndDateLabel: "What was the last day of this leave?",
    leaveMinutesHint:
      "Add up all the hours you took off between the first and last day of this leave and enter the total. For example, if you took off 8 hours in one week and 12 hours in another, you should enter 20 hours.",
    leaveMinutesLabel: "What was the total number of hours you took off?",
    leaveStartDateLabel: "What was the first day of this leave?",
    limitMessage: "You can only add up to {{limit}} leaves",
    minimumDateHint: "This date must be on or after {{minimumDate}}.",
    removeButton: "Remove this previous leave",
    sectionHint:
      "Enter details about each period of leave taken between {{previousLeaveStartDate}} and {{leaveStartDate}}. A leave period begins on the day you first went on leave and ends on the last day of leave. If you were on leave intermittently, your leave period begins on the first day you went on leave and ends on the very last day.<br/><br/>You don't need to tell us about previous leave you’ve taken through Massachusetts’ PFML program.",
    sectionLabel: "Tell us about your previous leave",
    workedPerWeekMinutesDetails:
      "If your weekly schedule was not consistent, enter the average number of hours you would have worked per week. For example, if your leave was across two different weeks, and your normal work schedule was 40 hours in one week and 20 hours in the next, you should enter 30 hours.",
    workedPerWeekMinutesDetailsLabel: "What if my schedule was not consistent?",
    workedPerWeekMinutesHint:
      "Enter the number of hours you would have worked each week, if you were not on leave.",
    workedPerWeekMinutesLabel:
      "How many hours would you normally have worked per week at the time you took this leave?",
  },
  claimsPreviousLeaveErrors: {
    isForCurrentEmployer_required:
      "Select Yes if your leave is from this employer.",
    leaveEndDate_format:
      "The date your leave ended must include a valid month, day, and year.",
    leaveEndDate_invalidDateRange:
      "The date your leave ends must be on or after the date your leave began.",
    leaveEndDate_minimum: "Only enter previous leaves with a valid start date.",
    leaveEndDate_required: "Enter the date your leave ended.",
    leaveMinutes_maximum: "The hours entered are more than the possible hours.",
    leaveMinutes_required: "Enter the hours you took off work for your leave.",
    leaveReason_required: "Select the qualifying reason for your leave.",
    leaveStartDate_format:
      "The date your leave began must include a valid month, day, and year.",
    leaveStartDate_minimum:
      "Only enter previous leaves with a valid start date.",
    leaveStartDate_required: "Enter the date your leave began.",
    workedPerWeekMinutes_maximum:
      "The hours entered are more than the possible hours.",
    workedPerWeekMinutes_required: "Enter the hours you normally worked.",
  },
  claimsReportedWarning:
    "Your employee’s benefit amount and/or the amount of leave they’re eligible to take may be reduced by leave taken outside of PFML, paid time off, federal programs or other leave offered by an employer. Learn more about <benefit-reductions-link>how other leave and benefits affect payments.</benefit-reductions-link>",
  claimsStatus: {
    applicationAndIdSubmittedExtensions: "Extension request submitted",
    applicationAndIdSubmittedExtensionsAndDocuments:
      "Extension request submitted",
    applicationAndIdSubmittedMedToBonding: "Request for leave submitted",
    submitProof: "Submit proof",
  },
  claimsVerifyIdTitle: "Your identification",
  contactCenterAddress:
    "PO Box 838$t(chars.nbsp)Lawrence, MA$t(chars.nbsp)01842",
  contactCenterFaxNumber: "(617)$t(chars.nbsp)855$t(chars.nbhyphen)6180",
  contactCenterPhoneNumber: "(833) 344-7365",
  contactCenterPhoneNumberNoBreak:
    "(833)$t(chars.nbsp)344$t(chars.nbhyphen)7365",
  contactCenterReportHoursPhoneNumber: "(857) 972-9256",
  date: "{{date, date}}",
  dateRange: "{{startDate}} $t(shared.dateRangeDelimiter) {{endDate}}",
  dateRangeDelimiter: "to",
  day_Friday: "Friday",
  day_Monday: "Monday",
  day_Saturday: "Saturday",
  day_Sunday: "Sunday",
  day_Thursday: "Thursday",
  day_Tuesday: "Tuesday",
  day_Wednesday: "Wednesday",
  departmentOfRevenuePhoneNumber:
    "(617)$t(chars.nbsp)466$t(chars.nbhyphen)3950",
  dfmlTaxPhoneNumber: "(855)$t(chars.nbsp)610$t(chars.nbhyphen)9905",
  disallow12moLeavePeriod: "Your leave cannot be 12 months or more.",
  // TODO (CP-1335): Add i18next formatter for time
  displayTime: "{{hours}}h {{minutes}}m",
  // TODO (CP-1335): Add i18next formatter for time
  displayTime_noMinutes: "{{hours}}h",
  docsRequired: {
    newborn:
      "Once your child is born, submit proof of birth so that we can review your application. Learn more about <proof-document-link>proof of birth documents</proof-document-link> we accept.",
  },
  documentCategory: {
    certification: "certification form",
    identification: "identification documents",
  },
  documentDownloadError:
    "Your documents are temporarily unavailable. This may occur for a variety of reasons, including temporarily losing an internet connection or an unexpected error in our system. If this continues to happen, you may call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
  documentsLoadError:
    "An error was encountered while checking your application for documents. If this continues to happen, call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  documentsUploadError:
    "We encountered an error when uploading your file. Try uploading your file again. If this continues to happen, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  employerBenefitEntryPrefix: "Benefit",
  employerBenefitType_familyOrMedicalLeave: "Family or medical leave insurance",
  employerBenefitType_imNotSure:
    "I am not sure which benefit will fully cover my wages",
  employerBenefitType_paidLeave: "Accrued paid leave",
  employerBenefitType_paidTimeOff: "Paid time off",
  employerBenefitType_permanentDisability: "Permanent disability insurance",
  employerBenefitType_shortTermDisability: "Temporary disability insurance",
  employerInstructions_addComment:
    "If something is incomplete, add a comment at the end of the page.",
  employerInstructions_followUpDate:
    "<strong>Review and respond by:</strong> {{date}} at 11:59 p.m. Eastern time",
  ethnicity: {
    hispanicOrLatino: "Hispanic or Latino",
    notHispanicOrLatino: "Not Hispanic or Latino",
    preferNotToAnswer: "Prefer not to answer",
  },
  fileUpload_addAnotherFileButton: "Choose another file",
  fileUpload_addFirstFileButton: "Choose files",
  fileUpload_fileHeadingPrefix: "File",
  filesUploaded: "Number of files uploaded",
  genderGenderNotListed: "Gender not listed",
  genderMan: "Man",
  genderNonbinary: "Non-binary",
  genderPreferNotToAnswer: "Prefer not to answer",
  genderWoman: "Woman",
  hoursLabel: "Hours",
  infoAlertBody_bonding:
    "You may be able to take up to 20 weeks of paid medical leave if you’re unable to work during your pregnancy or during your recovery from birth. Your health care provider determines how much medical leave you will need. Call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> if you need <about-bonding-leave-link>leave for pregnancy or recovery from childbirth</about-bonding-leave-link>. You should not submit a second application.",
  infoAlertBody_pregnancy:
    "You may be able to take up to 12 weeks of paid family leave to bond with your child after your medical leave ends. <about-bonding-leave-link>Leave to bond with your child</about-bonding-leave-link> can be easily added to your claim after your medical leave is approved. You should not submit a second application.",
  infoAlertHeading_bonding:
    "If you are giving birth, you may also be eligible for paid leave for pregnancy or recovery from childbirth",
  infoAlertHeading_pregnancy:
    "You may also be eligible for paid leave to bond with a child",
  language: {
    ChineseSimplified: "中文",
    English: "English",
    French: "Français",
    HaitianCreole: "Kreyòl Ayisyen",
    Portuguese: "Português (Brasil)",
    Spanish: "Español",
    Vietnamese: "Tiếng Việt",
  },
  leavePeriodCaringAlert:
    "You will need a completed <caregiver-certification-form-link>$t(shared.certificationFormCare)</caregiver-certification-form-link> for this$t(chars.nbsp)section.",
  leavePeriodContinuousDatesLeadMedicalOrPregnancy:
    "Your answer must match the continuous leave period start and end dates in the $t(shared.certificationFormMedical).",
  leavePeriodHasLeaveHintCare:
    "Your answer must match the $t(shared.certificationFormCare).",
  leavePeriodHasLeaveHintMedicalOrPregnancy:
    "Your answer must match the $t(shared.certificationFormMedical).",
  leavePeriodIntermittentDatesLeadMedicalOrPregnancy:
    "Your answer must match the intermittent leave start and end dates in the $t(shared.certificationFormMedical).",
  leavePeriodMedicalAlert:
    "You will need a completed <healthcare-provider-form-link>$t(shared.certificationFormMedical)</healthcare-provider-form-link> for this$t(chars.nbsp)section.",
  leavePeriodReducedDatesLeadMedicalOrPregnancy:
    "Your answer must match the reduced leave start and end dates in the $t(shared.certificationFormMedical).",
  leaveReasonActiveDutyFamily: "Active duty",
  leaveReasonBonding: "Leave to bond with a child",
  leaveReasonBondingHeader: "Leave to bond with a child",
  leaveReasonCare: "Care for a family member",
  leaveReasonCareHeader: "Leave to care for a family member",
  leaveReasonMedAndBondingHeader:
    "Leave for pregnancy or recovery from childbirth and Leave to bond with a child",
  leaveReasonMedical: "Leave to manage your own serious health condition",
  leaveReasonMedicalIllness:
    "Leave to manage your own serious health condition",
  leaveReasonMedicalSchedule: "$t(shared.leaveReasonMedical)",
  leaveReasonPregnancy: "Leave for pregnancy or recovery from childbirth",
  leaveReasonPregnancyHeader: "Leave for pregnancy or recovery from childbirth",
  leaveReasonPregnancySchedule: "$t(shared.leaveReasonPregnancy)",
  leaveReasonServiceMemberFamily: "Military family",
  loadingClaimDetailLabel: "Loading details",
  loadingDocumentsLabel: "Loading documents",
  maxEmployerCommentLengthError:
    "Please shorten your comment. We cannot accept comments that are longer than 9999 characters.",
  maximumReducedLeaveMinutes:
    "Hours you will take off cannot exceed your work schedule.",
  minimumReducedLeaveMinutes:
    "Reduced leave schedule hours must be 0 or greater.",
  minutesLabel: "Minutes",
  modificationsSubmitButton: "Submit",
  networkError:
    "Sorry, an error was encountered. This may occur for a variety of reasons, including temporarily losing an internet connection or an unexpected error in our system. If this continues to happen, you may call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
  newbornCertificationDocOptions:
    "<ul><li>Your child’s birth certificate.</li><li>A note from your child’s health care provider stating your child’s date of birth.</li><li>A note from the health care provider of the person who gave birth stating your child’s date of birth.</li></ul>",
  noneReported: "None reported",
  otherIncomeEntryPrefix: "Income",
  otherIncomeType_jonesAct: "Jones Act benefits",
  otherIncomeType_otherEmployer:
    "Earnings or benefits from another employer, or through self-employment",
  otherIncomeType_railroadRetirement: "Railroad Retirement benefits",
  otherIncomeType_retirementDisability:
    "Disability benefits under a governmental retirement$t(chars.nbsp)plan",
  otherIncomeType_ssdi: "Social Security Disability Insurance",
  otherIncomeType_unemployment: "Unemployment Insurance",
  otherIncomeType_workersCompensation: "Workers Compensation",
  otherLeaveAndBenefits: {
    addOtherLeavePlan: "Add an other disability, family, or medical leave plan",
    typeLabel: "What kind of benefit is it?",
  },
  otherLeavesEmployeeReportedWarningTitle:
    "The information your employee reported may impact their benefits.",
  otherLeavesEmployerReportingWarningTitle:
    "Information you report may impact your employee’s benefits.",
  passwordHint:
    "Your password must be at least 12$t(chars.nbsp)characters long and include at least 1$t(chars.nbsp)number, 1$t(chars.nbsp)symbol, and both uppercase and lowercase letters.",
  passwordLabel: "Password",
  paymentMethodAch: "Direct deposit into my bank account",
  paymentMethodCheck: "Paper check",
  paymentMethodPrepaidDebitCard: "Prepaid Debit Card",
  pdfNoticeSuffix: "(PDF)",
  previousLeaveEntryPrefix: "Previous leave",
  ptoLegislation: {
    body_v1:
      "For applications filed on or after November 1st, 2023, employees' PFML payments can be supplemented with employer Paid Time Off (PTO). This includes vacation days, sick days, and personal time, as long as the combined total benefit amount is not greater than the Individual Average Weekly Wage (IAWW). <ul><li>The employer is responsible for providing any supplemental payments</li><li>Employers cannot require employees to use PTO during PFML leave</li><li>Employers can continue to use temporary disability or paid family and medical leave plans to supplement PFML payments.</li></ul>",
    body_v2:
      "Employees' PFML payments can be supplemented with employer Paid Time Off (PTO). This includes vacation days, sick days, and personal time, as long as the combined total benefit amount is not greater than the Individual Average Weekly Wage (IAWW). <ul><li>The employer is responsible for providing any supplemental payments</li><li>Employers cannot require employees to use PTO during PFML leave</li><li>Employers can continue to use temporary disability or paid family and medical leave plans to supplement PFML payments.</li></ul>",
    link: "Learn more about <pto-legislation-link>using Paid Time Off during PFML leave.</pto-legislation-link>",
    note_v1:
      "<strong>Note:</strong> When you review the application, make sure that the employee has not reported any paid time off (PTO) used to supplement their PFML payments.",
    note_v2:
      '<strong>Note:</strong> When you review the application, make sure that your employee has not reported any paid time off (PTO) used to supplement their PFML payments. If your employee has reported PTO, remove it by checking that row\'s "Remove" button.',
    title_v1:
      "Legislative change: Employees can now supplement PFML payments with Paid Time Off",
    title_v2: "Employees can supplement PFML payments with Paid Time Off",
  },
  qualifyingReasonDetailsLabel: "What counts as a qualifying reason?",
  race: {
    americanIndianAlaskaNative: "American Indian/Alaska Native",
    anotherRaceNotListedAbove: "Another race not listed above",
    asianAsianAmerican: "Asian/Asian American",
    blackAfricanAmerican: "Black/African American",
    multiracial: "Multiracial",
    nativeHawaiianOtherPacificIslander:
      "Native Hawaiian/Other Pacific Islander",
    preferNotToAnswer: "Prefer not to answer",
    white: "White",
  },
  reducedLeaveScheduleLeadCertGuidanceMedicalOrPregnancy:
    "The total number of hours you enter must match the reduced leave schedule section in the $t(shared.certificationFormMedical).",
  repeatPasswordLabel: "Confirm Password",
  resendVerificationCodeLink: "Resend the code",
  saveAndContinue: "Save and continue",
  siteDescription:
    "Apply for this Commonwealth-offered benefit here, or log in to review your applications.",
  startByPhoneLabel: "Did you start an application by phone?",
  submitAppealTitle: "Submit an appeal",
  submitApplicationButton: "I understand and agree",
  taxDocumentDownloadError:
    "Your tax documents are temporarily unavailable. This may occur for a variety of reasons, including temporarily losing an internet connection or an unexpected error in our system. Please try again later. If this continues to happen, you may call the DFML tax line at <dfml-tax-phone-link>$t(shared.dfmlTaxPhoneNumber)</dfml-tax-phone-link>",
  taxDocumentsLoadError:
    "An error was encountered while checking your account for tax documents. If this continues to happen, call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
  trackStatus:
    "<track-status-link>Track the status of your application here</track-status-link>.",
  unauthorizedLeaveAdminError:
    "Sorry, you do not have permission to view that page. To access it, you need to <add-org-link>add that organization</add-org-link> to your account.",
  uploadingMessage: "Uploading documents… Do not refresh or go back.",
  usernameLabel: "Email address",
  verificationCodeLabel: "6-digit code",
  viewPostSubmissionVideo:
    "View the video below to learn about what happens between submitting your application and receiving payments.",
  yourChangeRequestTag: "YOUR CHANGE REQUEST",
};

const pages: {
  // Keys are limited to three levels deep, for example `pages.claimsDateOfBirth.title`.
  // This makes the structure easier to navigate and the process of finding a specific element more consistent
  [pageKey: string]: I18nContentGroup;
} = {
  app: {
    seoDescription: "$t(shared.siteDescription)",
  },
  appealsCircumstances: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hadCircumstancesLabel:
      "Were there any circumstances that prevented you from appealing the decision sooner?",
    reasonHint: "Explain the reason for the delay.",
    reasonLabel: "What prevented you?",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsHaveDocuments: {
    alertBody: "Please upload your documents to speed up the process.",
    alertHeading: "Your appeal has been received",
    backButtonLabel: "$t(shared.backToYourApplicationLink)",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    continueButton: "Continue",
    haveDocumentsHint:
      "Uploading relevant information sooner will help to speed up the review process. You will be able to upload additional information after you've submitted this form.",
    haveDocumentsLabel: "Have documents to upload?",
    title: "Add appeals documents",
  },
  appealsInterpreter: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    customLanguageLabel: "Enter your language below.",
    interpreterHint:
      "If your appeal results in a hearing, an interpreter will be provided through <multilingual-services-link>EOLWD Multilingual Services</multilingual-services-link>, which provides free spoken language interpreters and translators. A friend or family member cannot interpret on your behalf.",
    languageLabel: "Choose your language.",
    needsLabel: "Will you need an interpreter?",
    otherLanguage: "Not listed above",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsNoticeDate: {
    label: "When did you receive the DFML decision that you’re appealing?",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsNoticeRead: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hasReadLabel:
      "Did you read the DFML decision you are appealing when you received it?",
    reasonLabel: "Why didn't you read the notices?",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsPhone: {
    header: "What is the best phone number to reach you?",
    phoneHint:
      "This contact information is only used for the appeals process and does not affect information previously provided in your application.",
    phoneLabel: "Phone number",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsReason: {
    backButtonLabel: "$t(shared.backToYourApplicationLink)",
    header: "What is the reason for the appeal?",
    reasonLabel: "Explain the reason for this appeal",
    reasonText: "<p><strong>Reason for appeal (Optional)</strong></p>",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsRepresentative: {
    choiceNo: "$t(shared.choiceNo)",
    choiceUnknown: "Unknown at this time",
    choiceYes: "$t(shared.choiceYes)",
    label: "Will you have a representative?",
    noticeOfAppearance:
      'If you have a "Notice of appearance" from your representative, you will have the opportunity to upload it later.',
    representativeHint:
      "Although legal representation is not required, you may be represented at the hearing by a lawyer or an agent. If you cannot find a lawyer to represent you, you may meet the criteria for obtaining assistance. Visit <legal-help-link>Finding legal help</legal-help-link> for more information on free legal services.  ",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsReview: {
    absenceIdLabel: "Application ID",
    attestation:
      "By submitting an appeal, I affirm that all the information I provide is true and correct.",
    circumstancesChoiceNo: "$t(shared.choiceNo)",
    circumstancesChoiceYes: "$t(shared.choiceYes)",
    circumstancesLabel: "Preventing circumstances",
    detailsHeading: "Appeal details",
    editLink: "Edit",
    feinLabel: "Employer Identification Number (EIN)",
    languageLabel: "Language",
    nameLabel: "Name",
    needsInterpreterChoiceNo: "$t(shared.choiceNo)",
    needsInterpreterChoiceYes: "$t(shared.choiceYes)",
    needsInterpreterLabel: "Interpreter",
    noticeDateLabel: "Notice received on",
    phoneLabel: "Phone number",
    readNoticesChoiceNo: "$t(shared.choiceNo)",
    readNoticesChoiceYes: "$t(shared.choiceYes)",
    readNoticesLabel: "Read notices",
    reasonLabel: "Reason for appeal",
    representativeChoice_no: "$t(shared.choiceNo)",
    representativeChoice_unknown: "Unknown",
    representativeChoice_yes: "$t(shared.choiceYes)",
    representativeLabel: "Representative",
    submitButton: "Submit appeal",
    title: "Review and submit your appeal",
  },
  appealsStart: {
    continueButton: "Continue",
    header: "Before you appeal",
    hintText:
      "<p>\t\n" +
      "We understand any delay at this time is tough. Your appeal helps us gather necessary information so you can get back to the things that matter.</p><p>To keep this process simple, we recommend you:</p><ul><li>Review all notices for your application</li><li>Prepare any supporting documentation</li><li>Answer all phone calls from DFML</li><li>Keep an eye out for mail and emails from DFML</li><li><request-an-appeal-for-pfml>Learn more about appealing</request-an-appeal-for-pfml></li></ul><p>We appreciate your patience.</p>",
    title: "$t(shared.submitAppealTitle)",
  },
  appealsUpload: {
    documentsLoadError: "$t(shared.documentsLoadError)",
    heading: "Upload your supporting documents",
    submitButton: "Submit documents",
    title: "Add appeals documents",
    uploadingMessage: "$t(shared.uploadingMessage)",
  },
  applications: {
    addApplication: "$t(shared.addApplication)",
    applicationOneSuccess:
      "You successfully submitted Part 1. Submit Parts 2 and 3 so that we can review your application.",
    applicationTwoSubmitLater:
      "You will be able to submit Part 1 of your new benefit year application on <strong>{{submitDate}}</strong>. This is 60 days before the start of your leave in the new benefit year. Applications cannot be submitted earlier than 60 days before the start of leave.",
    benefitYearEligibility:
      "Learn more about <benefit-year-eligibility-link>your eligibility for family and medical leave per benefit year.</benefit-year-eligibility-link>",
    bothApplicationsWereSubmitted:
      "You successfully submitted Part 1. Your application was split into two, one for each benefit year. Submit Parts 2 and 3 so that we can review your applications.",
    can_submit_application_across_benefit_year:
      "If you submit an application with leave dates that are in both your current and a new benefit year, we'll review the request as two separate applications.",
    claimsApprovalProcess:
      "Learn more about <approval-process-link>DFML's application approval process</approval-process-link>.",
    claimsReportedWarning: "$t(shared.claimsReportedWarning)",
    createApplicationHeading: "Create a new application",
    currentBenefitYearLeave:
      "<strong>Current benefit year:</strong> leave dates from {{startDate}} to {{endDate}}",
    eligibleFor26Weeks:
      "Most Massachusetts employees are eligible for up to 26 weeks of combined family and medical leave per benefit year.",
    getReadyLink: "Start a new application",
    nextBenefitYearLeave:
      "<strong>New benefit year:</strong> leave dates from {{startDate}} to {{endDate}}",
    startByPhoneDescription: "$t(shared.applicationImportDescription)",
    startByPhoneLabel: "$t(shared.startByPhoneLabel)",
    title: "Your applications",
    uploadSuccessHeading: "You successfully submitted your documents",
    uploadSuccessMessage_Appeals:
      "Our team will review your documents for {{absence_id}}.",
    uploadSuccessMessage_Default:
      "Our Contact Center staff will review your documents for {{absence_id}}.",
    withdrawnApplicationAlertBody:
      "You will receive a notice and the status of your application will be updated when we review your change request. We usually review requests in 3 to 5 business days.",
    withdrawnApplicationAlertHeading:
      "You've requested to withdraw your application",
    yourApplicationWasSplit:
      "Your application was split into two, one for each benefit year.",
    your_benefit_year:
      "Your current <benefit-year-guide-link>benefit year</benefit-year-guide-link> is {{startDate}} to {{endDate}}.",
    your_benefit_year_out_of_sync_disclaimer:
      "Changes you make to your leave dates usually take 1 to 2 business days to update on the website.",
  },
  authLogin: {
    sessionTimedOut: "You were logged out due to inactivity",
    sessionTimedOutHeading: "Session timed out",
  },
  authTwoFactorSmsConfirm: {
    codeLabel: "$t(shared.verificationCodeLabel)",
    lead: "Enter the 6-digit code we sent to your phone number {{mfaPhoneNumber}}.",
    resendCodeButton: "$t(shared.resendVerificationCodeLink)",
    saveButton: "$t(shared.saveAndContinue)",
    title: "Confirm your phone number",
  },
  authTwoFactorSmsIndex: {
    hint: "<p>This optional step makes your account more secure and gives you access to additional features. You can add, change, or remove your phone number at any time by going to the Settings page.</p><p>If you select Yes, we’ll ask for a phone number that can receive text messages (SMS). When you log in to your account, we’ll send a 6-digit code you’ll need to enter to verify it’s really you.</p>",
    hint_employer:
      "<p>This optional step makes your account more secure and gives you access to additional features. You can add, change, or remove your phone number at any time by going to the Settings page.</p><p>If you select Yes, we’ll ask for a phone number that can receive text messages (SMS). When you log in to your account, we’ll send a 6-digit code you’ll need to enter to verify it’s really you.</p><p>If you don’t add a cell phone number to verify your login, you’ll still be able to manage leave. However, you will not be able to add or remove leave administrators.</p>",
    optIn: "Yes, I want to add a phone number for additional security.",
    optOut: "No, I do not want to add a phone number.",
    optOutNoSms: "I do not have a phone that can receive text messages.",
    saveButton: "$t(shared.saveAndContinue)",
    title:
      "Do you want to add a cell phone number to verify it's you when you log in?",
  },
  authTwoFactorSmsSetup: {
    lead: "Enter a number that can receive text messages and that you'll have consistent access to. We’ll send a 6-digit code by text message (SMS) to confirm your phone number.",
    phoneNumberLabel: "Phone number",
    saveButton: "$t(shared.saveAndContinue)",
    skipButton: "$t(shared.auth.skip)",
    title:
      "Provide a phone number we can use when we need to verify your login",
  },
  authTwoFactorSmsVerify: {
    checkboxHint:
      "We won’t text you a 6-digit code unless we need to re-verify this device.",
    checkboxLabel: "Trust this device",
    codeLabel: "$t(shared.verificationCodeLabel)",
    lead: "To protect your security we sent a 6-digit code to the phone number we have on file for verification.  Don’t share this code with anyone. We’ll never call or text you for this code.",
    resendCode:
      "To receive a new code, <login-link>log in again</login-link>. If you need help, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    submitButton: "Submit",
    title: "Enter your security code",
  },
  changeEmail: {
    backButton: "$t(shared.changeEmailBackButtonLabel)",
    lead: "We will email a 6-digit verification code to verify you have access to the new email addess.",
    subTitle: "Enter your new email address",
    submitButton: "Continue",
    textLabel: "New email address",
    title: "$t(shared.changeEmailTitle)",
  },
  claimsAdditionalUserNotFoundInfo: {
    beginAdditionalUserNotFoundInfoButton: "I understand",
    beginAdditionalUserNotFoundInfoDescription:
      "We need to collect additional information to process your application.",
    beginAdditionalUserNotFoundInfoSubheading:
      "We still couldn't find the employer you entered.",
    beginAdditionalUserNotFoundInfoSubheading_noEmployee:
      "We still couldn’t match you with the employer you entered.",
    currentlyWorkAtEmployerLabel: "Do you currently work at this employer?",
    dateOfHireLabel: "What was your start date at this employer?",
    dateOfSeparationLabel: "When was your last day at this employer?",
    employerNameHint: "The company name as it appears on official documents.",
    employerNameTitle: "What is your employer name?",
    noMatchFoundDescription:
      "This could happen if your Social Security Number or the employer's Employer Identification Number is entered incorrectly. Check and re-enter your Social Security Number and employer's Employer Identification Number. ",
    noMatchFoundFeinLabel: "Employer Identification Number (EIN)",
    noMatchFoundFormLabel: "We couldn't find the employer you entered.",
    noMatchFoundFormLabel_noEmployee:
      "We couldn’t match you with the employer you entered.",
    noMatchFoundLink:
      "Find your employer’s Federal Employer Identification Number",
    noMatchFoundSsnLabel: "Social Security Number",
    recentlyAcquiredOrMergedIDontKnowLabel: "I don't know",
    recentlyAcquiredOrMergedLabel:
      "Was your employer recently merged with or acquired by another company?",
    recentlyAcquiredOrMergedNoLabel: "No",
    recentlyAcquiredOrMergedYesLabel: "Yes",
  },
  claimsAddress: {
    addressSuggestion: "Please select the address from below list",
    choiceNo: "No, I would like to add a mailing address",
    choiceYes: "$t(shared.choiceYes)",
    defaultAddressWithResults:
      "Or continue with provided but unverfied address",
    defaultAddressWithoutResults:
      "Address cannot verified, do you want to continue with provided address?",
    hasMailingAddressHint:
      "We may send notices to this address. If you choose to get your payments through paper checks, we will mail the checks to this address.",
    hasMailingAddressLabel: "Do you get your mail at this address?",
    hint: "We will use this as your residential address for any previous applications you have submitted. If you are part of an Address Confidentiality Program, please provide your substitute address.",
    mailingAddressHint:
      "We will use this as your mailing address for any previous applications you have submitted.",
    mailingAddressLabel: "What is your mailing address?",
    sectionLabel: "What is your current residential address?",
    title: "$t(shared.claimsVerifyIdTitle)",
    validateAddress: "Validate Address",
  },
  claimsBondingDateOfChild: {
    birthHint:
      "If your child has not been born yet, enter the expected due date.",
    sectionLabel_adopt_foster:
      "When did the child arrive in your home through foster care or adoption?",
    sectionLabel_newborn: "When was your child born?",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsBondingLeaveAttestation: {
    lead: "<p>This paid leave applies to parents of children who have joined their family in the past 12 months via:</p><ul><li>Birth</li><li>Foster placement</li><li>Adoption</li></ul>",
    submitApplicationButton: "$t(shared.submitApplicationButton)",
    title: "Confirm that you are an eligible parent",
    truthAttestation:
      "I agree that I am a new parent by one of the routes listed and can provide certification to support this relationship.",
  },
  claimsCaringLeaveAttestation: {
    lead: "This paid leave applies to caregivers with an <caregiver-relationship-link>eligible family relationship</caregiver-relationship-link>.",
    submitApplicationButton: "$t(shared.submitApplicationButton)",
    title: "Confirm that you are an eligible caregiver",
    truthAttestation_child:
      "I agree that I have an eligible relationship with my child who is experiencing a serious health condition and needs my care.",
    truthAttestation_grandchild:
      "I agree that I have an eligible relationship with my grandchild who is experiencing a serious health condition and needs my care.",
    truthAttestation_grandparent:
      "I agree that I have an eligible relationship with my grandparent who is experiencing a serious health condition and needs my care.",
    truthAttestation_inlaw:
      "I agree that I have an eligible relationship with a parent of my spouse or domestic partner who is experiencing a serious health condition and needs my care.",
    truthAttestation_parent:
      "I agree that I have an eligible relationship with my parent who is experiencing a serious health condition and needs my care.",
    truthAttestation_sibling:
      "I agree that I have an eligible relationship with my sibling who is experiencing a serious health condition and needs my care.",
    truthAttestation_spouse:
      "I agree that I have an eligible relationship with my spouse or domestic partner who is experiencing a serious health condition and needs my care.",
  },
  claimsChecklist: {
    afterSubmissionAlertTitle_manualReview: "Needs manual review",
    afterSubmissionAlert_manualReview:
      "We couldn't find an association between your Social Security Number and the employer you entered in our system. Your application was submitted for manual review. We will notify you with next steps once your application has been reviewed.",
    afterSubmissionAlert_partOne:
      "You successfully submitted Part 1. Submit Parts 2 and 3 so that we can review your application.",
    afterSubmissionAlert_payment:
      "You successfully submitted your payment method. Complete the remaining steps so that you can submit your application.",
    afterSubmissionAlert_taxPref:
      "You successfully submitted your tax withholding preference. Complete the remaining steps so that you can submit your application.",
    backButtonLabel: "Back to applications",
    completed_editable: "$t(components.statusItems.completed)",
    completed_uneditable: "Confirmed",
    documentsLoadError: "$t(shared.documentsLoadError)",
    edit: "Edit",
    leaveInSecondBenefitYearNotReviewableTodayAlert:
      "<p>The leave dates you’re requesting extend into a new benefit year that starts more than 60 days in the future.</p><p><strong>Your current <benefit-year-what-is-link>benefit year</benefit-year-what-is-link> is {{benefitYearStartDate}} to {{benefitYearEndDate}}.</strong></p><p>DFML will review the following leave request dates: {{leaveStartDate}} to {{benefitYearEndDate}}.</p><p>If the leave request for your current benefit year is approved, you can submit a request to extend your leave on or after {{applicationSubmittableOn}}.</p>",
    leaveInSecondBenefitYearNotReviewableTodayAlertV2:
      "<p>The leave dates you’re requesting extend into a new benefit year that starts more than 60 days in the future.</p><p><strong>Your current <benefit-year-what-is-link>benefit year</benefit-year-what-is-link> is {{benefitYearStartDate}} to {{benefitYearEndDate}}.</strong></p><p>DFML will review the following leave request dates: {{leaveStartDate}} to {{benefitYearEndDate}}.</p><p>If the leave request for your current benefit year is approved, you will need to submit a separate application on or after <strong>{{applicationSubmittableOn}}</strong> to cover the part of your leave request in your new benefit year: <strong>{{newBYStartDate}} to {{leaveEndDate}}</strong>.</p>",
    leaveInSecondBenefitYearReviewableTodayAlert:
      "<p>The leave dates you’re requesting extend into a new benefit year.</p><p><strong>Your current <benefit-year-what-is-link>benefit year</benefit-year-what-is-link> is {{startDate}} to {{endDate}}.</strong></p><p>Your application will have <benefit-year-spans-two-link>two benefit years</benefit-year-spans-two-link>.  We will review each benefit year leave request.</p>",
    // TODO (CP-2354) Remove CC guidance for claims with Part 1 submitted without reductions data
    otherLeaveSubmittedDetailsBody:
      "<ul><li>You have already taken leave to care for a family member since July 1, 2021</li><li>You have already taken leave since {{otherLeaveStartDate}} <when-can-i-use-pfml>for any other reason that qualifies for PFML</when-can-i-use-pfml></li><li>You plan to use any accrued paid leave or any other benefits from your employer during your paid leave from PFML (for example: your employer’s parental leave program or paid sick time)</li><li>You expect to get income from any other sources during your leave (for example: disability insurance, retirement benefits, or another job)</li></ul>",
    otherLeaveSubmittedDetailsLabel: "What do I need to report?",
    otherLeaveSubmittedIntro:
      "<p>If you have any other leaves, benefits, or income to report, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",

    resume: "Resume",
    // Resume button aria-label for screen readers, since VoiceOver reads "résumé":
    resumeScreenReader: "Continue with",
    screenReaderNumberPrefix: "Step",
    start: "Start",
    stepHTMLDescription_bondingAdoptFoster:
      "<p>You need to provide a statement confirming the placement and the date of placement.</p><p>Your certification documents will be shared with your employer as part of your application.</p>",
    stepHTMLDescription_bondingAdoptFosterFuture:
      "After your child arrives in your home, you will need to provide a statement confirming the placement and the date of placement.",
    stepHTMLDescription_bondingNewborn:
      "<p>You need to provide your child’s birth certificate or a document from a health care provider that shows the child’s date of birth.</p><p>Your certification documents will be shared with your employer as part of your application.</p>",
    stepHTMLDescription_bondingNewbornFuture:
      "After your child is born you will need to provide your child’s birth certificate or a document from a health care provider that shows the child’s date of birth.",
    stepHTMLDescription_care:
      "<p>Provide your completed <family-member-serious-health-condition-form-link>$t(shared.certificationFormCare)</family-member-serious-health-condition-form-link>.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_employerInformation:
      "You will need to know:<ul><li>Your employer’s 9 digit federal employer identification number (FEIN or EIN). <br><strong>Where to find this: </strong>on your W$t(chars.nbhyphen)2 or 1099-MISC, or ask your employer’s finance department.</li><li>The date you told your employer you were taking leave.</li></ul><p>If you are taking leave from multiple employers, you must create separate applications for each job.</p>",
    stepHTMLDescription_leaveDetails:
      "<p>You’ll enter the type of leave you’re taking. For medical leave and leave to care for a family member, use the dates from your completed DFML provided certification form to fill out the application.</p><p>You’ll upload your required documents in steps 8 and 9 of the application.</p><p>If you’re not sure what kind of leave to apply for, learn about <leave-types-link>types of Paid Family and Medical Leave.</leave-types-link></p>",
    stepHTMLDescription_leaveDetailsWithoutMedToBonding:
      "<p>You’ll enter the type of leave you’re taking. For medical leave and leave to care for a family member, use the dates from your completed DFML provided certification form to fill out the application.</p><p>You’ll upload your required documents in steps 8 and 9 of the application.</p><p>If you’re not sure what kind of leave to apply for, learn about <leave-types-link>types of Paid Family and Medical Leave.</leave-types-link></p>",
    stepHTMLDescription_medical:
      "<p>Provide your completed <healthcare-provider-form-link>$t(shared.certificationFormMedical)</healthcare-provider-form-link>.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_otherLeave:
      "You will need to know:<ul><li>If you will use any benefits from your employer because you are taking leave.</li><li>If you will receive income from any other sources during your leave.</li><li>The dates for any leave you’ve taken since {{otherLeaveStartDate}} for a reason that is covered by Paid Family and Medical Leave.</li></ul>",
    stepHTMLDescription_otherLeaveClaimantPtoTopoff:
      "You will need to know:<ul><li>If you will use any benefits from your employer because you are taking leave.</li><li>If you will receive income from any other sources during your leave.</li><li>The dates for any leave you’ve taken since {{otherLeaveStartDate}} for a reason that is covered by Paid Family and Medical Leave.</li></ul><p>You don’t need to report paid time off (PTO) from your employer, such as vacation, sick, or personal days that you will use to supplement your PFML payments.</p><p><learn-olb-pfml-link>Learn how other leave and benefits can affect your Paid Family and Medical Leave</learn-olb-pfml-link></p>",
    stepHTMLDescription_payment:
      "<p>If you want to receive payment by direct deposit, you will need to provide your bank account information, including a routing number and account number.</p>",
    stepHTMLDescription_reviewAndConfirm:
      "<p>Once you confirm your leave information, we’ll notify your employer. Your job will be protected. To complete your application, you will need to finish the following three steps and submit.</p><p>If you need to edit your information in Part 1 after completing this step, you’ll need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",
    stepHTMLDescription_taxWithholding:
      "<p>You need to decide if you want to withhold state and federal taxes from this benefit. If you're unsure, we recommend speaking with a tax professional.</p>",
    stepHTMLDescription_uploadCareForFamilyMember:
      "<p>You need to provide your completed <caregiver-certification-form-link>Certification of Your Family Member's Serious Health Condition</caregiver-certification-form-link> or the <active-duty-caregiver-form-link>WH-385 FMLA form for active duty service members</active-duty-caregiver-form-link> or the <veteran-form-link>WH-385-V FMLA form for veterans</veteran-form-link>.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_uploadFamilyMemberServiceProof:
      "<p>Upload proof of military service - Active Duty Orders or Letter of Impending Activation or other government-issued military information.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_uploadId:
      "<p>Upload proof of identity. If you entered a Massachusetts driver’s license or Mass ID number in step 1, upload the same$t(chars.nbsp)ID.</p><p>For other IDs, follow the instructions for acceptable proof of identity on the upload page.</p>",
    stepHTMLDescription_uploadMilitaryExigency:
      "<p>You need to provide your completed military exigency form.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_uploadServiceMemberProof:
      "<p>Upload proof of identity as a service member - Active Duty Orders or other government-issued military information.</p><p>Your certification documents will be shared with your employer as part of your leave application.</p>",
    stepHTMLDescription_verifyId:
      "<p>You can use a variety of documents to verify your identity, but it’s easiest if you have a Massachusetts driver’s license or Massachusetts Identification Card.</p><p>You will need to provide:</p><ul><li>Your name as it appears on your ID.</li><li>Your driver’s license number or Mass ID number, if you have one.</li><li>Your Social Security Number or Individual Taxpayer Identification Number.</li></ul>",
    stepListDescription_1:
      "Your progress is automatically saved as you complete the application. You can edit any information you enter in Part 1 until step 5 is completed.",
    stepListDescription_1_submitted:
      "Your in-progress application will be viewable by our Contact Center staff. If you need to edit your information in Part 1, you’ll need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Your application ID is <strong>{{absence_id}}</strong>.",
    stepListDescription_2:
      "Entering information here leads to faster processing, but you can also call$t(chars.nbsp)<contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    stepListDescription_2_manualReview:
      "If you need to edit your information in Part 2, you’ll need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Your application was submitted for manual review.",
    stepListDescription_2_submitted:
      "If you need to edit your information in Part 2, you’ll need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Your application ID is <strong>{{absence_id}}</strong>.",
    stepListDescription_3:
      "Uploading your documents online leads to faster processing, but you can also <mail-fax-instructions-link>fax or mail documents</mail-fax-instructions-link>. If you fax or mail documents to DFML, your application will be updated once we receive your documents. You’ll need to return to this page to complete and submit your application.",
    stepListTitlePrefix: "Part {{number}}",
    stepListTitle_1: "Tell us about yourself and your leave",
    stepListTitle_2: "Tell us how you want to receive your benefit",
    stepListTitle_3: "Upload required documents",
    stepTitle_employerInformation: "Enter employment information",
    stepTitle_leaveDetails: "Enter leave details",
    stepTitle_otherLeave: "Report other leave, benefits, and income",
    stepTitle_payment: "Enter payment method",
    stepTitle_reviewAndConfirm: "Review and confirm",
    stepTitle_taxWithholding: "Enter tax withholding preference",
    stepTitle_uploadCareForFamilyMember: "Upload Care for a Family Member form",
    stepTitle_uploadCertification: "Upload leave certification documents",
    stepTitle_uploadFamilyMemberServiceProof:
      "Upload family member active duty service proof",
    stepTitle_uploadId: "Upload proof of identity",
    stepTitle_uploadMilitaryExigency: "Upload miliary exigency document",
    stepTitle_uploadServiceMemberProof: "Upload covered service member ID",
    stepTitle_verifyId: "Verify your identification",
    submitButton: "Review and submit application",
    title: "Your in-progress application",
    titleBody: "Submit all three parts so that we can review your application.",
  },

  claimsDateOfBirth: {
    sectionLabel: "What’s your date of birth?",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsEmployerBenefits: {
    addButton: "Add another policy",
    cardSubHeading:
      "Benefit received from your current employer EIN {{employer_fein}}",
    cardSubHeadingDescription:
      "We only need to know if the benefit you are reporting was from this employer. Your employer will be able to review this benefit after you submit your application.",
    choiceHint_familyOrMedicalLeave:
      "$t(shared.choiceHint_familyOrMedicalLeave)",
    choiceHint_paidLeave:
      "For example, vacation time, sick leave, or personal time",
    choiceHint_paidTimeOff: "$t(shared.choiceHint_paidTimeOff)",
    choiceHint_shortTermDisability: "$t(shared.choiceHint_shortTermDisability)",
    choiceLabel_familyOrMedicalLeave:
      "$t(shared.employerBenefitType_familyOrMedicalLeave)",
    choiceLabel_imNotSure: "$t(shared.employerBenefitType_imNotSure)",
    choiceLabel_imNotSureEnableNewEmployerBenefitWording:
      "I am not sure which of the <strong>above benefits</strong> will fully cover my wages",
    choiceLabel_no: "$t(shared.choiceNo)",
    choiceLabel_paidLeave: "$t(shared.employerBenefitType_paidLeave)",
    choiceLabel_paidTimeOffEnableNewEmployerBenefitWording:
      "$t(shared.employerBenefitType_paidTimeOff)",
    choiceLabel_permanentDisability:
      "$t(shared.employerBenefitType_permanentDisability)",
    choiceLabel_shortTermDisability:
      "$t(shared.employerBenefitType_shortTermDisability)",
    choiceLabel_yes: "$t(shared.choiceYes)",
    choiceNo: "$t(shared.choiceNo)",
    choiceNoAlert:
      "If you receive benefit payments that will cover your wages in full after you apply, you must call the Contact Center at (833) 344-7365 to let us know.",
    choiceNoHint:
      "I will not receive employer benefits while I am on PFML that will cover my wages in full. I have applied for benefits, but it hasn't yet been approved; or I don’t know my benefit amount yet.",
    choiceYes: "$t(shared.choiceYes)",
    choiceYesAlert:
      "Only select ‘Yes’ if you will be receiving employer or disability insurance payments that will fully cover your weekly wages. If anything is reported in this section, it may result in you receiving zero dollars ($0) in PFML benefits for some or all of your leave.",
    choiceYesAlertV2:
      "Do not select 'Yes' to report paid time off that will only cover a portion of your wages for a period of time. We only want to know about benefits that will cover 100% of your wages.  Note: If you select 'Yes' in this section, you will be ineligible to receive payments from the Department of Family and Medical Leave during that time.",
    choiceYesHint:
      "I will receive employer benefits while I am on PFML that will <strong>cover my wages in full</strong>.",
    choiceYesHintV2:
      "I will receive at least one of the employer benefits below while I am on PFML that will <strong>cover my wages in full.</strong><p><ul><li>Temporary disability insurance (Short-term or long-term disability)</li><li>Permanent disability insurance</li><li>Family or medical leave insurance (For example, a paid parental leave policy)</li><li>Paid time off (Vacation days, sick days, or personal time)</li></ul></p>",
    endDateLabel: "When will you stop receiving your full wage payments?",
    isFullSalaryContinuousLabel:
      "Does this employer-sponsored benefit fully replace your wages?",
    limitMessage: "You can only add up to {{limit}} benefits",
    removeButton: "Remove benefit",
    sectionLabel:
      "Will you receive benefit payments from your employer EIN {{employer_fein}} that will cover your wages in full {{dateIntervalText}} {{dayAfterWaitingPeriodFormatted}} and {{leaveEndDateFormatted}}?",
    sectionLabelShortLeaveEdgeCase:
      "Will you receive benefit payments from your employer EIN {{employer_fein}} that will cover your wages in full while on PFML?",
    startDateLabel: "When will you start receiving your full wage payments?",
    title: "$t(shared.claimsOtherLeaveTitle)",
    typeLabel: "Which benefit will fully cover your wages?",
  },
  claimsEmployerBenefitsDetails: {
    addButton: "Add another benefit",
    cardHeadingPrefix: "$t(shared.employerBenefitEntryPrefix)",
    choiceHint_familyOrMedicalLeave:
      "$t(shared.choiceHint_familyOrMedicalLeave)",
    choiceHint_paidLeave:
      "For example, vacation time, sick leave, or personal time",
    choiceHint_shortTermDisability: "$t(shared.choiceHint_shortTermDisability)",
    choiceLabel_familyOrMedicalLeave:
      "$t(shared.employerBenefitType_familyOrMedicalLeave)",
    choiceLabel_no: "$t(shared.choiceNo)",
    choiceLabel_paidLeave: "$t(shared.employerBenefitType_paidLeave)",
    choiceLabel_permanentDisability:
      "$t(shared.employerBenefitType_permanentDisability)",
  },
  claimsEmployerBenefitsIntro: {
    intro_1:
      "<p>We need to know if you will receive any employer benefit payments that will <strong>cover your wages in full</strong> on any day after your PFML waiting period ends. Your waiting period ends {{lastWaitingPeriodDayFormatted}}.</p>",
    intro_1_shortLeaveEdgeCase:
      "<p>We need to know if you will receive any employer benefit payments that will <strong>cover your wages in full</strong> on any day after your PFML waiting period ends.</p>",
    intro_2:
      "<p><strong>You only need to report employer benefit payments from:</strong></p>",
    list_1:
      "<ul><li>Temporary disability insurance <br /> <i> Short-term or long-term disability</i></li><li>Permanent disability insurance</li><li>Family or medical leave insurance <br /><i>Paid parental leave program or maternity disability insurance</i></li></ul>",
    list_1v2:
      "<ul><li>Temporary disability insurance <br /> <i> Short-term or long-term disability</i></li><li>Permanent disability insurance</li><li>Family or medical leave insurance <br /><i>Paid parental leave program or maternity disability insurance</i></li><li>Paid time off that will cover 100% of your wages <br /> <i> Vacation days, sick days, or personal time</i></ul>",
    list_2:
      "<ul><li>Any supplemental benefits from your employer that will only cover a portion of your wages.</li><li>Any benefits from your employer and the PFML program that, together, cover your wages in full.</li><li>Any payments you receive before {{firstWaitingPeriodDayFormatted}} to {{lastWaitingPeriodDayFormatted}}.</li><li>Employer benefits you receive from any other employer than the one listed at the beginning of this application.</li></ul>",
    list_2_disclaimer: "<p><strong>Do not report:</strong></p>",
    list_2_intro_text:
      "Only report employer benefits on this page if, while you are receiving PFML benefits, you have received or will receive your wages in full from your employer or from a disability, family, or medical leave plan.",
    list_2_ptoTopOff:
      "<ul><li>Any paid time off (PTO), including vacation days, sick days, and personal time.</li><li>Any supplemental benefits from your employer that will only cover a portion of your wages.</li><li>Any payments you receive before {{firstWaitingPeriodDayFormatted}} to {{lastWaitingPeriodDayFormatted}}.</li><li>Employer benefits you receive from any other employer than the one listed at the beginning of this application.</li></ul>",
    list_2_ptoTopOffv2:
      "<ul><li>Paid time off (PTO), including vacation days, sick days, and personal time, that will only cover a portion of your wages.</li><li>Any supplemental benefits from your employer that will only cover a portion of your wages.</li><li>Any payments you receive before {{firstWaitingPeriodDayFormatted}} to {{lastWaitingPeriodDayFormatted}}.</li><li>Employer benefits you receive from any other employer than the one listed at the beginning of this application.</li></ul>",
    list_2_shortLeaveEdgeCase:
      "<ul><li>Any supplemental benefits from your employer that will only cover a portion of your wages.</li><li>Any benefits from your employer and the PFML program that, together, cover your wages in full.</li><li>Any payments you receive before the start of your leave.</li><li>Employer benefits you receive from any other employer than the one listed at the beginning of this application.</li></ul>",
    sectionLabel:
      "Reporting other disability, family, or medical leave benefits that cover your wages in full while on Paid Family and Medical Leave (PFML)",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },

  claimsEmploymentStatus: {
    alertBody:
      "If you’re <strong>unemployed, apply by calling</strong> the Department of Family and Medical Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p><p>You can apply online if you’re currently employed in Massachusetts.</p>",
    feinHowToFindSubhead:
      "How to find your Employer's Federal Identification Number",
    feinHowToFind_1:
      "You can find your employer’s EIN on a W-2 form. It will be in Box B, right above your employer’s name and address. EINs are usually 9 digits with a dash after the first 2: XX-XXXXXXX. This number does not change, so you do not need the most recent W-2. ",
    feinHowToFind_2:
      "If you can't find a W-2 or have not received one yet, ask someone in your employer's payroll, accounting, or human resources departments.",
    feinImageAlt:
      "A clear image of an example W-2 form, with the Employer Identification Number (EIN) outlined.",
    feinImageCaption: "This image is an example W-2 form.",
    feinLabel: "Enter your Employer Identification Number (EIN)",
    sectionLabel:
      "What is your Employer's Federal Identification Number? (EIN)",
    title: "$t(shared.claimsEmploymentInfoTitle)",
  },
  claimsEthnicity: {
    choiceHispanicOrLatino: "$t(shared.ethnicity.hispanicOrLatino)",
    choiceNotHispanicOrLatino: "$t(shared.ethnicity.notHispanicOrLatino)",
    choicePreferNotToAnswer: "$t(shared.ethnicity.preferNotToAnswer)",
    ethnicityIdLabel: "Ethnicity selection",
    sectionLabel: "What is your ethnicity?",
    sectionLabelHint:
      "If you prefer not to answer, it won't affect your eligibility for benefits. We ask for this to ensure our program operates equitably and fairly.",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsFamilyMemberDateOfBirth: {
    sectionLabel: "What is your family member's date of birth?",
  },
  claimsFamilyMemberName: {
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    middleNameLabel: "Middle name",
    sectionHint:
      "Fill out their name as it appears on official documents like their driver’s license or W‑2.",
    sectionLabel: "What is your family member's name?",
  },
  claimsFamilyMemberRelationship: {
    choiceLabel_child: "I am caring for my child.",
    choiceLabel_grandchild: "I am caring for my grandchild.",
    choiceLabel_grandparent: "I am caring for my grandparent.",
    choiceLabel_inlaw:
      "I am caring for a parent of my spouse or domestic partner.",
    choiceLabel_parent: "I am caring for my parent.",
    choiceLabel_sibling: "I am caring for my sibling.",
    choiceLabel_spouse: "I am caring for my spouse or domestic partner.",
    detailsBody:
      "<p>You can apply to care for someone who served in <in-loco-parentis-link>loco parentis</in-loco-parentis-link> (acted as a parent) for you. In that case, choose “I am caring for my parent.”</p><p>If you served in loco parentis for the family member you’re caring for, choose “I am caring for my child.”</p><p>If none of the options listed below describe your relationship with the person you're caring for, you are not eligible to take paid family leave. If you’d still like to submit an application or if you have questions about whether your relationship qualifies, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",
    detailsLabel:
      "What if I don’t see my relationship with the family member I’m caring for on this list?",
    sectionHint:
      "<p>Learn more about <caregiver-relationship-link>which relationships are covered</caregiver-relationship-link>.</p>",
    sectionLabel:
      "What is your relationship with the family member you are caring for?",
  },
  claimsGender: {
    choiceGenderNotListed: "$t(shared.genderGenderNotListed)",
    choiceMan: "$t(shared.genderMan)",
    choiceNonbinary: "$t(shared.genderNonbinary)",
    choicePreferNotToAnswer: "$t(shared.genderPreferNotToAnswer)",
    choiceWoman: "$t(shared.genderWoman)",
    genderIdLabel: "Gender selection",
    sectionLabel: "What is your gender identity?",
    sectionLabelHint:
      "This data helps us understand who is accessing our program to ensure it is built for everyone.",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsImport: {
    absenceIdHint:
      "Enter the application ID given to you when you applied by phone. It includes the letters NTN and ABS. You must enter all letters, numbers, and dashes (example: NTN-123456-ABS-01).",
    absenceIdLabel: "Application ID",
    leadIdentify: "We need some information to identify your application.",
    leadIntro: "$t(shared.applicationImportDescription)",
    submitButton: "Add application",
    taxIdHint:
      "Don’t have a Social Security Number? Use your Individual Taxpayer Identification Number (ITIN).",
    taxIdLabel: "Social Security Number",
    title: "Add an existing application",
    uploadingMessage: "Submitting… Do not refresh or go back",
  },
  claimsIntermittentFrequency: {
    durationBasisChoice_days: "At least one day",
    durationBasisChoice_hours: "Less than one full work day",
    durationBasisLabel: "How long will an absence typically last?",
    durationLabel_days: "How many days of work will you miss per absence?",
    durationLabel_hours: "How many hours of work will you miss per absence?",
    frequencyBasisChoice_irregular: "Irregular over the next 6 months",
    frequencyBasisChoice_months: "Once or more per month",
    frequencyBasisChoice_weeks: "Once or more per week",
    frequencyBasisLabel:
      "How often might you need to be absent from work (frequency interval)?",
    frequencyHint_care:
      "Your answers must match the intermittent leave section in the $t(shared.certificationFormCare).",
    frequencyHint_medical:
      "Your answers must match the intermittent leave section in the $t(shared.certificationFormMedical).",
    frequencyHint_pregnancy:
      "Your answers must match the intermittent leave section in the $t(shared.certificationFormMedical).",
    frequencyLabel_irregular:
      "Estimate how many absences over the next 6 months.",
    frequencyLabel_months: "Estimate how many absences per month.",
    frequencyLabel_weeks: "Estimate how many absences per week.",
    needDocumentAlert_care: "$t(shared.leavePeriodCaringAlert)",
    needDocumentAlert_medical: "$t(shared.leavePeriodMedicalAlert)",
    needDocumentAlert_pregnancy: "$t(shared.leavePeriodMedicalAlert)",
    sectionLabel:
      "Tell us the estimated frequency and duration of your intermittent$t(chars.nbsp)leave.",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsLanguage: {
    choiceChineseSimplified: "$t(shared.language.ChineseSimplified)",
    choiceEnglish: "$t(shared.language.English)",
    choiceFrench: "$t(shared.language.French)",
    choiceHaitianCreole: "$t(shared.language.HaitianCreole)",
    choiceLanguageNotListed: "Language not listed above",
    choiceLanguageNotListedHint:
      "Your communications will be in English. However, you can get translation help by calling the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Interpretation services for up to 240+ languages are available.",
    choicePortuguese: "$t(shared.language.Portuguese)",
    choiceSpanish: "$t(shared.language.Spanish)",
    choiceVietnamese: "$t(shared.language.Vietnamese)",
    sectionLabel:
      "In what language would you like to receive future written communications?",
    sectionLabelHint:
      "You will receive emails, mail, and notices related to your application in the language you choose.",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsLeavePeriodContinuous: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    datesLead_bonding:
      "If you have already taken family leave to bond with a child since their date of birth, or the date of their adoption or foster care placement, tell us the first day you missed work, and the last day you expect to be on leave.<br /><br />You must complete your leave before the child’s first birthday, or the one-year anniversary of their adoption or foster care placement.",
    datesLead_care:
      "Your answer must match the continuous leave period start and end dates in the $t(shared.certificationFormCare).",
    datesLead_medical:
      "$t(shared.leavePeriodContinuousDatesLeadMedicalOrPregnancy)",
    datesLead_pregnancy:
      "$t(shared.leavePeriodContinuousDatesLeadMedicalOrPregnancy)",
    datesSectionLabel:
      "Enter the start and end dates for your continuous leave.",
    endDateLabel: "Last day of leave",
    hasLeaveHint_care: "$t(shared.leavePeriodHasLeaveHintCare)",
    hasLeaveHint_medical:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveHint_pregnancy:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveLabel:
      "Do you need to take off work completely for a period of time (continuous leave)?",
    needDocumentAlert_care: "$t(shared.leavePeriodCaringAlert)",
    needDocumentAlert_medical: "$t(shared.leavePeriodMedicalAlert)",
    needDocumentAlert_pregnancy: "$t(shared.leavePeriodMedicalAlert)",
    startDateLabel: "First day of leave",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsLeavePeriodIntermittent: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    datesLead_bonding:
      "If you have already taken family leave to bond with a child since their date of birth, or the date of their adoption or foster care placement, tell us the first day you missed work, and the last day you expect to be on leave.<br /><br />You must complete your leave before the child’s first birthday, or the one-year anniversary of their adoption or foster care placement.",
    datesLead_care:
      "Your answer must match the intermittent leave start and end dates in the $t(shared.certificationFormCare).",
    datesLead_medical:
      "$t(shared.leavePeriodIntermittentDatesLeadMedicalOrPregnancy)",
    datesLead_pregnancy:
      "$t(shared.leavePeriodIntermittentDatesLeadMedicalOrPregnancy)",
    datesSectionLabel:
      "Enter the start and end dates for your intermittent leave.",
    endDateLabel: "Last day of leave",
    endDateLabel_medical: "Last day of leave",
    hasLeaveHint_bonding:
      "For example, you need to take time off for: <ul><li>Court dates for your foster child</li><li>Social worker visits</li><li>Gaps in your childcare</li></ul>",
    hasLeaveHint_care: " $t(shared.leavePeriodHasLeaveHintCare)",
    hasLeaveHint_medical:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveHint_pregnancy:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveLabel:
      "Do you need to take off work in uneven blocks of time (<scheduling-leave-guide-link>intermittent leave</scheduling-leave-guide-link>)?",
    hybridLeaveWarning:
      "You have to create a separate application for intermittent leave.",
    needDocumentAlert_care: "$t(shared.leavePeriodCaringAlert)",
    needDocumentAlert_medical: "$t(shared.leavePeriodMedicalAlert)",
    needDocumentAlert_pregnancy: "$t(shared.leavePeriodMedicalAlert)",
    startDateLabel: "First day of leave",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsLeavePeriodReducedSchedule: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    datesLead_bonding:
      "If you have already taken family leave to bond with a child since their date of birth, or the date of their adoption or foster care placement, tell us the first day you missed work, and the last day you expect to be on leave.<br /><br />You must complete your leave before the child’s first birthday, or the one-year anniversary of their adoption or foster care placement.",
    datesLead_care:
      "Your answer must match the reduced leave start and end dates in the $t(shared.certificationFormCare).",
    datesLead_medical:
      "$t(shared.leavePeriodReducedDatesLeadMedicalOrPregnancy)",
    datesLead_pregnancy:
      "$t(shared.leavePeriodReducedDatesLeadMedicalOrPregnancy)",
    datesSectionLabel:
      "Enter the start and end dates for your reduced leave schedule.",
    endDateLabel: "Last day of leave",
    endDateLabel_medical: "Last day of leave",
    hasLeaveHint_care: "$t(shared.leavePeriodHasLeaveHintCare)",
    hasLeaveHint_medical:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveHint_pregnancy:
      "$t(shared.leavePeriodHasLeaveHintMedicalOrPregnancy)",
    hasLeaveLabel:
      "Do you need to work fewer hours than usual for a period of time (<scheduling-leave-guide-link>reduced leave schedule</scheduling-leave-guide-link>)?",
    needDocumentAlert_care: "$t(shared.leavePeriodCaringAlert)",
    needDocumentAlert_medical: "$t(shared.leavePeriodMedicalAlert)",
    needDocumentAlert_pregnancy: "$t(shared.leavePeriodMedicalAlert)",
    startDateLabel: "First day of leave",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsLeaveReason: {
    alertBody:
      "<p>To apply for the following paid benefits:</p><ul><li>Paid family leave to care for a service member with a serious health condition related to military service</li><li>Paid family leave to manage a <leave-types-link>qualifying family need (or exigency)</leave-types-link> related to a family member’s deployment</li></ul><p>Call the Department of Family and Medical Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",
    bondingLeaveLabel:
      "I need family leave to bond with my child after birth, adoption, or foster placement.",
    bondingTypeAdoptionLabel: "Adoption",
    bondingTypeDetailsSummary:
      "If you are unable to work due to pregnancy or you are recovering from birth, submit your application for medical leave due to pregnancy <strong>before</strong> applying for family bonding leave to avoid unnecessary delays to your approval. You can add a family bonding to your application for medical leave due to complication from pregnancy or recovery from birth.",
    bondingTypeFamilyLeaveDetailsLabel:
      "Taking family leave to bond with a child",
    bondingTypeFamilyLeaveDetailsSummary:
      "<p>You must complete your leave before the child’s first birthday, or the one-year anniversary of their adoption or foster care placement.</p> <p>You can apply for a total of 12 weeks of family leave within a benefit year. If your medical leave is due to recovery from giving birth or your inability to work during pregnancy, you can transition directly into family leave to bond with a child after the child’s birth. Or you can wait to take family leave to bond with your child some other time within the next 12 months. Taking family leave immediately after you take medical leave will waive your unpaid waiting week and could permit you to take over 12 weeks of bonding leave.</p>",
    bondingTypeFosterLabel: "Foster placement",
    bondingTypeLabel:
      "Will you take leave for a birth, an adoption, or a foster placement?",
    bondingTypeMultipleBirthsDetailsLabel:
      "What if I’ve had multiple births or placements within one year?",
    bondingTypeMultipleBirthsDetailsSummary:
      "Leave is determined based on your benefit year (a period of 52 weeks starting on the Sunday prior to your first day of paid leave through any leave program), not based on the number of children. You have 1 year from the date of the birth/placement of your child to take leave to bond with a child. <multiple-births-link>Learn more about taking leave for multiple childbirths or placements</multiple-births-link>.",
    bondingTypeNewbornLabel: "Birth",
    caringLeaveLabel: "I need family leave to care for my family member.",
    leaveReasonChangedAlertBody:
      "Earlier you reported taking previous leave for the same reason you are applying now, but you are about to change that reason.<br/><br/>Save and continue to change your leave reason, then review the previous leave you reported to make sure the reason you took that leave is still accurate.",
    leaveReasonChangedAlertTitle: "Review your previous leave",
    medicalLeaveDueToIllnessInjuryLabel:
      "I need medical leave due to my illness or injury.",
    pregnancyLabel:
      "I need medical leave to recover from giving birth <strong>or</strong> due to my inability to work during pregnancy.",
    pregnancyLeaveHint:
      "You can apply for either leave type prior to giving birth. If your application is approved, you will be eligible to add a family leave request to bond with your newborn child.",
    sectionLabel: "Why do you need to take leave?",
    sectionLearnMoreDetail:
      "<b>Medical leave due to my inability to work during pregnancy</b><br/><br/>If your health care provider certifies that your pregnancy prevents you from doing your job, you may be able to take medical leave prior to giving birth to your child. Talk to your health care provider about pregnancy-related illnesses and injuries that could prevent you from working.<br/><br/><b>Medical leave to recover from birth</b><br/><br/>You can take this leave to physically recover from your labor and delivery. A typical leave duration for recovery is 6 to 8 weeks. <br/><br/><i>The maximum leave you can take for medical leave is 20 weeks per benefit year.</i><br/><br/>",
    sectionLearnMoreLabel:
      "Learn about the different leave opportunities available for birthing parents.",
    title: "$t(shared.claimsLeaveDetailsTitle)",
  },
  claimsLeaveSpansBenefitYears: {
    continueLabel: "I understand",
    loadingLabel: "Redirecting to applications index",
    title: "$t(shared.claimsLeaveSpansBenefitYears.title)",
  },
  claimsModifyBenefitYearInfoReview: {
    benefitAmountInfoLink:
      "DFML will calculate your weekly benefit amount for each benefit year separately. You may receive different payment amounts for each benefit year. Learn more about <benefit-year-info-link>how PFML weekly benefit amounts are calculated.</benefit-year-info-link>",
    button: "I understand",
    dfmlWillReview:
      "DFML will only review additional leave dates within your current benefit year.",
    heading: "The leave dates you’re requesting cross into a new benefit year.",
    title: "$t(shared.claimsModifyTitle)",
    waitingPeriodLink:
      "You'll have another unpaid 7-day waiting period at the start of your new benefit year. Learn more about the <benefit-year-guide-link>7-day waiting period.</benefit-year-guide-link>",
    youCanContinueToSubmit:
      "You can continue to submit your request for additional leave.",
    yourCurrentBenefitYear:
      "Your existing <benefit-year-guide-link>benefit year</benefit-year-guide-link> is {{startDate}} to {{endDate}}.",
    yourNewBenefitYear:
      "Your new benefit year is {{startDate}} to {{endDate}}.",
    yourRequestWithTwoBenefitYearsMeans:
      "You will have a request for additional leave with two benefit years. This means that:",
  },
  claimsModifyCancel: {
    cancelBody:
      "Once your application has been canceled, you cannot make any changes to your application.",
    cancelButton: "Yes, cancel my leave",
    overpaymentsWarning:
      "If you have received payments for the leave dates you are cancelling, you will need to return the money to the Department.",
    sectionLabel: "Are you sure you want to cancel your entire PFML leave?",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyChecklist: {
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    title: "Welcome back to your in-progress change request",
  },
  claimsModifyDeleteChangeRequest: {
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    changeRequestTypeLabel: "Change request type",
    deleteButton: "Yes, delete my request",
    heading: "Are you sure you want to delete your in progress change request?",
    inProgressChangeRequestType: "Not selected",
    inProgressChangeRequestType_cancellation: "Change leave end date",
    inProgressChangeRequestType_extension: "Change leave end date",
    inProgressChangeRequestType_medicalToBonding:
      "Add family leave to bond with a child",
    inProgressChangeRequestType_modification: "Change leave end date",
    inProgressChangeRequestType_withdrawal: "Cancel entire leave",
    leaveDatesReviewLabel: "Current leave dates",
    leaveDatesReviewValue: "{{startDate}} to {{endDate}}",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyIndex: {
    backButtonLabel: "$t(shared.backToYourApplicationDetailsLink)",
    button: "Start your change request",
    extensionDocuments:
      "To extend your leave, you will need to upload a completed <health-form-link>certification form</health-form-link> which covers the new leave dates you are requesting. You may also use a note from a medical provider if it meets the following requirements:<ul><li>The health condition is referenced in the letter and is related to the condition from the original leave.</li><li>The additional leave dates are specified.</li><li>The letter is on official letterhead or in a Medical Record format that lists the practice name.</li><li>The DFML is able to verify the provider.</li></ul>",
    extensionDocumentsLabel: "Document requirements for leave extensions",
    extensionRequirementsBody:
      "<p>You can request an extension of your leave online up to 30 calendar days after your approved leave ends. You may also need to upload updated documentation that covers your new leave dates.</p>",
    extensionRequirementsHeading: "Requirements for extension requests",
    heading: "Request a change to your application",
    instructions:
      "<p>You can exit your change request and return to where you left off at a later time. Your original, previously submitted application won't be changed until you submit your change and the DFML reviews your request.</p>",
    instructionsHeading:
      "Your change request activity will be saved automatically.",
    notifyEmployer:
      "Make sure you've notified your employer of any changes to your leave.",
    onlineBody:
      "<p>You can make the following changes online:</p><ul><li>Extend your leave</li><li>End your leave early</li></ul>",
    onlineHeading: "Make your change online",
    phoneBody:
      "<p>Call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumber)</contact-center-phone-link> if you need to:</p><ul><li>Change your leave start date</li><li>Cancel your entire approved leave</li><li>Change your work schedule</li><li>Change your leave schedule (such as from continuous to reduced leave)</li><li>Report additional other leaves or benefits you will be receiving during your leave</li><li>Take more time to get a certification form or note from your healthcare provider</li></ul>",
    phoneHeading: "Make your change by phone",
    sectionHeading: "Only some changes can be made online",
    title: "Change your application",
  },
  claimsModifyMedToBondingDateOfBirth: {
    sectionHint:
      "If your child has not been born yet, enter the expected due date.",
    sectionTitle: "When is your child's birth date?",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyMedToBondingExtensions: {
    body: "<p>Your child’s date of birth is more than 2 weeks after your medical leave ends on {{endDate}}.  You need to update your medical leave dates to reflect the leave you are requesting first in order to add family leave to bond with your child and avoid another 7 day unpaid waiting period.</p><p>If your medical leave starts on a different day, you will need to call the Contact Center to change the start date of your medical leave.</p><p>Once DFML reviews the adjusted leave dates and your change request is processed, you can come back to add your family leave to bond with your child.</p>",
    buttonLabel: "Update my leave dates",
    learnMoreLink:
      "<p><transition-to-bonding-link>Learn more about how you can arrange your leave as a new parent.</transition-to-bonding-link></p>",
    sectionTitle: "You may need to update your medical leave dates",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyMedToBondingIndex: {
    backButtonLabel: "$t(shared.backToYourApplicationDetailsLink)",
    body: "<p>Taking this leave immediately after your medical leave for pregnancy or recovery from childbirth ensures there is no disruption in your benefit payments.</p><p>Parents are eligible for up to 12 weeks of family leave to bond with their child within 12 months after birth. <about-bonding-leave-link>Learn more about eligibility requirements for family leave to bond with a child.</about-bonding-leave-link></p><p>You need to notify your employer 30 days in advance of your family leave to bond with a child if possible.</p>",
    continueButton: "Continue",
    heading: "Add family leave to bond with a child to your medical leave",
    proofOfBirth:
      "<p><strong>Acceptable proof of birth</strong><br/>Submit one of these four documents.</p><ul><li>A copy of the child’s Birth Certificate</li><li>A statement from the child’s health care provider stating the child’s date of birth</li><li>A statement from the birth parent’s health care provider stating the child’s date of birth</li><li>A hospital statement of birth records</li></ul>",
    uploadDocument:
      "<p>If you do not have acceptable proof of birth, you can still submit your request for family leave to bond with your child. Your leave request won’t be approved until your proof of birth is uploaded.</p>",
  },
  claimsModifyMedToBondingLeaveDates: {
    bondingLeaveLabel: "Leave to bond with a child",
    bondingLeaveValue:
      "<p><strong>First day</strong></p>{{startDate}}<p><strong>Enter your requested last day of leave</strong></p>",
    delayBondingLeaveDescription:
      "If you do not plan to start your leave to bond with your child on the calendar day immediately following the last day of your leave for pregnancy or recovery from childbirth, you will need to <submit-app-link>submit a new application</submit-app-link> for leave to bond with your child. You will have a second unpaid waiting period for this application.",
    delayBondingLeaveLabel:
      "What if you don’t want to start leave to bond with your child right after leave for pregnancy or recovery from childbirth?",
    delayedBirthDescription:
      "<p><strong>If so, first you will need to change the end date of your medical leave</strong></p><ul><li>First submit a request to update the end date of your medical leave for pregnancy or recovery from childbirth.</li><li>Once DFML reviews the adjusted leave dates and your request is processed, then you can add your leave to bond with your child.</li><li>If you request bonding leave before updating your medical leave, you will have a second unpaid waiting period.</li></ul>",
    delayedBirthLabel:
      "Was your child’s birth date more than 2 weeks after your due date?",
    medToBondingModLegend:
      "Enter the last day of your leave to bond with your child",
    medToBondingModLegendLegendHint:
      "<ul><li>Taking your leave to bond with your child immediately after your leave for pregnancy or recovery from childbirth allows you to avoid a second unpaid waiting period.</li><li>You must complete your leave to bond with your child before the child’s first birthday.</li><li>PFML provides up to 12 weeks of family leave in a benefit year.<learn-about-bonding-leave-link>Read more about family leave eligibility.</learn-about-bonding-leave-link></li></ul>",
    medicalLeaveLabel: "Leave for pregnancy or recovery from childbirth",
    medicalLeaveValue: "{{startDate}} to {{endDate}}",
    title: "$t(shared.claimsModifyTitle)",
    yourChangeRequestTag: "$t(shared.yourChangeRequestTag)",
  },
  claimsModifyMedToBondingProofOfBirth: {
    choiceHintBondingLeaveDatesLabel: "Leave to bond with a child dates",
    choiceHintBondingLeaveDatesValue: "{{startDate}} to {{endDate}}",
    choiceHintDetailsLabel:
      "What type of document can I use as proof of birth?",
    choiceHintDetailsText:
      "<p>For a child’s birth, you will need to submit one of these documents:</p><ul><li>A copy of the child's birth certificate</li><li>A statement from the child's health care provider with the child’s date of birth</li><li>A statement from the birth parent’s health care provider with the child’s date of birth</li><li>A hospital statement of birth records</li></ul>",
    choiceLabel: "Do you have proof of birth?",
    choiceNo: "No",
    choiceNoHint:
      "You can upload proof of birth within 90 days of submitting this request.",
    choiceYes: "Yes",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyMedToBondingSuccess: {
    backButtonLabel: "Back to your application details",
    button: "View application status",
    medToBondingSuccessHeading:
      "You've submitted your request for leave to bond with a child",
    uploadProofOfBirthBody:
      "<ul><li>After you give birth, you will need to upload at least one <proof-of-birth-document-link>proof of birth document</proof-of-birth-document-link>.</li><li>You have up to 90 days after submitting this request to provide your proof of birth. Your leave to bond with a child will not be approved until after you upload proof of birth.</li></ul>",
    uploadProofOfBirthHeading: "You will need to upload proof of birth",
    whatHappensNextBody_noProofOfBirth:
      "<ul><li>You’ll continue to receive payments for your approved leave for pregnancy or recovery from childbirth.</li><li>DFML will add your pending request for leave to bond with a child to your application within 3 business days.</li></ul>",
    whatHappensNextBody_proofOfBirth:
      "<ul><li>You’ll continue to receive payments for your approved leave for pregnancy or recovery from childbirth.</li><li>DFML will add your pending request for leave to bond with a child to your application within 3 business days.</li><li>Once we add your request, it will be reviewed by DFML and your employer will be asked to respond to your application. <approval-process-link>Read more about the application review process</approval-process-link>.</li></ul>",
    whatHappensNextHeading: "What happens next",
  },
  claimsModifyReview: {
    bondingReviewRowLabel: "Leave to bond with a child",
    bondingReviewRowValue:
      "<strong>First day</strong><br/>{{startDate}}<p><strong>Requested last day of leave</strong></p>{{endDate}}",
    buttonText_cancellation: "$t(shared.modificationsSubmitButton)",
    buttonText_extension: "$t(shared.modificationsSubmitButton)",
    buttonText_medicalToBonding: "Submit your request",
    buttonText_withdrawal: "Yes, cancel my leave",
    cancellationBody:
      "Once your application has been canceled, you cannot make any changes.",
    cancellationHeading:
      "Are you sure you want to cancel your entire PFML leave?",
    disabledAlertBody_alreadyHasBonding:
      "You already have a family leave to bond with your child tied to this application. You can make changes to your leave online once/if it has been approved. If you need to make a change before then you can call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    disabledAlertBody_notApproved:
      "You can't continue this change request because you have a request for leave that is not currently approved. To change your leave dates, you can call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    disabledAlertBody_pastLeaveEnd:
      "You can't continue this change request because your leave ended more than 30 days ago. If you still need to change your leave dates, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    disabledAlertBody_pending:
      "You can't continue this change request because you have a pending request for leave. To change your leave dates, you can wait until your pending leave is approved or call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    disabledAlertHeading:
      "Your in-progress change request can no longer be submitted",
    medicalReviewRowLabel: "Leave for pregnancy or recovery from childbirth",
    medicalReviewRowValue: "{{startDate}} to {{endDate}}",
    medicalToBondingBody:
      "Once you review and submit your leave changes, we will send a copy of your change request to your employer.",
    medicalToBondingHeading: "Review and submit your change",
    modificationBody_cancellation:
      "Once you review and submit your leave changes, we will send a copy of your change request to your employer. ",
    modificationBody_extension:
      "Once you review and submit your leave changes, we will send a copy of your change request to your employer. They will also get a copy of the certification documents.",
    modificationHeading: "Review and submit your requested change",
    modificationReviewRowLabel: "Updated leave dates",
    modificationReviewRowValue: "{{startDate}} to {{endDate}}",
    pregnancyReviewRowLabel: "Leave for pregnancy or recovery from childbirth",
    pregnancyReviewRowValue:
      "<strong>Start</strong><br/>{{startDate}}<br/><strong>End</strong><br/>{{endDate}}",
    title: "$t(shared.claimsModifyTitle)",
    yourChangeRequestTag: "$t(shared.yourChangeRequestTag)",
  },
  claimsModifySuccess: {
    adjudicationProcess:
      "<ul><li>We’ll add your pending request to your application within 3 business days and your employer will be notified.</li><li>Your employer has 10 business days to provide feedback on your application.</li> <li>We’ll confirm your eligibility and make sure that your documents are valid.</li> <li>We will notify you once we’ve made a decision. You’ll be able to read the details of the decision on this website. Your employer will also get a copy of the decision.</li><li>You’ll continue to receive payments for time that is already approved. You won’t receive payments for your additional time until after the time is approved.</li><li>You will not have a second waiting period because your new leave dates immediately follow your existing leave.</li><li>If your additional leave starts in the future, you can expect your first payment 2-4 weeks after your new leave starts. After that, you will receive your payments every week.</li></ul>",
    adjudicationProcessHeading: "What happens next",
    backButtonLabel: "Back to your application details",
    button: "View application status",
    claimantApplicationId:
      "Your application ID is <strong>{{absence_id}}</strong>",
    title: "You've submitted your change request",
    viewStatus: "View status and details for your application",
  },
  claimsModifyType: {
    cancelChoiceHint: "I need to return to work earlier than expected",
    cancelChoiceText: "End my leave early",
    cancellationHint:
      "<p><strong>If you are ending your leave early and have received payments for the leave dates you are canceling</strong></p><ul><li>You will need to return the money to DFML. <overpayments-link>Read more about overpayments</overpayments-link>.</li></ul>",
    choiceHint:
      "We will ask some questions about your desired change. Your employer will be notified of any changes you make.",
    choiceLabel: "How do you want to change your application?",
    currentLeaveDatesLabel: "Current leave dates",
    currentLeaveDatesValue: "{{startDate}} to {{endDate}}",
    endDateLabel: "Requested last day of leave",
    modificationChoiceHint: "I need more time away from work",
    modificationChoiceLabel: "Extend my leave",
    modificationHint:
      "<strong>To extend your leave</strong><ul><li>You will need to upload an updated PFML Certification Form or note from your health care provider which covers your new leave dates. You can upload your original PFML Certification Form if the new leave dates you are requesting are covered.</li><li>Documents must be uploaded to submit your extension request online.</li><li>If you are unable to gather documents before the 30 calendar day deadline, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. The Contact Center can start an extension request for you while you gather your documents. </li></ul>",
    modificationReviewDates: "Enter your new leave end date",
    title: "$t(shared.claimsModifyTitle)",
  },
  claimsModifyWithdrawLeave: {
    backButtonAppDetailsLabel: "$t(shared.backToYourApplicationDetailsLink)",
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    buttonText: "Yes, withdraw my application",
    title: "$t(shared.claimsModifyTitle)",
    withdrawalBody:
      "Once your application has been withdrawn, you cannot make any changes.<br/>You will need to create a new application if you choose take leave.",
    withdrawalHeading:
      "Are you sure you want to withdraw your application for leave?",
  },
  claimsName: {
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    lead: "Fill out your name as it appears on official documents like your driver’s license or W$t(chars.nbhyphen)2.",
    middleNameLabel: "Middle name",
    sectionLabel: "What’s your name?",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsNotifiedEmployer: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    employerNotificationDateHint: "This can be an approximate date.",
    employerNotificationLabel: "When did you tell them?",
    employerNotificationWarning:
      "<p>Your leave request may be delayed or denied if you do not notify your employer of your leave at least 30 days before the start date. If you are unable to notify your employer of your leave at least 30 days before the leave starts due to reasons beyond your control, notify your employer as soon as it is practical to avoid potential delays or denials.</p> <p>Once you submit Part 1 of this application, your employer will be informed that you have started an application for PFML. DFML’s communication with your employer is not a substitute for you notifying your employer about the leave.</p>",
    employerNotificationWarningHeader: "You can continue your application",
    mustNotifyEmployerWarning:
      "You can continue to enter information about your leave. Before you can submit your application, you must tell your employer that you’re taking$t(chars.nbsp)leave. Notify your employer at least 30 days before the start of your leave if possible.",
    sectionLabel:
      "Have you told your employer that you are taking$t(chars.nbsp)Massachusetts Paid Family and Medical Leave?",
    title: "$t(shared.claimsEmploymentInfoTitle)",
  },
  claimsOrganizationUnit: {
    choiceNo: "$t(shared.choiceNo)",
    choiceNotSure: "I'm not sure",
    choiceYes: "$t(shared.choiceYes)",
    comboBoxLabel: "Select your department",
    confirmHint:
      "Based on what you’ve entered, our records show you work at <strong>{{organization_unit}}</strong>. Is this the department where you are applying to take leave?",
    confirmSectionLabel: "Confirm your department",
    failureWarning:
      "Failure to select the right department will delay your application.",
    followupInfo:
      "Our team will check their records to find your department. If there is no match, someone from the Contact Center will follow up with you after you apply to make sure the right department reviews your application.",
    sectionLabel: "Select the department where you are applying to take leave",
  },
  claimsOtherIncomes: {
    choiceNo: "$t(shared.choiceNo)",
    choiceNoAlert:
      "If you receive any other income after you apply, you’ll need to call the Contact Center to let us know.",
    choiceNoHint:
      "I won't receive other income from the above sources during my paid leave,  I've applied but it hasn't been approved, or I don’t know the income amount yet",
    choiceYes: "$t(shared.choiceYes)",
    choiceYesHint:
      "I will receive other income from other sources during my paid leave",
    doNotReportHintHeading: "You don't need to report:",
    doNotReportHintList:
      "<ul><li>Other income that you've applied for but are not yet receiving</li><li>Income from past PFML benefits</li><li>Income received through a bonus, like a holiday bonus or an annual sum paid out at the end of the year</li></ul>",
    doReportHintHeading: "You must report these sources of income:",
    doReportHintList:
      "<ul><li>Workers Compensation</li><li>Unemployment Insurance</li><li>Social Security Disability Insurance</li><li>Disability benefits under a governmental retirement plan such as STRS or PERS</li><li>Jones Act benefits</li><li>Railroad Retirement benefits</li><li>Earnings or benefits from another employer, or through self-employment<ul>",
    sectionLabel:
      "Will you receive income from any other sources during your leave dates for paid$t(chars.nbsp)leave?",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },
  claimsOtherIncomesDetails: {
    addButton: "Add another income",
    amountFrequencyLabel: "$t(shared.amountFrequencyLabel)",
    amountFrequency_daily: "$t(shared.amountFrequency_daily)",
    amountFrequency_inTotal: "$t(shared.amountFrequency_inTotal)",
    amountFrequency_monthly: "$t(shared.amountFrequency_monthly)",
    amountFrequency_weekly: "$t(shared.amountFrequency_weekly)",
    amountLabel: "Amount",
    amountLegend: "How much will you receive?",
    cardHeadingPrefix: "$t(shared.otherIncomeEntryPrefix)",
    endDateLabel:
      "What is the last day of your leave that this income will pay you for?",
    limitMessage: "You can only add up to {{limit}} incomes",
    removeButton: "Remove income",
    sectionLabel:
      "Tell us about your other sources of income during your leave dates for paid leave from PFML.",
    startDateLabel:
      "What is the first day of your leave that this income will pay you for?",
    title: "$t(shared.claimsOtherLeaveTitle)",
    typeChoiceLabel_jonesAct: "$t(shared.otherIncomeType_jonesAct)",
    typeChoiceLabel_otherEmployer: "$t(shared.otherIncomeType_otherEmployer)",
    typeChoiceLabel_railroadRetirement:
      "$t(shared.otherIncomeType_railroadRetirement)",
    typeChoiceLabel_retirementDisability:
      "$t(shared.otherIncomeType_retirementDisability)",
    typeChoiceLabel_ssdi: "$t(shared.otherIncomeType_ssdi)",
    typeChoiceLabel_unemployment: "$t(shared.otherIncomeType_unemployment)",
    typeChoiceLabel_workersCompensation:
      "$t(shared.otherIncomeType_workersCompensation)",
    typeLabel: "What kind of income is it?",
  },
  claimsPaidTimeOffExplainer: {
    list_1:
      "<ul><li>Most employers offer paid time off (PTO). This includes vacation days, sick days, and personal time.</li><li>You can choose to use PTO during PFML, as long as the combined payment amount doesn’t exceed your Individual Average Weekly Wage.</li><li>If your application is approved, your PFML benefit amount and Individual Average Weekly Wage will be in the approval notice you receive.</li><li>Your employer is responsible for providing any PTO payments, not the Department of Family and Medical Leave.</li><li>Your employer cannot require you to use PTO during your PFML leave.</li></ul>",
    list_2:
      "<ul><li>Mary has an Individual Average Weekly Wage of $1,300</li><li>Mary takes PFML leave, and is approved for $1,100 per week</li><li>Mary can get $200 in PTO from her employer</li></ul>",
    list_2_label: "<p><strong>For example</strong></p>",
    list_3:
      "<ul><li>Talk to your employer if you want to use paid time off during your leave to supplement your PFML benefit payments.</li><li>Learn more about <explainer-pto-link>using paid time off during PFML leave.</explainer-pto-link></li></ul>",
    list_3_label: "<p><strong>What you can do</strong></p>",
    sectionLabel:
      "Using paid time off while on Paid Family and Medical Leave (PFML)",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },
  claimsPaymentMethod: {
    accountNumberHint:
      "Before submitting, verify your checking account or savings account number, which you can find on bank statements, online banking accounts, or at the bottom of a check.",
    accountNumberLabel: "Account number",
    accountNumberWarning:
      "Your account number looks similar to a routing number. Check that you entered your account number correctly.",
    achLead:
      "We need this information so you can receive your weekly benefit through Direct Deposit. Contact your bank if you are having trouble finding this information. Entering incorrect information can delay payments.",
    achSectionLabel: "Enter your bank account information",
    achTypeChecking: "$t(shared.achTypeChecking)",
    achTypeLabel: "Account type",
    achTypeSavings: "$t(shared.achTypeSavings)",
    choiceAch: "$t(shared.paymentMethodAch)",
    choiceCheck: "$t(shared.paymentMethodCheck)",
    choiceHintAch:
      "It can take us additional time to set up direct deposit with your bank. Your first payment might be a check sent to the address you listed as your mailing address.",
    choiceHintCheck:
      "You will receive your checks at the address you listed as your mailing address.",
    choiceHintPrepaidDebitCard:
      "You will receive a prepaid debit card in the mail. You can use this card to access your benefits.",
    choicePrepaidDebitCard: "$t(shared.paymentMethodPrepaidDebitCard)",
    personalCheckAlt:
      "An example of a personal check, showing the routing number in the bottom left-hand corner, and the account number to its right.",
    retypeAccountNumberHint:
      "Make sure your account numbers match exactly. You can't copy and paste into this field.",
    retypeAccountNumberLabel: "Re-type account number",
    retypeAccountNumberRequired: "Retype your account number",
    routingNumberHint:
      "Banks can have multiple routing numbers. Make sure the number that you provide is your bank’s direct deposit routing number.",
    routingNumberLabel: "Routing number",
    sectionLabel: "How do you want to get your weekly benefit?",
    sectionLabelHint:
      "Your choice will be applied to any previous applications you have submitted.",
    submitPayment: "Submit payment method",
    title: "Payment method",
    warning:
      "After you submit your payment method, it can’t be edited on this website. To change it, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    whenWillIGetPaidDetails:
      "<p>Once your application is approved, you can expect your first payment to arrive at the beginning of your fourth week of leave, if your leave has already started.</p><p>If your leave starts in the future, you can expect your first payment 2-4 weeks after your leave starts.</p><p>After that, you will receive your payments every week.</p>",
    whenWillIGetPaidLabel: "When will I get paid?",
  },
  claimsPhoneNumber: {
    choiceCell: "Mobile",
    choicePhone: "Landline",
    lead: "If we need to contact you, we will reach out by phone or mail. We will also use this number for any previous applications you have submitted.",
    phoneNumberHint:
      "Don’t have a personal phone number? Enter a number where we might be able to reach you (for example, a work phone number, or a friend’s phone number).",
    phoneNumberLabel: "Phone number",
    phoneTypeLabel: "Number type",
    sectionLabel: "What’s your phone number?",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsPreviousLeaves: {
    alertPreviousLeaveDates:
      "<div>Your previous PFML dates:</div><div><previous-pfml-dates /></div><div><strong>Do not report this leave.</strong></div>",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hoursLabel: "$t(shared.hoursLabel)",
    minutesLabel: "$t(shared.minutesLabel)",
    previousLeavesAddButton: "Add another leave",
    previousLeavesEntryPrefix: "Leave",
    previousLeavesLimitMessage: "You can only add up to {{limit}} leaves",
    previousLeavesRemoveButton: "Remove this leave",
    previousPfmlPeriodListItem: "{{start_date}} to {{end_date}}",
    questionCurrentEmployerHint:
      "This is the same employer identification number (EIN) you entered at the beginning of this application. If you answer yes, your employer will be able to review this leave after you submit your application. Otherwise, we will not inform this employer of any previous leave you took from a different employer.",
    questionCurrentEmployerLabel:
      "Did you take leave from this employer? EIN:$t(chars.nbsp){{employerFein, ein}}",
    questionHasPreviousLeavesChoiceNoHint:
      "I did not take any other leaves after {{previousLeaveStartDate}}.",
    questionHasPreviousLeavesChoiceYesHint:
      "I took another leave after {{previousLeaveStartDate}} that I need to report.",
    questionHasPreviousLeavesLabel:
      "Did you take any other previous leave (paid or unpaid) after {{previousLeaveStartDate}}?",
    questionIsContinuousLabel:
      "Were you completely off work from the start to the end of this leave?",
    questionLeaveEndDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveEndDateLabel)",
    questionLeaveMinutesHint:
      "$t(shared.claimsPreviousLeaveDetails.leaveMinutesHint)",
    questionLeaveMinutesLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveMinutesLabel)",
    questionLeaveReasonChoice_activeDutyFamily:
      "Managing family affairs while a family member was on active duty in the armed forces.",
    questionLeaveReasonChoice_bonding:
      "Bonding with my child after birth, adoption, or foster care placement as is certified by my proof of birth or placement documentation.",
    questionLeaveReasonChoice_care:
      "Caring for a family member with a serious health condition that was certified in writing by a health care provider.",
    questionLeaveReasonChoice_medical:
      "A health condition kept me out of work for at least 3 days for which I received written certification from a health care provider.",
    questionLeaveReasonChoice_medicalHospital:
      "I received care in a hospital due to an illness or injury.",
    questionLeaveReasonChoice_pregnancy:
      "I was recovering from giving birth as certified by my health care provider.",
    questionLeaveReasonChoice_pregnancyHealthCondition:
      "A health condition during pregnancy for which I received written certification from a health care provider.",
    questionLeaveReasonChoice_serviceMemberFamily:
      "Caring for a family member who was serving in the armed forces.",
    questionLeaveReasonLabel: "Why did you need to take this leave?",
    questionLeaveStartDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveStartDateLabel)",
    questionWorkedPerWeekMinutesHintDetails:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetails)",
    questionWorkedPerWeekMinutesHintDetailsLabel:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetailsLabel)",
    questionWorkedPerWeekMinutesLabel:
      "How many hours per week did you normally work prior to taking this leave?",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },
  claimsPreviousLeavesIntro: {
    detailsBenefitYear:
      "<p>The benefit year is a period of 52 weeks that starts on the Sunday before when you first miss work for a qualifying reason. You are entitled to up to 20 weeks of medical leave, 12 weeks of family leave, or 26 weeks for a combination of both during a single benefit year.</p><p>You can submit an application for leave that crosses into a new benefit year. DFML will review each benefit year leave request. The decision may be different for each benefit year.",
    detailsBenefitYearHeader: "What is a benefit year?",
    detailsBondingWithChild:
      "<p><strong>Bonding with a child:</strong></p><p><i>This type of leave requires proof of birth or placement documentation.</i></p><ul><li>Bonding with your child after birth, adoption, or foster care placement.</li></ul>",
    detailsCareForFamilyMember:
      "<p><strong>Caring for a family member:</strong></p><ul><li>Managed family affairs while a family member was on active duty in the armed forces.</li><li>Cared for a family member who serves in the armed forces.</li><li>Cared for a family member with a condition lasting 3 days or more that was certified in writing by a health care provider.</li></ul>",
    detailsDoNotReport:
      "<p><strong>You do not need to report:</strong></p><ul><li>Any previous leave taken before {{previousLeaveStartDate}}.</li><li>Any leave previously approved by the Department of Family and Medical Leave.</li></ul><p><strong>Do not report any previous leave you have taken within the PFML program.</strong></p>",
    detailsGeneral:
      "<p>We need to know if you have taken any previous leave (paid or unpaid) apart from the Massachusetts PFML program. For example, you can report leave taken under the Family and Medical Leave Act (FMLA). Reporting your previous leave will allow us to determine the length of leave you qualify for under the PFML program.</p><p><strong>Only report paid or unpaid leave taken after {{previousLeaveStartDate}}, for any of the following reasons:</strong></p>",
    detailsQualifyingReason:
      "<p>A qualifying reason is the cause or event that makes you unable to work and eligible for Paid Family and Medical Leave benefits.</p><p>Qualifying reasons include:</p><ul><li>Bonding with your child during the first 12 months after birth, adoption, or placement</li><li>Caring for a family member with a serious health condition as certified by a health care provider, including illness, injury, or pregnancy</li><li>Managing affairs while a family member is on active duty</li><li>Caring for a family member who was injured serving in the armed forces</li><li>Caring for your own serious health condition as certified by a health care provider, including illness, injury, or pregnancy</li></ul>",
    detailsQualifyingReasonHeader: "What is a qualifying reason?",
    detailsSeriousHealthCondition:
      "<p><strong>Managing your own serious health condition:</strong></p><ul><li>A serious illness or injury lasting 3 days or more that was certified in writing by a health care provider.</li><li>An illness or injury where you received care in a hospital for a period of time.</li><li>A health care provider gave a written certification that a pregnancy-related condition prevented you from working.</li><li>A health care provider gave a written statement certifying you were recovering from birth.</li></ul>",
    dontNeed:
      "<ul><li>Leave that was taken through Massachusetts’ PFML program</li><li>Leave that was taken outside of your current benefit year</li><li>Family leave to care for a family member before July 1, 2021</li></ul>",
    dontNeedHeader: "You don’t need to report:",
    learnMoreText: "Learn more about Benefit Years.",
    need: "<ul><li>Any previous leave (paid or unpaid) for the same reason that you are applying for paid leave now</li><li>Any previous leave for a different qualifying reason</li></ul>",
    needHeader:
      "You’ll need to report previous leave you have taken between {{otherLeaveStartDate}} and {{startDate}}:",
    sectionLabel: "Tell us about your previous leave.",
    sectionLabelv2:
      "Reporting previous leave taken, not including Paid Family and Medical Leave (PFML)",
    title: "$t(shared.claimsOtherLeaveTitle)",
    titlev2:
      "Reporting previous leave taken, not including Paid Family and Medical Leave (PFML)",
  },
  claimsPreviousLeavesOtherReason: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    detailsLabel: "What counts as a qualifying reason?",
    hintDoHeader:
      "You must report paid or unpaid leave for these qualifying reasons that aren't the reason you are applying for paid leave now:",
    hintDontHeader: "You don't need to report:",
    hintHeader:
      "Answer yes if you took paid or unpaid leave for a qualifying reason that is not the reason you are applying for paid leave now. The following are qualifying reasons:",
    hintList:
      "<ul><li>You had a serious health condition, including illness, injury, pregnancy, or recovery from birth.</li><li>If you were sick, you were out of work for at least 3 days and needed continuing care from your health care provider or needed inpatient care.</li><li>You bonded with your child after birth or placement.</li><li>You needed to manage family affairs while a family member is on active duty in the armed forces.</li><li>You needed to care for a family member who serves in the armed forces.</li><li>You needed to care for a family member with a serious health condition and your leave began on or after July 1, 2021.</li></ul>",
    hintTextNo:
      "I haven’t taken previous leave for a qualifying reason, or my other leave was through Massachusetts’ PFML program",
    hintTextYes:
      "I have taken previous leave for one or more qualifying reasons",
    leaveTakenThroughPFML:
      "<ul><li>Leave that was taken through Massachusetts' PFML program</li></ul>",
    sectionLabel:
      "Did you take any previous leave between {{otherLeaveStartDate}} and {{leaveStartDate}} for a different qualifying reason?",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },
  claimsPreviousLeavesOtherReasonDetails: {
    addButton: "$t(shared.claimsPreviousLeaveDetails.addButton)",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hoursLabel: "$t(shared.hoursLabel)",
    isForCurrentEmployerHint:
      "$t(shared.claimsPreviousLeaveDetails.isForCurrentEmployerHint)",
    isForCurrentEmployerLabel:
      "$t(shared.claimsPreviousLeaveDetails.isForCurrentEmployerLabel)",
    leaveEndDateHint: "$t(shared.claimsPreviousLeaveDetails.minimumDateHint)",
    leaveEndDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveEndDateLabel)",
    leaveMinutesHint: "$t(shared.claimsPreviousLeaveDetails.leaveMinutesHint)",
    leaveMinutesLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveMinutesLabel)",
    leaveReasonChoice_activeDutyFamily:
      "Managing family affairs while a family member was on active duty in the armed forces",
    leaveReasonChoice_bonding: "Bonding with my child after birth or placement",
    leaveReasonChoice_care: "Caring for a family member",
    leaveReasonChoice_medical: "An illness or injury",
    leaveReasonChoice_pregnancy: "Pregnancy or recovery from childbirth",
    leaveReasonChoice_serviceMemberFamily:
      "Caring for a family member who served in the armed forces",
    leaveReasonHint:
      "If you didn’t take leave for one of these reasons, go back to the previous screen and select No.",
    leaveReasonLabel: "Why did you need to take this leave?",
    leaveStartDateHint: "$t(shared.claimsPreviousLeaveDetails.minimumDateHint)",
    leaveStartDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveStartDateLabel)",
    limitMessage: "$t(shared.claimsPreviousLeaveDetails.limitMessage)",
    minutesLabel: "$t(shared.minutesLabel)",
    previousLeaveEntryPrefix: "$t(shared.previousLeaveEntryPrefix)",
    removeButton: "$t(shared.claimsPreviousLeaveDetails.removeButton)",
    sectionHint: "$t(shared.claimsPreviousLeaveDetails.sectionHint)",
    sectionLabel: "$t(shared.claimsPreviousLeaveDetails.sectionLabel)",
    title: "$t(shared.claimsOtherLeaveTitle)",
    workedPerWeekMinutesDetails:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetails)",
    workedPerWeekMinutesDetailsLabel:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetailsLabel)",
    workedPerWeekMinutesHint:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesHint)",
    workedPerWeekMinutesLabel:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesLabel)",
  },
  claimsPreviousLeavesSameReason: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hintNo:
      "I have not taken previous leave for the same reason, or my other leave was through Massachusetts’ PFML program",
    hintYes: "I have taken previous leave for the same reason",
    sectionLabel:
      "Did you take any previous leave between {{previousLeaveStartDate}} and {{leaveStartDate}} for the same reason as you are applying for paid leave now?",
    title: "$t(shared.claimsOtherLeaveTitle)",
  },
  claimsPreviousLeavesSameReasonDetails: {
    addButton: "$t(shared.claimsPreviousLeaveDetails.addButton)",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hoursLabel: "$t(shared.hoursLabel)",
    isForCurrentEmployerHint:
      "$t(shared.claimsPreviousLeaveDetails.isForCurrentEmployerHint)",
    isForCurrentEmployerLabel:
      "$t(shared.claimsPreviousLeaveDetails.isForCurrentEmployerLabel)",
    leaveEndDateHint: "$t(shared.claimsPreviousLeaveDetails.minimumDateHint)",
    leaveEndDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveEndDateLabel)",
    leaveMinutesHint: "$t(shared.claimsPreviousLeaveDetails.leaveMinutesHint)",
    leaveMinutesLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveMinutesLabel)",
    leaveStartDateHint: "$t(shared.claimsPreviousLeaveDetails.minimumDateHint)",
    leaveStartDateLabel:
      "$t(shared.claimsPreviousLeaveDetails.leaveStartDateLabel)",
    limitMessage: "$t(shared.claimsPreviousLeaveDetails.limitMessage)",
    minutesLabel: "$t(shared.minutesLabel)",
    previousLeaveEntryPrefix: "$t(shared.previousLeaveEntryPrefix)",
    removeButton: "$t(shared.claimsPreviousLeaveDetails.removeButton)",
    sectionHint: "$t(shared.claimsPreviousLeaveDetails.sectionHint)",
    sectionLabel: "$t(shared.claimsPreviousLeaveDetails.sectionLabel)",
    title: "$t(shared.claimsOtherLeaveTitle)",
    workedPerWeekMinutesDetails:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetails)",
    workedPerWeekMinutesDetailsLabel:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesDetailsLabel)",
    workedPerWeekMinutesHint:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesHint)",
    workedPerWeekMinutesLabel:
      "$t(shared.claimsPreviousLeaveDetails.workedPerWeekMinutesLabel)",
  },
  claimsRace: {
    choiceAmericanIndianAlaskaNative:
      "$t(shared.race.americanIndianAlaskaNative)",
    choiceAnotherRaceNotListedAbove:
      "$t(shared.race.anotherRaceNotListedAbove)",
    choiceAsianAsianAmerican: "$t(shared.race.asianAsianAmerican)",
    choiceBlackAfricanAmerican: "$t(shared.race.blackAfricanAmerican)",
    choiceMultiracial: "$t(shared.race.multiracial)",
    choiceNativeHawaiianOtherPacificIslander:
      "$t(shared.race.nativeHawaiianOtherPacificIslander)",
    choicePreferNotToAnswer: "$t(shared.race.preferNotToAnswer)",
    choiceWhite: "$t(shared.race.white)",
    customHint:
      "If you identify as more than one race, you are welcome to write them all out.",
    customLabel: "What is your race?",
    raceIdLabel: "Race selection",
    sectionLabel: "What is your race?",
    sectionLabelHint:
      "If you prefer not to answer, it won't affect your eligibility for benefits. We ask for this to ensure our program operates equitably and fairly.",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsReducedLeaveSchedule: {
    hoursLabel: "$t(shared.hoursLabel)",
    inputHoursLabel_Friday: "Friday time off",
    inputHoursLabel_Monday: "Monday time off",
    inputHoursLabel_Saturday: "Saturday time off",
    inputHoursLabel_Sunday: "Sunday time off",
    inputHoursLabel_Thursday: "Thursday time off",
    inputHoursLabel_Tuesday: "Tuesday time off",
    inputHoursLabel_Wednesday: "Wednesday time off",
    inputHoursLabel_weekly: "Hours off per week",
    lead: "Enter 0 for days you won’t work a reduced schedule.",
    leadCertGuidance_care:
      "The total number of hours you enter must match the reduced leave schedule section in the $t(shared.certificationFormCare).",
    leadCertGuidance_medical:
      "$t(shared.reducedLeaveScheduleLeadCertGuidanceMedicalOrPregnancy)",
    leadCertGuidance_pregnancy:
      "$t(shared.reducedLeaveScheduleLeadCertGuidanceMedicalOrPregnancy)",
    minutesLabel: "$t(shared.minutesLabel)",
    needDocumentAlert_care: "$t(shared.leavePeriodCaringAlert)",
    needDocumentAlert_medical: "$t(shared.leavePeriodMedicalAlert)",
    needDocumentAlert_pregnancy: "$t(shared.leavePeriodMedicalAlert)",
    sectionLabel:
      "How many hours will you take off while you are on a reduced leave schedule?",
    title: "$t(shared.claimsLeaveDetailsTitle)",
    workPatternVariableTime: "$t(shared.displayTime) per week",
    workPatternVariableTime_noMinutes:
      "$t(shared.displayTime_noMinutes) per week",
    workScheduleToggle: "View your work schedule",
  },
  claimsReview: {
    achTypeLabel: "Account type",
    achType_checking: "$t(shared.achTypeChecking)",
    achType_savings: "$t(shared.achTypeSavings)",
    amountPerFrequency_daily: "$t(shared.amountPerFrequency_daily)",
    amountPerFrequency_inTotal: "$t(shared.amountPerFrequency_inTotal)",
    amountPerFrequency_monthly: "$t(shared.amountPerFrequency_monthly)",
    amountPerFrequency_unknown: "",
    amountPerFrequency_weekly: "$t(shared.amountPerFrequency_weekly)",
    childBirthDateLabel: "Child’s date of birth",
    childPlacementDateLabel: "Child’s placement date",
    currentlyWorkAtEmployerLabel: "Do you currently work at this employer?",
    dateOfHireLabel: "What was your start date at this employer?",
    dateOfSeparationLabel: "When was your last day at this employer?",
    documentsLoadError: "$t(shared.documentsLoadError)",
    editLink: "Edit",
    employeeOrganizationUnit: "Department",
    employerBenefitEntryLabel:
      "$t(shared.employerBenefitEntryPrefix) {{count}}",
    employerBenefitIsFullSalaryContinuous: "Full wage replacement",
    employerBenefitLabel: "Employer-sponsored benefits?",
    employerBenefitType_familyOrMedicalLeave:
      "$t(shared.employerBenefitType_familyOrMedicalLeave)",
    employerBenefitType_imNotSure: "$t(shared.employerBenefitType_imNotSure)",
    employerBenefitType_paidLeave: "$t(shared.employerBenefitType_paidLeave)",
    employerBenefitType_paidTimeOff:
      "$t(shared.employerBenefitType_paidTimeOff)",
    employerBenefitType_permanentDisability:
      "$t(shared.employerBenefitType_permanentDisability)",
    employerBenefitType_shortTermDisability:
      "$t(shared.employerBenefitType_shortTermDisability)",
    employerFeinLabel: "Employer’s EIN",
    employerNameLabel: "What is your employer name?",
    employerNotNotifiedWarning:
      "<p>Your leave request may be delayed or denied if you do not notify your employer of your leave at least 30 days before the start date. If you are unable to notify your employer of your leave at least 30 days before the leave starts due to reasons beyond your control, notify your employer as soon as it is practical to avoid potential delays or denials.</p> <p>Once you submit Part 1 of this application, your employer will be informed that you have started an application for PFML. DFML’s communication with your employer is not a substitute for you notifying your employer about the leave.</p>",
    employerNotifiedLabel: "Notified employer",
    employerNotifiedValue: "No",
    employerNotifiedValue_true: "Notified employer on {{date}}",
    employerWarning:
      "<p>Once you review and submit your application, we will send a copy of your application to your employer. In addition to details about your leave and employment information, your employer will see the last 4 digits of your Social Security Number or ITIN and your mailing address. They will also get a copy of the certification documents.</p>",
    employmentStatusLabel: "Employment status",
    employmentStatusValue_employed: "Currently employed",
    employmentStatusValue_selfEmployed: "Self-employed",
    employmentStatusValue_unemployed: "Unemployed",
    ethnicity_hispanicOrLatino: "$t(shared.ethnicity.hispanicOrLatino)",
    ethnicity_notHispanicOrLatino: "$t(shared.ethnicity.notHispanicOrLatino)",
    ethnicity_preferNotToAnswer: "$t(shared.ethnicity.preferNotToAnswer)",
    familyLeaveTypeLabel: "Family leave type",
    familyLeaveTypeValue_adoption: "Adoption",
    familyLeaveTypeValue_fosterCare: "Foster care",
    familyLeaveTypeValue_newBorn: "Birth",
    familyMemberDateOfBirthLabel: "Family member's date of birth",
    familyMemberNameLabel: "Family member's name",
    familyMemberRelationshipLabel: "Family member's relationship",
    familyMemberRelationship_child: "Child",
    familyMemberRelationship_grandchild: "Grandchild",
    familyMemberRelationship_grandparent: "Grandparent",
    familyMemberRelationship_inlaw: "Parent of spouse or domestic partner",
    familyMemberRelationship_parent: "Parent",
    familyMemberRelationship_serviceMember: "Service Member",
    familyMemberRelationship_sibling: "Sibling",
    familyMemberRelationship_spouse: "Spouse or domestic partner",
    genderValue_genderNotListed: "$t(shared.genderGenderNotListed)",
    genderValue_man: "$t(shared.genderMan)",
    genderValue_nonbinary: "$t(shared.genderNonbinary)",
    genderValue_preferNotToAnswer: "$t(shared.genderPreferNotToAnswer)",
    genderValue_woman: "$t(shared.genderWoman)",
    intermittentFrequencyDurationLabel: "Frequency of intermittent leave",
    intermittentFrequencyDuration_irregularMonths_days:
      "Estimated {{frequency}} absences over the next 6 months, each lasting {{duration}} days.",
    intermittentFrequencyDuration_irregularMonths_hours:
      "Estimated {{frequency}} absences over the next 6 months, each lasting {{duration}} hours.",
    intermittentFrequencyDuration_months_days:
      "Estimated {{frequency}} absences per month, each lasting {{duration}} days.",
    intermittentFrequencyDuration_months_hours:
      "Estimated {{frequency}} absences per month, each lasting {{duration}} hours.",
    intermittentFrequencyDuration_weeks_days:
      "Estimated {{frequency}} absences per week, each lasting {{duration}} days.",
    intermittentFrequencyDuration_weeks_hours:
      "Estimated {{frequency}} absences per week, each lasting {{duration}} hours.",
    isContinuous: "Completely off work during this leave",
    isForCurrentEmployer_false: "From a different employer",
    isForCurrentEmployer_true: "From this employer",
    languageLabel: "Language for written communications",
    leaveDetailsBenefitYearsIntroduction:
      "<p>Your current <benefit-year-guide-link>benefit year</benefit-year-guide-link> is {{startDate}} to {{endDate}}. After you submit Part 1 of your application, we'll automatically split this application into two, one for each benefit year. You can upload the same supporting documentation for both applications.",
    leaveDetailsCurrentBenefitYearsDates:
      "<strong>Current benefit year:</strong> leave dates from {{startDate}} to {{endDate}}",
    leaveDetailsNewBenefitYearsDates:
      "<strong>New benefit year:</strong> leave dates from {{startDate}} to {{endDate}}",
    leaveDetailsSectionHeading: "$t(shared.leaveDetailsStepTitle)",
    leavePeriodLabel_continuous: "$t(shared.claimDurationTypeContinuous)",
    leavePeriodLabel_intermittent: "$t(shared.claimDurationTypeIntermittent)",
    leavePeriodLabel_reduced: "$t(shared.claimDurationTypeReduced)",
    leavePeriodNotSelected: "$t(shared.choiceNo)",
    leaveReasonLabel: "Leave type",
    leaveReasonValue_activeDutyFamily: "$t(shared.leaveReasonActiveDutyFamily)",
    leaveReasonValue_bonding: "$t(shared.leaveReasonBonding)",
    leaveReasonValue_care: "$t(shared.leaveReasonCare)",
    leaveReasonValue_medical: "$t(shared.leaveReasonMedical)",
    leaveReasonValue_pregnancy: "$t(shared.leaveReasonPregnancy)",
    leaveReasonValue_serviceMemberFamily:
      "$t(shared.leaveReasonServiceMemberFamily)",
    mailingAddressLabel: "Mailing address",
    missingRequiredFieldError:
      "We’ve added some new questions since you last updated your application. Return to the <checklist-link>checklist</checklist-link> to make sure you’ve completed every step.",
    numberOfFilesLabel: "$t(shared.filesUploaded)",
    otherIncomeEntryLabel: "$t(shared.otherIncomeEntryPrefix) {{count}}",
    otherIncomeLabel: "Other sources of income?",
    otherIncomeType_jonesAct: "$t(shared.otherIncomeType_jonesAct)",
    otherIncomeType_otherEmployer: "$t(shared.otherIncomeType_otherEmployer)",
    otherIncomeType_railroadRetirement:
      "$t(shared.otherIncomeType_railroadRetirement)",
    otherIncomeType_retirementDisability:
      "$t(shared.otherIncomeType_retirementDisability)",
    otherIncomeType_ssdi: "$t(shared.otherIncomeType_ssdi)",
    otherIncomeType_unemployment: "$t(shared.otherIncomeType_unemployment)",
    otherIncomeType_workersCompensation:
      "$t(shared.otherIncomeType_workersCompensation)",
    otherLeaveChoiceNo: "$t(shared.choiceNo)",
    otherLeaveChoiceYes: "$t(shared.choiceYes)",
    otherLeaveDollarAmount: "{{amount, currency}}",
    partDescription:
      "If you need to make edits to Part {{step}}, you’ll need to call our Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Your application ID is <strong>{{absence_id}}</strong>",
    partHeadingPrefix: "Part {{number}}",
    partHeading_1: "Review: Tell us about yourself and your$t(chars.nbsp)leave",
    partHeading_2: "Review: Tell us how you want to receive your payment",
    partHeading_3: "Review: Upload your documents",
    partOneNextSteps:
      "<p>Once you review and submit Part 1, your in-progress application will be viewable by our Contact Center staff. If you need to make edits to Part 1, you’ll need to call our Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p><p>We’ll also notify your employer that you’ve started an application for Paid Family and Medical Leave. Your leave request may be delayed or denied if you do not notify your employer of your leave at least 30 days before the start date. If you are unable to notify your employer of your leave at least 30 days before the leave starts due to reasons beyond your control, notify your employer as soon as it is practical to avoid potential delays or denials.</p><p>Next, you’ll be able to work on Parts 2 and 3, and submit your application.</p>",
    partOneNextSteps_manualReview:
      "<p>Once you review and submit Part 1, your in-progress application will be viewable by our Contact Center staff. If you need to make edits to Part 1, you’ll need to call our Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p><p>You will not be able to complete Part 3 until your application has been reviewed manually.</p>",
    paymentAccountNumLabel: "Account number",
    paymentMethodLabel: "Direct deposit or paper check",
    paymentMethodValue_ach: "$t(shared.paymentMethodAch)",
    paymentMethodValue_check: "$t(shared.paymentMethodCheck)",
    paymentRoutingNumLabel: "Routing number",
    phoneLabel: "Phone number",
    phoneType_cell: "Mobile",
    phoneType_phone: "Landline",
    pregnancyOrRecentBirthLabel:
      "Leave for pregnancy or recovery from childbirth",
    pregnancyOrRecentBirth_no: "$t(shared.choiceNo)",
    pregnancyOrRecentBirth_yes: "$t(shared.choiceYes)",
    previousLeaveEntryLabel: "$t(shared.previousLeaveEntryPrefix) {{count}}",
    previousLeaveHasPreviousLeavesLabel: "Previous leave?",
    previousLeaveLabel: "Previous paid or unpaid leave?",
    previousLeaveLeaveMinutes: "$t(shared.displayTime)",
    previousLeaveLeaveMinutesLabel: "Total hours of leave taken: ",
    previousLeaveLeaveMinutes_noMinutes: "$t(shared.displayTime_noMinutes)",
    previousLeaveReason_activeDutyFamily:
      "Family leave to manage family affairs while a family member was on active duty in the armed forces",
    previousLeaveReason_bonding:
      "Family leave to bond with a child after birth or placement",
    previousLeaveReason_care: "Family leave to care for a family member",
    previousLeaveReason_medical: "Medical leave for an illness or injury",
    previousLeaveReason_medicalHospital:
      "An illness or injury that required hospitalization",
    previousLeaveReason_pregnancy:
      "Leave for pregnancy or recovery from childbirth",
    previousLeaveReason_pregnancyHealthCondition:
      "A health condition during pregnancy",
    previousLeaveReason_serviceMemberFamily:
      "Family leave to care for a family member who serves in the armed forces",
    previousLeaveReason_unknown: "Unknown",
    previousLeaveType_otherReason: "Leave for a different qualifying reason",
    previousLeaveType_sameReason: "Leave for the same qualifying reason",
    previousLeaveWorkedPerWeekMinutes: "$t(shared.displayTime)",
    previousLeaveWorkedPerWeekMinutesLabel: "Hours worked at time of leave: ",
    previousLeaveWorkedPerWeekMinutes_noMinutes:
      "$t(shared.displayTime_noMinutes)",
    race_americanIndianAlaskaNative:
      "$t(shared.race.americanIndianAlaskaNative)",
    race_anotherRaceNotListedAbove: "$t(shared.race.anotherRaceNotListedAbove)",
    race_asianAsianAmerican: "$t(shared.race.asianAsianAmerican)",
    race_blackAfricanAmerican: "$t(shared.race.blackAfricanAmerican)",
    race_multiracial: "$t(shared.race.multiracial)",
    race_nativeHawaiianOtherPacificIslander:
      "$t(shared.race.nativeHawaiianOtherPacificIslander)",
    race_preferNotToAnswer: "$t(shared.race.preferNotToAnswer)",
    race_white: "$t(shared.race.white)",
    recentlyAcquiredOrMergedLabel:
      "Was your employer recently merged with or acquired by another company?",
    reducedLeaveScheduleLabel: "Hours off per week",
    reducedLeaveScheduleWeeklyTotal: "$t(shared.displayTime)",
    reducedLeaveScheduleWeeklyTotal_noMinutes:
      "$t(shared.displayTime_noMinutes)",
    residentialAddressLabel: "Residential address",
    stepHeading_employerInformation: "$t(shared.claimsEmploymentInfoTitle)",
    stepHeading_leaveDetails: "$t(shared.claimsLeaveDetailsTitle)",
    stepHeading_otherLeave: "$t(shared.claimsOtherLeaveTitle)",
    stepHeading_payment: "Payment method",
    stepHeading_tax: "Tax withholding",
    stepHeading_uploadCareForFamilyMember:
      "Upload Care for a Family Member form",
    stepHeading_uploadCertification: "Upload certification document",
    stepHeading_uploadFamilyMemberServiceProof:
      "Upload family member active duty service proof",
    stepHeading_uploadId: "Upload identity document",
    stepHeading_uploadMilitaryExigency: "Upload miliary exigency document",
    stepHeading_uploadServiceMemberProof: "Upload covered service member ID",

    stepHeading_verifyId: "$t(shared.claimsVerifyIdTitle)",
    submitAction_final: "Submit application",
    submitAction_part1: "Submit Part 1",
    submitLoadingMessage: "Submitting… Do not refresh or go back.",
    taxLabel: "Withhold state and federal taxes?",
    taxNoWithhold: "$t(shared.choiceNo)",
    taxYesWithhold: "$t(shared.choiceYes)",
    title_final: "Review and submit your application",
    title_part1:
      "Check your answers before submitting your$t(chars.nbsp)application.",
    userDateOfBirthLabel: "Date of birth",
    userEthnicityLabel: "Ethnicity",
    userGenderLabel: "Gender Identity",
    userNameLabel: "Name",
    userRaceLabel: "Race",
    userStateIdLabel: "Driver’s License Number",
    userTaxIdLabel: "Social Security Number or ITIN",
    workPatternDaysFixedLabel: "Weekly work hours",
    workPatternDaysVariableLabel: "Average weekly hours",
    workPatternTypeLabel: "Work schedule type",
    workPatternTypeValue_fixed:
      "Each week I work the same number of hours on the same days.",
    workPatternTypeValue_variable:
      "My schedule is not consistent from week to week.",
    workPatternVariableTime: "$t(shared.displayTime)",
    workPatternVariableTime_noMinutes: "$t(shared.displayTime_noMinutes)",
  },
  claimsScheduleFixed: {
    hoursLabel: "$t(shared.hoursLabel)",
    inputHoursHeading: "Tell us your work schedule.",
    inputHoursHeadingHint:
      "Enter the number of hours you work each day. Enter 0 for days you don't work.",
    inputHoursLabel_Friday: "$t(shared.day_Friday)",
    inputHoursLabel_Monday: "$t(shared.day_Monday)",
    inputHoursLabel_Saturday: "$t(shared.day_Saturday)",
    inputHoursLabel_Sunday: "$t(shared.day_Sunday)",
    inputHoursLabel_Thursday: "$t(shared.day_Thursday)",
    inputHoursLabel_Tuesday: "$t(shared.day_Tuesday)",
    inputHoursLabel_Wednesday: "$t(shared.day_Wednesday)",
    minutesLabel: "$t(shared.minutesLabel)",
    title: "$t(shared.claimsEmploymentInfoTitle)",
  },
  claimsScheduleVariable: {
    heading: "How many hours do you work on average each week?",
    hoursLabel: "$t(shared.hoursLabel)",
    inputHoursLabel: "Average weekly hours",
    lead: "We’ll confirm your hours with your employer after you submit your application. Learn more about <calculate-hours-link>how we use this number</calculate-hours-link> and how to figure out your average.",
    minutesLabel: "$t(shared.minutesLabel)",
    title: "$t(shared.claimsEmploymentInfoTitle)",
  },
  claimsSsn: {
    lead: "Don’t have a Social Security Number? Use your Individual Taxpayer Identification Number (ITIN).",
    sectionLabel: "What’s your Social Security Number?",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsStart: {
    explanation:
      "<p>We use this application to determine the leave time and benefit amount you will receive.</p><p>We need true answers to every question so that we can manage the program the way the law requires. You can read more about the <mass-consent-agreement-link>requirement to answer truthfully</mass-consent-agreement-link>.</p><p>Please confirm that you will answer as truthfully as you can.</p>",
    submitApplicationButton: "$t(shared.submitApplicationButton)",
    title: "Start your application",
    truthAttestation:
      "<p>I understand that I need to give true answers to all questions in order to receive and keep my paid leave benefits and job protections. I understand false answers may forfeit my rights to paid leave and may result in other penalties.</p><p>I understand that I will be responsible to return PFML benefits to the Department if I receive benefit payments for which I am not eligible.</p>",
    truthAttestationList:
      "<ul><li>I am not eligible for PFML benefits if I am employed by an employer that is legally excluded from the PFML program (for example, municipalities, districts, political subdivisions or instrumentalities, the United States government, church organizations operated primarily for religious purposes, etc.), unless it has formally opted into the PFML program.</li><li>I am not eligible for PFML benefits if I am employed by an employer that is offering me paid family or medical leave benefits through a Department approved private plan.</li><li>I am not eligible for PFML benefits for periods of time that I receive full wage replacement from my employer or another source. </li></ul>",
  },
  claimsStateId: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    hasStateIdLabel: "Do you have a Massachusetts driver’s license or ID card?",
    idHint:
      "This may be a 9 digit number or begin with an S or SA followed by numbers for a total of 9 characters.",
    idLabel: "Enter your license or ID number",
    title: "$t(shared.claimsVerifyIdTitle)",
  },
  claimsStatus: {
    actionRequiredHeader: "Upload documents",
    appealStartAnotherAppealGuidance:
      "DFML has made a decision for this appeal, see your notices for more information. If you need to start another appeal for this application, call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.",
    appealSubmittedBody: "Submitted: {{submittedDate}}",
    appealSubmittedBody_generated: "Received: {{submittedDate}}",
    appealSubmittedDocumentsUploaded: "Documents uploaded: {{numDocuments}}",
    appealsBody:
      "We have made a decision for this application. If you would like to appeal that decision, you can start an appeal online or do so using mail or fax.",
    appealsBody_submitted:
      "<p>If needed, you can send in additional documents by uploading them.</p>",
    appealsButton_continue: "Continue appeal",
    appealsButton_start: "Start an appeal",
    appealsButton_upload: "Add appeals documents",
    appealsGeneratedFromExtract: "Received via mail, fax, or web form.",
    appealsHeading: "Appeal the decision",
    applicationAndIdSubmittedExtensionAndDocumentsStepHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedExtensionsAndDocuments)",
    applicationAndIdSubmittedExtensionStepHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedExtensions)",
    applicationAndIdSubmittedMedToBondingStepHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedMedToBonding)",
    applicationID: "Application ID: {{fineos_absence_id}}",
    applicationReceived: "Application submitted",
    applicationSubmittedOn:
      "Application for leave submitted on {{submissionDate}}",
    applicationTitle: "Application details",
    applicationUpdatesHeading: "Application updates",
    approvedWaitingPeriodDetail_ClaimCrossBY:
      "You will have two separate 7-day unpaid waiting periods, one at the start of your leave and the other at the start of your new benefit year.",
    approvedWaitingPeriodDetail_ExtensionCrossBY:
      "You will have a 7-day unpaid waiting period at the start of your new benefit year.",
    approvedWaitingPeriodDetail_Intermittent:
      "You will have two separate 7-day unpaid waiting periods. Your first waiting period will start on your first reported day of leave. Your second waiting period will start on your first reported day of leave in your new benefit year.",
    approvedWaitingPeriodDetail_IntermittentExtension:
      "You will have a 7-day unpaid waiting period after your first day of reported leave in your new benefit year.",
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    bondingActionRequiredHeaderProof: "$t(shared.claimsStatus.submitProof)",
    bondingActionRequiredHeaderProof_adoption:
      "$t(shared.claimsStatus.submitProof) of adoption",
    bondingActionRequiredHeaderProof_fosterCare:
      "$t(shared.claimsStatus.submitProof) of placement",
    bondingActionRequiredHeaderProof_newborn:
      "$t(shared.claimsStatus.submitProof) of birth",
    "bondingActionRequiredHeader_Child bonding evidence form":
      "Upload child bonding evidence form",
    bondingActionRequiredTextProofBulletFour:
      "A statement or birth record from the hospital where the child was born with the child’s birth date and signed by the birth registrar",
    bondingActionRequiredTextProofBulletOne: "The child's birth certificate",
    bondingActionRequiredTextProofBulletThree:
      "A statement from the health care provider of the person who gave birth with the child's birth date",
    bondingActionRequiredTextProofBulletTwo:
      "A statement from the child's health care provider with the child's birth date",
    bondingActionRequiredTextProofLineOne: "",
    bondingActionRequiredTextProofLineOne_adoption:
      "Once your child is adopted, submit proof of adoption, including the date of adoption. ",
    bondingActionRequiredTextProofLineOne_fosterCare:
      "Once your child is placed, submit proof of placement, including the date of placement. ",
    bondingActionRequiredTextProofLineOne_newborn:
      "Once your child is born, submit proof of birth, including the child’s date of birth. ",
    bondingActionRequiredTextProofLineTwo:
      "You must upload your document within 90 days of your family leave request. Your application will not be approved until we receive and accept your proof of birth document. We will accept any of the following <proof-of-birth-link>proof of birth documents</proof of birth documents>:",
    bondingCompletedHeaderProof: "",
    bondingCompletedHeaderProof_adoption: "Proof of adoption submitted",
    bondingCompletedHeaderProof_fosterCare: "Proof of placement submitted",
    bondingCompletedHeaderProof_newborn: "Proof of birth submitted",
    bondingDocumentTypeSubmittedOn: "Document submitted on {{uploadDate}}",
    bondingDocumentTypeSubmittedOn_adoption:
      "Proof of adoption submitted on {{uploadDate}}",
    bondingDocumentTypeSubmittedOn_fosterCare:
      "Proof of placement submitted on {{uploadDate}}",
    bondingDocumentTypeSubmittedOn_newborn:
      "Proof of birth submitted on {{uploadDate}}",
    certificationAccordionInProgressOne:
      "Submit an updated Certification of your Serious Health Condition form that covers the extension dates you requested. If the certification document you submitted with your original request covers these new dates, you do not need to submit a new document.",
    certificationAccordionInProgressTwo:
      "The latest certification document we have on file was provided on {{latestUploadDate}}. You or your health care provider can also mail or fax the updated documents.",
    certificationButton: "Upload documents",
    certificationDisplayItem: "Certification form submitted on {{uploadDate}}",
    changeRequestHistoryBody:
      "When we make a decision about your change request you will receive a notice and your leave details will be updated. If you made requests to change your application over the phone, those requests will not appear in this history.",
    changeRequestHistoryHeading: "Change request history",
    checkmarkAltText: "Checkmark",
    currentBenefitYear:
      "<strong>Current benefit year:</strong> From {{startDate}} to {{endDate}}",
    deleteInProgressChangeRequestButton: "Delete change request",
    deletedChangeRequestAlertBody:
      "You've successfully deleted your in progress change request.",
    dfmlActionsHeading: "DFML actions",
    dfmlDecisionBody:
      "After your required documents are submitted and your employer has responded or their deadline has passed, DFML has up to 14 calendar days after receiving your completed application to make a decision to approve, deny, or request more information. The review process may take longer if you request changes to your application. <timeline-link>Learn more about the application approval process.</timeline-link>",
    dfmlDecisionHeading: "Decision",
    dfmlReviewBody:
      "Your request for leave is being reviewed. DFML may need more information from you to review your application. If this happens, you will be notified by email or mail. You will be able to download the Request for Information notice on this page.",
    dfmlReviewHeading: "DFML review",
    documentStatusHeading: "Document status",
    documentTypeSubmittedOn: "{{documentType}} submitted on {{uploadDate}}",
    "documentTypeSubmittedOn_Care for a family member form":
      "Certification of Your Family Member’s Serious Health Condition form submitted on {{uploadDate}}",
    documentTypeSubmittedOn_Identification:
      "Proof of identity submitted on {{uploadDate}}",
    "documentTypeSubmittedOn_Identification Proof":
      "$t(pages.claimsStatus.documentTypeSubmittedOn_Identification)",
    "documentTypeSubmittedOn_Own serious health condition form":
      "Certification of Your Serious Health Condition form submitted on {{uploadDate}}",
    documentsSubmittedExtensionAndDocumentsHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedExtensionsAndDocuments)",
    documentsSubmittedExtensionHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedExtensions)",
    documentsSubmittedHeader: "Documents submitted",
    documentsSubmittedMedToBondingHeader:
      "$t(shared.claimsStatus.applicationAndIdSubmittedMedToBonding)",
    documentsSubmittedNonMedToBondingHeader: "Submit Certification",
    employerEIN: "Employer Identification Number (EIN): {{employer_fein}}",
    employerReviewHeader: "Employer response",
    employerReviewTextEmployerReviewCompleted:
      "Your employer completed their review of your application on <strong>{{displayDate}}</strong>",
    employerReviewTextEmployerReviewCompletedNoReviewDate:
      "Your employer completed their review of your application.",
    employerReviewTextEmployerReviewInProgress:
      "Your employer has until <strong>{{displayDate}}</strong> to respond to your application. DFML has contacted your employer to review your application. If your employer doesn't respond by the deadline, DFML will conduct its review using the information you provided.",
    employerReviewTextEmployerReviewInProgressNoFollowUp:
      "Your employer has <strong>10 business days</strong> to respond to your application. DFML has contacted your employer to review your application. If your employer doesn’t respond by the deadline, DFML will conduct its review using the information you provided.",
    employerReviewTextEmployerReviewPastDeadline:
      "Your employer response deadline passed on <strong>{{displayDate}}</strong>. DFML will conduct its review using the information you provided. You do not need to take further action at this time.",
    extensionSubmitted: "Extension request submitted",
    extensionSubmittedOn: "Extension request submitted on {{submissionDate}}",
    inProgressChangeRequestButton: "Continue request",
    inProgressChangeRequestHeader: "In-progress change request",
    inProgressChangeRequestInvalidHeader_alreadyHasBonding:
      "You already have a family leave to bond with your child tied to this application. ",
    inProgressChangeRequestInvalidHeader_notApproved:
      "You can't continue this change request because you have a request for leave that is not currently approved. ",
    inProgressChangeRequestInvalidHeader_pastLeaveEnd:
      "You can't continue this change request because your leave ended more than 30 days ago. ",
    inProgressChangeRequestInvalidHeader_pending:
      "You can't continue this change request because you have a pending request for leave. ",
    inProgressChangeRequestInvalid_alreadyHasBonding:
      "You can make changes to your leave online once/if it has been approved. If you need to make a change before then you can call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    inProgressChangeRequestInvalid_notApproved:
      "To change your leave dates, you can call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    inProgressChangeRequestInvalid_pastLeaveEnd:
      "If you still need to change your leave dates, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    inProgressChangeRequestInvalid_pending:
      "To change your leave dates, you can wait until your pending leave is approved or call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    inProgressChangeRequestStarted: "Change request started",
    inProgressChangeRequestType: "Type: Not selected",
    inProgressChangeRequestType_cancellation: "Type: Change leave end date",
    inProgressChangeRequestType_extension: "Type: Change leave end date",
    inProgressChangeRequestType_medicalToBonding:
      "Type: Add family leave to bond with a child",
    inProgressChangeRequestType_modification: "Type: Change leave end date",
    inProgressChangeRequestType_withdrawal: "Type: Cancel entire leave",
    infoAlertBody_bonding: "$t(shared.infoAlertBody_bonding)",
    infoAlertBody_pregnancy: "$t(shared.infoAlertBody_pregnancy)",
    infoAlertHeading_bonding: "$t(shared.infoAlertHeading_bonding)",
    infoAlertHeading_pregnancy: "$t(shared.infoAlertHeading_pregnancy)",
    infoRequestsBody:
      "Only provide documents here if you need to upload a Certification form after you requested an earlier leave start date through the Contact Center. We will let you know if we need documents for another reason.",
    infoRequestsHeading: "Upload additional documents",
    learnMoreAboutBenefitYear:
      "Learn more about <benefit-year-info-link>how benefit years affect your application.</benefit-year-info-link>",
    leavePast30Days:
      "<p>Since your leave ended more than 30 days ago, you’ll need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to request a change.</p>",
    leavePeriodDates: "From {{startDate}} to {{endDate}}",
    leavePeriodLabel_continuous: "$t(shared.claimDurationTypeContinuous)",
    leavePeriodLabel_intermittent: "$t(shared.claimDurationTypeIntermittent)",
    leavePeriodLabel_reduced: "$t(shared.claimDurationTypeReduced)",
    leaveReasonValueHeader_activeDutyFamily:
      "$t(shared.leaveReasonActiveDutyFamily)",
    leaveReasonValueHeader_bonding: "$t(shared.leaveReasonBondingHeader)",
    leaveReasonValueHeader_care: "$t(shared.leaveReasonCareHeader)",
    leaveReasonValueHeader_medAndBonding:
      "$t(shared.leaveReasonMedAndBondingHeader)",
    leaveReasonValueHeader_medical: "$t(shared.leaveReasonMedical)",
    leaveReasonValueHeader_pregnancy: "$t(shared.leaveReasonPregnancyHeader)",
    leaveReasonValueHeader_serviceMemberFamily:
      "$t(shared.leaveReasonServiceMemberFamily)",
    leaveReasonValue_activeDutyFamily: "$t(shared.leaveReasonActiveDutyFamily)",
    leaveReasonValue_bonding: "Leave to bond with a child",
    leaveReasonValue_care: "Leave to care for a family member",
    leaveReasonValue_medical: "$t(shared.leaveReasonMedical)",
    leaveReasonValue_pregnancy: "$t(shared.leaveReasonPregnancy)",
    leaveReasonValue_serviceMemberFamily:
      "$t(shared.leaveReasonServiceMemberFamily)",
    leaveRequestSpansBenefitYear:
      "Your request for leave extends into your new benefit year.",
    leaveRequestSpansBenefitYear_Approved:
      "Your approved leave dates extend into a new benefit year.",
    leaveStatusMessage_Approved:
      "<strong>This leave was approved.</strong><p>View your approval notice below for more details about your benefit amount, and how to appeal if your benefits appear incorrect. Learn more about the <request-appeal-link>appeal process</request-appeal-link>.</p>",
    leaveStatusMessage_Cancelled:
      "<strong>This leave was cancelled.</strong><p>This application will no longer be processed. If you want to apply for paid leave again, you can begin another application.</p><p><application-link>Start another application</application-link></p>",
    leaveStatusMessage_Denied:
      "<strong>This leave was denied.</strong><p>View your denial notice below for more details and an explanation of the appeal process.</p><p>If you would like to appeal, you must <appeal-section-link>submit your request</appeal-section-link> within 10 calendar days of the date on your denial notice. Learn more about the <request-appeal-link>appeal process</request-appeal-link>.</p>",
    "leaveStatusMessage_In Review":
      "<strong>We’re reviewing your requested changes or appeal for this leave.</strong>",
    leaveStatusMessage_Pending:
      "<strong>This leave is being reviewed.</strong>",
    leaveStatusMessage_Projected:
      "<strong>This leave is being reviewed.</strong>",
    leaveStatusMessage_ReportHoursV2:
      "<p>Once you go on leave, you can report your intermittent leave hours <report-hours-link>online</report-hours-link> each week, or you can call the Hours Reporting Line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link>.</p>",
    leaveStatusMessage_Voided:
      "<strong>This leave was cancelled.</strong><p>This application will no longer be processed. If you want to apply for paid leave again, you can begin another application.</p><p><application-link>Start another application</application-link></p>",
    leaveStatusMessage_Withdrawn:
      "<strong>This leave was withdrawn.</strong><p>You have withdrawn your application from the review process. If you want to apply for paid leave again, you can begin another application.</p><p><application-link>Start another application</application-link></p>",
    legalNoticesFallback:
      "We will notify you once we’ve made a decision. You’ll be able to download the decision notice on this website. Your notices will appear in the language you selected for receiving written communications. An English translation of your notices will be included in the same file.",
    loadingClaimDetailLabel: "$t(shared.loadingClaimDetailLabel)",
    loadingLegalNoticesLabel: "Loading legal notices",
    medToBondingSubmitted: "Request for leave to bond with a child submitted",
    medToBondingSubmittedOn:
      "Request for leave to bond with a child submitted on {{submissionDate}}",
    nextBenefitYear:
      "<strong>New benefit year:</strong> From {{startDate}} to {{endDate}}",
    noActionRequiredBannerHeading: "No action required at this time",
    noActionRequiredBannerText:
      "We will notify you if we require more information.",
    noticeTranslationMessage:
      "If you need help translating notices from DFML about your application, call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Hours are from 8:30 am to 4:30 pm, Monday through Friday. Interpretation services for up to 240+ languages are available.",
    onlyOneWaitingPeriod:
      "<strong>Waiting Period:</strong> From {{startDate}} to {{endDate}}",
    partiallyApprovedOver60Days_header:
      "<p><strong>Your leave dates in your new benefit year could not be approved.</strong><br />DFML cannot review leave dates in a benefit year that starts more than 60 days in the future.</p><p><strong>What you can do:</strong> On or after {{sixtyDaysBeforeNextBenefitYear}}, submit a separate application to cover the part of your leave that is in your new benefit year. If your new benefit year leave application is approved, you’ll have another unpaid 7-day waiting period at the start of your new benefit year.</p>",
    pendingBenefitYearMoreSixtyDays:
      "<p><strong>Part of your leave cannot be reviewed.</strong></p><p>DFML cannot review leave dates in a benefit year that starts more than 60 days in the future.</p><p><strong>What you can do:</strong>  On or after {{extendDate}}, submit a separate application to cover the part of your leave that is in your new benefit year. If your new benefit year leave application is approved, you’ll have another unpaid 7-day waiting period at the start of your new benefit year.</p>",
    pendingCrossesBenefitYearLessSixtyDays:
      "You will have two separate 7-day unpaid waiting periods, one at the start of your leave and the other at the start of your new benefit year.",
    pendingCrossesBenefitYearLessSixtyDays_Extension:
      "$t(pages.claimsStatus.approvedWaitingPeriodDetail_ExtensionCrossBY)",
    pendingCrossesBenefitYearLessSixtyDays_ExtensionIntermittent:
      "You will have a 7-day unpaid waiting period after your first day of reported leave in your new benefit year.",
    pendingCrossesBenefitYearLessSixtyDays_Intermittent:
      "You will have two separate 7-day unpaid waiting periods. Your first waiting period will start on your first reported day of leave. Your second waiting period will start on your first reported day of leave in your new benefit year.",
    pendingLeaveSyncContent:
      "View your approval notice below to see your leave dates in each benefit year and your maximum weekly benefit amount.",
    readAboutExtendingOrUpdatingAppication:
      "<p>Read more about <update-or-extend-link>updating or extending your application</update-or-extend-link>.</p>",
    receivedApplication:
      "We received your application for leave on {{claimSubmittedDate}}",
    reportOtherBenefitsBody:
      "<p>If your plans for other benefits or income during your paid leave have changed, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. Learn more about <other-leave-income-benefits-link>How other leave and benefits can affect your Paid Family and Medical Leave</other-leave-income-benefits-link>",
    reportOtherBenefitsHeading: "Report other benefits or income",
    requestChangeAltBody:
      "<p>To make changes while we are reviewing your application, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>. If you request a change to your start and end dates, we may need to review your application again.</p><p>Once your application is approved you will be able to make changes to your leave dates in this portal. Learn more about <update-application-link>updating or extending your application</update-application-link>.</p>",
    requestChangeBody:
      "<ul><li>End your leave early</li><li>Extend your leave</li></ul>",
    requestChangeButton: "Start a change request",
    requestChangeHeading: "Request a change to your application",
    requestWithdrawButton: "Start a request",
    requestWithdrawHeading: "Withdraw your request for leave",
    rfiAccordionHeader: "Request for More Information",
    rfiAccordionText_actionRequired:
      "Respond to the Request for More Information posted on {{rfiReceivedDate}}.",
    rfiAccordionText_inProgress:
      "We are reviewing the latest documents you submitted on {{latestDocumentUploadDate}}.",
    rfiBannerActionRequiredParagraph:
      "You received a Request for More Information on <strong>{{rfiReceivedDate}}</strong>. Upload the requested documentation by <strong>{{rfiDueDate}}</strong>. A decision on your application can not be made until documentation is received.",
    rfiBannerActionStatusText_actionRequired: "Action required",
    rfiBannerActionStatusText_inProgress: "In-progress",
    rfiBannerHeader_actionRequired: "We need more information from you",
    rfiBannerHeader_inProgress:
      "We are reviewing your documentation. No action required at this time",
    rfiBannerInProgressParagraphOne:
      "We are reviewing the latest documents you submitted on <strong>{{latestDocumentUploadDate}}</strong>.",
    rfiBannerInProgressParagraphTwo:
      "If needed, you can upload additional documents until <strong>{{rfiDueDate}}</strong>.",
    statusItemCompletedText: "on {{formattedDate}}",
    statusItemInProgressText: "by {{formattedDate}}",
    statusTimelineNotice:
      "Notices usually appear within 30 minutes after we update the status of your application.",
    stepIndicatorHeader: "Timeline",
    submitProofStepLabel: "$t(shared.claimsStatus.submitProof)",
    submitProofStepLabel_adoption:
      "$t(shared.claimsStatus.submitProof) of adoption",
    submitProofStepLabel_fosterCare:
      "$t(shared.claimsStatus.submitProof) of placement",
    submitProofStepLabel_newborn:
      "$t(shared.claimsStatus.submitProof) of birth",
    submittedChangeRequestAlertBody:
      "You will receive a notice and the status of your application will be updated once the department processes your request. We normally process requests within 5 business days.",
    submittedChangeRequestAlertHeading: "We’ve received your change request",
    submittedExtensionCertificationHeader: "Certification Submitted",
    submittedProofStepLabel: "Proof submitted",
    submittedProofStepLabel_adoption: "Proof of adoption submitted",
    submittedProofStepLabel_fosterCare: "Proof of placement submitted",
    submittedProofStepLabel_newborn: "Proof of birth submitted",
    timelineDescription:
      "<p>Your application is complete when:</p><ul><li>You have submitted all required documents</li><li>Your employer has responded or their deadline passes</li></ul>",
    timelineHeading: "Timeline",
    timelineTextFollowUpEmployer:
      "<p>Your employer has until <strong>{{employerFollowUpDate}}</strong> to respond to your application.</p>",
    timelineTextFollowUpGenericDFML:
      "<p>We have <strong>14 calendar days</strong> after receiving your completed application to make a decision to approve, deny or request more information.</p>",
    timelineTextFollowUpGenericEmployer:
      "<p>Your employer has <strong>10 business days</strong> to respond to your application.</p>",
    timelineTextFollowUpMayTakeLonger:
      "<p>The process may take longer if we request more information to complete your application or if you request changes to your application.</p>",
    timelineTextLearnMore:
      "<p>Learn more about the <timeline-link>application approval process.</timeline-link></p>",
    transitionToBondingBody:
      "<p>You may be able to take up to 12 weeks of paid family leave to bond with your child after your medical leave ends. Start your request to add family leave to bond with your child.</p>",
    transitionToBondingButtonLabel: "Add leave to bond with a child",
    transitionToBondingHeading: "Add leave to bond with your child",
    uploadDocumentsButton: "Upload documents",
    uploadDocumentsHeading: "Upload Documents",
    uploadSuccessHeading: "You've successfully submitted your {{document}}",
    "uploadSuccessHeadingDocumentName_appeals-supporting-documentation":
      "appeals documents",
    "uploadSuccessHeadingDocumentName_family-member-medical-certification":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_medical-certification":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_military-caregiver":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_military-caregiver-proof":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_military-exigency":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_military-exigency-proof":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_other-id":
      "$t(shared.documentCategory.identification)",
    "uploadSuccessHeadingDocumentName_pregnancy-medical-certification":
      "$t(shared.documentCategory.certification)",
    "uploadSuccessHeadingDocumentName_proof-of-birth":
      "proof of birth documents",
    "uploadSuccessHeadingDocumentName_proof-of-placement":
      "proof of placement documents",
    "uploadSuccessHeadingDocumentName_state-id":
      "$t(shared.documentCategory.identification)",
    viewApprovalNotice:
      "View your approval notice below for more details about your benefit amount, and how to appeal if your benefits appear incorrect. Learn more about the <request-appeal-link>appeal process</request-appeal-link>.</p>",
    viewNoticesHeading: "View your notices",
    viewPaymentTimeline:
      "<p>See your payment status and estimated payment date on your payments page.</p><payments-page-link>Track your payments</payments-page-link>",
    waitingPeriodExtension: "$t(pages.claimsStatus.onlyOneWaitingPeriod)",
    waitingPeriodFirstYear:
      "<strong>Waiting Period 1:</strong> From {{startDate}} to {{endDate}}",
    waitingPeriodSecondYear:
      "<strong>Waiting Period 2:</strong> From {{startDate}} to {{endDate}}",
    whatHappensNext: "What happens next",
    whatHappensNextButton: "$t(shared.claimsStatus.submitProof)",
    whatHappensNextButton_adoption:
      "$t(shared.claimsStatus.submitProof) of adoption",
    whatHappensNextButton_fosterCare:
      "$t(shared.claimsStatus.submitProof) of placement",
    whatHappensNextButton_newborn:
      "$t(shared.claimsStatus.submitProof) of birth",
    whatYouNeedToDo: "What you need to do",
    whatYouNeedToDoText_adoption:
      "Once your child is adopted, submit proof of adoption, including the date of the adoption, so that we can review your application. Learn more about the <proof-document-link>proof of adoption documents</proof-document-link> we accept.",
    whatYouNeedToDoText_fosterCare:
      "Once your child is placed, submit proof of placement, including the date of the placement, so that we can review your application. Learn more about the <proof-document-link>proof of placement documents</proof-document-link> we accept.",
    whatYouNeedToDoText_newborn: "$t(shared.docsRequired.newborn)",
    withdrawnChangeRequestAlertBody:
      "You will receive a notice and the status of your application will be updated once the department processes your cancellation.",
    withdrawnChangeRequestAlertHeading:
      "You've withdrawn your application for leave",
  },
  claimsSuccess: {
    adjudicationProcess:
      "<ul> <li>Your employer has 10 business days to provide feedback on your application.</li> <li>We’ll confirm your eligibility and make sure that your documents are valid.</li> <li>We will notify you once we’ve made a decision. You’ll be able to read the details of the decision on this website. Your employer will also get a copy of the decision.</li><li>Once your application is approved, you can expect your first payment to arrive at the beginning of your fourth week of leave, if your leave has already started. If your leave starts in the future, you can expect your first payment 2-4 weeks after your leave starts. After that, you will receive your payments every week.</li><li>$t(shared.trackStatus)</li><li>$t(shared.viewPostSubmissionVideo)</li></ul>",
    adjudicationProcessHeading: "What happens next",
    adjudicationProcessText:
      "<ul><li>View your application details page to track status and required actions.</li><li>Once your application is approved, you can expect weekly payments to begin 2-4 weeks after your leave begins. If your leave has already begun, you can expect your first payment to arrive 2 weeks after it is approved.</li><li>There is an <seven-day-waiting-period-link>unpaid 7 day waiting period</seven-day-waiting-period-link> at the start of your leave.</li></ul>",
    adjudicationProcess_bondingAdoptFosterFuture:
      "<ul><li>Your employer has 10 days to provide feedback on your application.</li> <li>Once you’ve provided proof of placement, we’ll confirm your eligibility and make sure that your documents are valid.</li> <li>After we’ve made a decision, you’ll receive an email notification with a link to details about the decision.</li> <li>If you need to change your leave dates because your child arrived in your home earlier or later than expected, you must call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</li><li>If your application is approved prior to your leave, you can expect your first payment to arrive about 3 weeks after your leave starts. Otherwise, you can expect your first payment 2-3 weeks after your leave is approved.</li><li>$t(shared.trackStatus)</li><li>$t(shared.viewPostSubmissionVideo)</li></ul>",
    adjudicationProcess_bondingNewbornFuture:
      "<ul><li>Your employer has 10 days to provide feedback on your application.</li> <li>Once you’ve provided proof of birth, we’ll confirm your eligibility and make sure that your documents are valid.</li> <li>After we’ve made a decision, you’ll receive an email notification with a link to details about the decision.</li> <li>If you need to change your leave dates because your child was born earlier or later than expected, you must call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</li><li>If your application is approved prior to your leave, you can expect your first payment to arrive about 3 weeks after your leave starts. Otherwise, you can expect your first payment 2-3 weeks after your leave is approved.</li><li>$t(shared.trackStatus)</li><li>$t(shared.viewPostSubmissionVideo)</li></ul>",
    adjudicationProcess_caringLeave:
      "<ul><li>Your employer has 10 business days to provide feedback on your application.</li> <li>We’ll confirm your eligibility and make sure that your documents are valid.</li> <li>We will notify you once we’ve made a decision. You’ll be able to read the details of the decision on this website. Your employer will also get a copy of the decision.</li><li>Once your application is approved, you can expect your first payment to arrive at the beginning of your fourth week of leave, if your leave has already started. If your leave starts in the future, you can expect your first payment 2-4 weeks after your leave starts. After that, you will receive your payments every week.</li><li>If you need to end your leave early, you must call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</li><li>$t(shared.trackStatus)</li><li>$t(shared.viewPostSubmissionVideo)</li></ul>",
    adjudicationProcess_medicalPregnantFuture:
      "<ul><li>Your employer has 10 days to provide feedback on your application.</li><li>After we’ve made a decision, you’ll receive an email notification with a link to details about the decision.</li><li>If your application is approved prior to your leave, you can expect your first payment to arrive about 3 weeks after your leave starts. Otherwise, you can expect your first payment 2-3 weeks after your leave is approved.</li><li>$t(shared.trackStatus)</li><li>$t(shared.viewPostSubmissionVideo)</li></ul>",
    adjudicationProcess_v2:
      "<ul><li>View your application details page to track status and required actions.</li><li>Once your application is approved, you can expect weekly payments to begin 2-4 weeks after your leave begins. If your leave has already begun, you can expect your first payment to arrive 2 weeks after it is approved.</li><li>There is an <seven-day-waiting-period-link>unpaid 7 day waiting period</seven-day-waiting-period-link> at the start of your leave.</li><li>Learn about <pto-with-pfml>how you can use Paid Time Off with PFML</pto-with-pfml></li></ul>",
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    caringForAFamilyMemberHeading: "Interested in more support?",
    claimantApplicationId:
      "Your application ID is <strong>{{absence_id}}</strong>",
    exitLink: "View your application",
    familyLeaveToBond:
      "You may be able to take up to 12 weeks of paid family leave to bond with your child after your medical leave ends. <medical-bonding-link>Family leave to bond with your child</medical-bonding-link> can be easily added to your application after your medical leave is approved. You should not submit a second application.",
    familyLeaveToBondHeading: "You may also be eligible for paid family leave",
    learnMore:
      "<ul><li>Review <benefits-amount-details-link>our benefits guide</benefits-amount-details-link> to find out more about how benefits are determined.</li> <li>For a rough estimate of how much you might receive, <benefits-calculator-link>use this calculator</benefits-calculator-link>.</li> <li>The maximum total amount that a person can receive in PFML benefits is ${{maxBenefitAmount}} per week, even if you have been approved for leave benefits from multiple employers.</li>", // eslint-disable-line no-template-curly-in-string
    learnMoreAboutApplicationReview:
      "Learn more about the application review and payment process",
    learnMoreAboutPaidLeaveHeading: "Learn more about paid leave benefits",
    learnMore_one:
      "Review <benefits-amount-details-link>our benefits guide</benefits-amount-details-link> to find out more about how benefits are determined. For a rough estimate of how much you might receive, <benefits-calculator-link>use this calculator</benefits-calculator-link>. The maximum benefit a person can receive per week is ${{maxBenefitAmount}} for a benefit year starting in 2023 and ${{maxNewBenefitAmount}} starting in 2024, even if you have been approved for leave benefits from multiple employers. Learn more about <benefits-amount-details-link>how benefits are calculated.</benefits-amount-details-link>", // eslint-disable-line no-template-curly-in-string
    medicalLeaveAfterBirth:
      "You may be able to take up to 20 weeks of paid medical leave if you’re unable to work during your pregnancy or during your recovery from birth. Your health care provider determines how much medical leave you will need. Call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> if you need <medical-bonding-link>leave for pregnancy or recovery from childbirth</medical-bonding-link>.",
    medicalLeaveAfterBirthHeading:
      "If you are giving birth, you may also be eligible for paid leave for pregnancy or recovery from childbirth",
    proofRequired_bondingAdoptFosterFuture:
      "After your child arrives in your home, you will need to upload, mail, or fax a document that shows your child’s placement date.",
    proofRequired_bondingNewbornFuture:
      "After your child is born, you will need to upload, mail, or fax a document that shows your child’s date of birth.",
    proofRequired_medicalPregnantFuture:
      "You must call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> once your medical leave begins to confirm that your leave has begun. We can’t approve your application until we hear from$t(chars.nbsp)you.",
    reportReductionsHeading: "We may need more information from you",
    reportReductionsMessage:
      "<p>Call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> if any of these are true:</p><ul><li>You have already taken leave to care for a family member since July 1, 2021</li><li>You have already taken leave since January 1, 2021 for <when-can-i-use-pfml>any other reason that qualifies for PFML</when-can-i-use-pfml></li><li>You plan to use any accrued paid leave or any other benefits from your employer during your paid leave from PFML (for example: your employer’s parental leave program or paid sick time)</li><li>You expect to get income from any other sources during your leave (for example: disability insurance, retirement benefits, or another job)</li></ul>",
    takeSurveyButtonText: "Take survey",
    takeSurveyText:
      "Find out if you may be eligible to receive benefits from related services and programs by taking a short survey.",
    title: "You submitted your application",
    trackAndManageApplicationHeader: "Track and manage your application",
    viewApplicationButtonText: "View application",
    viewStatus: "View status and details for your application",
  },
  claimsTaxWithholding: {
    choiceNo: "No, don’t withhold state and federal taxes",
    choiceYes: "Yes, withhold state and federal taxes",
    explanation:
      "<p>You have the option to have state and federal taxes withheld from your weekly benefit. If you choose to have taxes withheld, we will withhold 5% for state taxes and 10% for federal taxes. These percentages can’t be adjusted.</p><p>The IRS hasn’t decided if paid leave benefits are considered taxable income. Massachusetts tax treatment will follow the guidance provided by the IRS.</p><p>If you're unsure whether you want to withhold taxes, we recommend speaking with a <tax-professional-link>tax professional</tax-professional-link> about how IRS decisions could affect your personal tax liability. We cannot offer guidance or advice for individual tax situations.</p>",
    sectionLabel:
      "Do you want us to withhold state and federal taxes from this paid leave benefit?",
    submit: "Submit tax withholding preference",
    title: "Tax withholding",
    warning:
      "<p>After you submit your tax withholding preference, it can’t be edited on this website. To change it before your application has been approved, you will need to call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p><p><strong>Attention: This selection can’t be changed once your application has been approved.</strong></p>",
  },
  claimsUploadCertification: {
    addAnotherFileButton: "Choose another file",
    addFirstFileButton: "Choose files",
    additionalContentList_bonding: "$t(shared.newbornCertificationDocOptions)",
    additionalContentList_military_caregiver:
      "$t(pages.claimsUploadDocumentType.additionalContentList_military_caregiver)",
    additionalContentList_military_caregiver_proof:
      "$t(pages.claimsUploadDocumentType.additionalContentList_military_caregiver_proof)",
    additionalContentList_military_exigency_proof:
      "$t(pages.claimsUploadDocumentType.additionalContentList_military_exigency_proof)",
    certificationDocumentsCount: "$t(shared.filesUploaded)",
    documentsLoadError: "$t(shared.documentsLoadError)",
    fileHeadingPrefix: "File",
    lead_bonding_adopt_foster:
      "You need to upload a statement from your adoption or foster agency or from the Massachusetts Department of Children and Families to confirm the placement and the date of the placement.",
    lead_bonding_newborn:
      "You need to upload one of the following documents to confirm your child’s date of birth:",
    lead_care:
      "You need to upload a completed <caregiver-certification-form-link>$t(shared.certificationFormCare)</caregiver-certification-form-link> to prove that you need to take leave to care for a family member with a serious medical condition.",
    lead_medical:
      "You need to upload a completed <healthcare-provider-form-link>$t(shared.certificationFormMedical)</healthcare-provider-form-link> to prove that you need to take medical leave to manage your own serious health condition.",
    lead_military_caregiver:
      "$t(pages.claimsUploadDocumentType.lead_military_caregiver)",
    lead_military_caregiver_proof:
      "$t(pages.claimsUploadDocumentType.lead_military_caregiver_proof)",
    lead_military_exigency:
      "$t(pages.claimsUploadDocumentType.lead_military_exigency)",
    lead_military_exigency_proof:
      "$t(pages.claimsUploadDocumentType.lead_military_exigency_proof)",
    sectionLabel: "Upload your certification form",
    sectionLabel_bonding: "Upload your documentation",
    sectionLabel_military_caregiver_proof: "Upload proof of military service",
    sectionLabel_military_exigency:
      "Upload your certification of a qualifying exigency",
    sectionLabel_military_exigency_proof: "Upload proof of military service",
    title: "Upload certification",
    title_military_caregiver_proof:
      "$t(pages.claimsUploadDocumentType.title_military_proof)",
    title_military_exigency_proof:
      "$t(pages.claimsUploadDocumentType.title_military_proof)",
    uploadingMessage: "$t(shared.uploadingMessage)",
  },
  claimsUploadCertificationType: {
    anotherOption: "Another form of documentation",
    familyMemberHealthConditionOption: "$t(shared.certificationFormCare) form",
    fmlaOption: "My employer’s Family and Medical Leave Act (FMLA) form",
    questionTitle: "What type of form are you uploading?",
    title: "Upload certification",
    yourSeriousHealthConditionOption:
      "$t(shared.certificationFormMedical) form",
  },
  claimsUploadDocsOptions: {
    certLabel_bonding_adopt_foster: "Proof of placement",
    certLabel_bonding_newborn: "Proof of birth",
    certLabel_care: "$t(shared.certificationFormCare)",
    certLabel_medical: "$t(shared.certificationFormMedical)",
    certLabel_military_caregiver_form:
      "Certification of service member's serious health condition",
    certLabel_military_caregiver_proof: "Proof of military service",
    certLabel_military_exigency_form: "Certification of a qualifying exigency",
    certLabel_military_exigency_proof: "Proof of military service",
    nonStateIdLabel: "Different identification documentation",
    sectionHint:
      "You only need to complete this if you received a notice from the Department of Family and Medical Leave asking you to provide additional documents or you need to provide proof of birth or placement. ",
    sectionLabel: "What kind of document are you uploading?",
    stateIdLabel: "Massachusetts driver’s license or ID",
    title: "Additional documentation",
  },
  claimsUploadDocumentType: {
    addAnotherFileButton: "$t(shared.fileUpload_addAnotherFileButton)",
    addFirstFileButton: "$t(shared.fileUpload_addFirstFileButton)",
    additionalContentList_bonding_newborn:
      "$t(shared.newbornCertificationDocOptions)",
    additionalContentList_military_caregiver:
      "<ul><li><caregiver-certification-form-link>$t(shared.certificationFormCare)</caregiver-certification-form-link></li><li><active-duty-caregiver-form-link>WH-385 Certification for Serious Injury or Illness of a Current Servicemember for Military Caregiver Leave under the Family and Medical Leave Act</active-duty-caregiver-form-link></li><li><veteran-form-link>WH-385-V Certification for Serious Injury or Illness of a Veteran for Military Caregiver Leave under the Family and Medical Leave Act</veteran-form-link></li></ul>",
    additionalContentList_military_caregiver_proof:
      "<ul><li>Active Duty Orders</li><li>Other government-issued military information</li></ul>",
    additionalContentList_military_exigency_proof:
      "<ul><li>Active Duty Orders</li><li>Other government-issued military information</li><li>Letter of impending action</li></ul>",
    documentsLoadError: "$t(shared.documentsLoadError)",
    fileHeadingPrefix: "$t(shared.fileUpload_fileHeadingPrefix)",
    idAccordionContent:
      "<p><strong>If you don’t have any of those, you can provide one of the following plus proof of your Social Security Number or Individual Tax Identification Number:</strong></p><ul><li>Valid, unexpired U.S. State or Territory License or ID, both front and back</li><li>Certified copy of a birth certificate filed with a State Office of Vital Statistics or equivalent agency in the individual’s state of birth. (You can only use a <puerto-rican-birth-certificate-link>Puerto Rican birth certificate</puerto-rican-birth-certificate-link> if it was issued on or after July 1, 2010.)</li><li>Certificate of Citizenship (Form N$t(chars.nbhyphen)560, or Form N$t(chars.nbhyphen)561)</li><li>Certificate of Naturalization (Form N$t(chars.nbhyphen)550 or N$t(chars.nbhyphen)570)</li></ul><p><strong>You can provide proof of your Social Security Number using one of the following documents displaying your complete Social Security Number:</strong></p><ul><li>Social Security card</li><li>W$t(chars.nbhyphen)2 Form</li><li>SSA$t(chars.nbhyphen)1099 Form</li><li>Non$t(chars.nbhyphen)SSA$t(chars.nbhyphen)1099 Form</li><li>Pay stub with your name on it</li></ul><p><identity-proof-link>Learn more about verifying your identity with different documents.</identity-proof-link></p>",
    idAccordionHeading: "If you don’t have any of those documents:",
    leadListNewborn: "$t(shared.newbornCertificationDocOptions)",
    lead_bonding_adopt_foster:
      "You need to upload a statement from your adoption or foster agency or from the Massachusetts Department of Children and Families to confirm the placement and the date of the placement.",
    lead_bonding_newborn:
      "You need to upload one of the following documents to confirm your child’s date of birth:",
    lead_care:
      "You need to upload a completed <caregiver-certification-form-link>$t(shared.certificationFormCare)</caregiver-certification-form-link> to prove that you need to take leave to care for a family member with a serious medical condition.",
    lead_medical:
      "You need to upload a completed <healthcare-provider-form-link>$t(shared.certificationFormMedical)</healthcare-provider-form-link> to prove that you need to take medical leave to manage your own serious health condition.",
    lead_military_caregiver:
      "You need to upload one of the following to prove that you need to take leave to care for a service member with a serious medical condition related to military service:",
    lead_military_caregiver_proof:
      "You need to upload a copy of one of the following documents:",
    lead_military_exigency:
      "You need to upload a completed <exigency-form-link>WH-384 Certification for Military Family Leave for Qualifying Exigency under the Family and Medical Leave Act</exigency-form-link> to prove you need to take exigency leave.",
    lead_military_exigency_proof:
      "You need to upload a copy of one of the following documents:",
    lead_modificationsContext:
      "You can upload your original certification form if the new leave dates you are requesting are covered.",
    lead_modificationsMedicalNote:
      "You may also use a note from a medical provider if it meets the following requirements:",
    lead_modificationsMedicalNoteList:
      "<ul><li>The health condition is referenced in the letter and is related to the condition from the original leave.</li><li>The additional leave dates are specified.</li><li>The letter is on official letterhead or in a Medical Record format that lists the practice name.</li><li>The Department of Family and Medical Leave is able to verify the provider.</li></ul>",
    otherIdentityDocs:
      "<p><strong>You can upload a copy of one of the following documents:</strong></p><ul><li>U.S. State or Territory Real ID, both front and back</li><li>U.S. passport or passport card</li><li>Permanent Resident Card issued by DHS or INS</li><li>Employment Authorization Document (EAD) issued by DHS</li><li>Foreign passport</li></ul>",
    sectionLabel_bonding: "Upload your documentation",
    sectionLabel_certification: "Upload your certification form",
    sectionLabel_massId:
      "Upload the front and back of your Massachusetts driver’s license or ID card",
    sectionLabel_military_caregiver_proof: "Upload proof of military service",
    sectionLabel_military_exigency:
      "Upload your certification of a qualifying exigency",
    sectionLabel_military_exigency_proof: "Upload proof of military service",
    sectionLabel_otherId:
      "Upload an identification document issued by state or federal government",
    title_certification: "Upload certification",
    title_id: "$t(shared.claimsVerifyIdTitle)",
    title_military_proof: "Upload proof of service",
    uploadingMessage: "$t(shared.uploadingMessage)",
  },
  claimsUploadId: {
    accordionContent:
      "<p><strong>If you don’t have any of those, you can provide one of the following plus proof of your Social Security Number or Individual Tax Identification Number:</strong></p><ul><li>Valid, unexpired U.S. State or Territory License or ID, both front and back</li><li>Certified copy of a birth certificate filed with a State Office of Vital Statistics or equivalent agency in the individual’s state of birth. (You can only use a <puerto-rican-birth-certificate-link>Puerto Rican birth certificate</puerto-rican-birth-certificate-link> if it was issued on or after July 1, 2010.)</li><li>Certificate of Citizenship (Form N$t(chars.nbhyphen)560, or Form N$t(chars.nbhyphen)561)</li><li>Certificate of Naturalization (Form N$t(chars.nbhyphen)550 or N$t(chars.nbhyphen)570)</li></ul><p><strong>You can provide proof of your Social Security Number using one of the following documents displaying your complete Social Security Number:</strong></p><ul><li>Social Security card</li><li>W$t(chars.nbhyphen)2 Form</li><li>SSA$t(chars.nbhyphen)1099 Form</li><li>Non$t(chars.nbhyphen)SSA$t(chars.nbhyphen)1099 Form</li><li>Pay stub with your name on it</li></ul><p><identity-proof-link>Learn more about verifying your identity with different documents.</identity-proof-link></p>",
    accordionHeading: "If you don’t have any of those documents:",
    addAnotherFileButton: "$t(shared.fileUpload_addAnotherFileButton)",
    addFirstFileButton: "$t(shared.fileUpload_addFirstFileButton)",
    documentsLoadError: "$t(shared.documentsLoadError)",
    fileHeadingPrefix: "$t(shared.fileUpload_fileHeadingPrefix)",
    idDocumentsCount: "$t(shared.filesUploaded)",
    lead_mass:
      "<p>It’s faster to upload your documents online, but you can fax or mail color copies of your documents if you prefer. Follow the <mail-fax-instructions-link>fax and mail instructions</mail-fax-instructions-link>.</p><p>To verify your identity, <strong>upload a color copy of both the front and back of your ID card.</strong></p>",
    lead_other:
      "<p>It’s faster to upload your documents online, but you can fax or mail color copies of your documents if you prefer. Follow the <mail-fax-instructions-link>fax and mail instructions</mail-fax-instructions-link>.</p><p>To verify your identity you will need valid, unexpired documentation issued by state or federal government.</p>",
    otherIdentityDocs:
      "<p><strong>You can upload a copy of one of the following documents:</strong></p><ul><li>U.S. State or Territory Real ID, both front and back</li><li>U.S. passport or passport card</li><li>Permanent Resident Card issued by DHS or INS</li><li>Employment Authorization Document (EAD) issued by DHS</li><li>Foreign passport</li></ul>",
    sectionLabel_mass:
      "Upload the front and back of your Massachusetts driver’s license or ID card",
    sectionLabel_other:
      "Upload an identification document issued by state or federal government",
    title: "$t(shared.claimsVerifyIdTitle)",
    uploadingMessage: "$t(shared.uploadingMessage)",
  },
  claimsWorkPatternType: {
    choiceHint_fixed:
      "For example, every Monday I work 6 hours, and every Tuesday I work 7 hours.",
    choiceHint_variable:
      "For example, I work 40 hours every week but the days differ, or my schedule changes from week to week.",
    choiceLabel_fixed:
      "Each week I work the same number of hours on the same days.",
    choiceLabel_variable: "My schedule is not consistent from week to week.",
    sectionLabel: "How would you describe your work schedule?",
    title: "$t(shared.claimsEmploymentInfoTitle)",
  },
  confirmPassword: {
    backButton: "$t(shared.changeEmailBackButtonLabel)",
    lead: "Before you can change your email address, you need to re-enter your password.",
    passwordLabel: "$t(shared.passwordLabel)",
    subTitle: "Confirm your password",
    submitButton: "Continue",
    title: "$t(shared.changeEmailTitle)",
  },
  contactInfoForm: {
    firstName: "First name",
    lastName: "Last name",
    lead: "We'll use the information that you enter below to assist you if you call the Contact Center or if we need to call you.",
    phoneExtension: "Phone extension",
    phoneNumber: "Phone number",
    submitButton: "Submit",
    title: "How should we contact you?",
  },
  convertToEmployee: {
    alertDescription:
      'Converting your account from "leave administrator" to "employee" is irreversible and will not be possible if your account has been verified.',
    alertHeading: "This action is irreversible!",
    submit: "Convert account",
    title: "Convert to employee account",
  },
  convertToEmployer: {
    alertDescription:
      'Converting your account from "employee" to "leave administrator" is irreversible and will not be possible if any applications have been created, are in-progress, or are completed.',
    alertHeading: "This action is irreversible!",
    einHint:
      "Your Employer Identification Number is a <ein-link>9-digit number</ein-link> assigned by the Internal Revenue Service. It is listed on tax returns and your payroll department should have this information.",
    einLabel: "Employer ID number (EIN)",
    submit: "Convert account",
    title: "Convert to leave administrator account",
  },
  employersAddLeaveAdmin: {
    add: "Add a leave administrator",
    attestation:
      "I agree that this person should be granted access to manage leave for {{company}}.",
    back: "Back to {{company}}",
    button: "Agree and add leave administrator",
    email: "Email address",
    headingEin: "Employer ID number (EIN)",
    headingOrg: "Organization",
    intro:
      "We'll send a notification to the person you're adding. If they don't have an account they'll be prompted to create one. If they already have an account, be sure to enter the email address they used to create their account.",
    success: "An email notification has been sent to {{email}}",
    successHeading: "Leave administrator added",
    title: "Add a leave administrator to {{company}}",
  },
  employersApplications: {
    // Fallback in case some unexpected leave reason is sent from Fineos:
    absencePeriodReason: "Leave period",
    absencePeriodReason_activeDutyFamily:
      "$t(shared.leaveReasonActiveDutyFamily)",
    absencePeriodReason_bonding: "$t(shared.leaveReasonBonding)",
    absencePeriodReason_care: "$t(shared.leaveReasonCare)",
    absencePeriodReason_medical: "$t(shared.leaveReasonMedical)",
    absencePeriodReason_pregnancy: "$t(shared.leaveReasonPregnancy)",
    absencePeriodReason_serviceMemberFamily:
      "$t(shared.leaveReasonServiceMemberFamily)",
    absencePeriodType_Continuous: "$t(shared.claimDurationTypeContinuous)",
    absencePeriodType_Intermittent: "$t(shared.claimDurationTypeIntermittent)",
    "absencePeriodType_Reduced Schedule":
      "$t(shared.claimDurationTypeReducedSchedule)",
    filterNavLabel: "Filters:",
    filterOrgsLabel: "Organizations",
    filterRemove: "Remove filter:",
    filterRequestDecisionLabel: "Leave details",
    filterRequestDecision_all: "Show all",
    filterRequestDecision_approved: "Approved",
    filterRequestDecision_cancelled: "Cancelled",
    filterRequestDecision_denied: "Denied",
    filterRequestDecision_pending: "Pending",
    filterRequestDecision_withdrawn: "Withdrawn",
    filterReviewableLabel: "Review requested",
    filterReviewable_all: "Show all",
    filterReviewable_no: "No, review not needed",
    filterReviewable_yes: "Yes, review requested",
    filtersApply: "Apply filters",
    filtersReset: "Reset all filters",
    filtersShowWithCount: "Show filters ({{count}})",
    filtersToggle: "Show filters",
    filtersToggle_expanded: "Hide filters",
    noClaimResults: "No applications on file",
    numAbsencePeriodsHidden: "+ {{hiddenCount}} more",
    respondBy: "Respond by {{ date }}",
    reviewAction: "Review Application",
    searchLabel: "Search for employee name or application ID",
    searchSubmit: "Search",
    sortChoice_employee_az: "Last name – A to Z",
    sortChoice_employee_za: "Last name – Z to A",
    sortChoice_newest: "Newest applications",
    sortChoice_oldest: "Oldest applications",
    sortLabel: "Sort",
    tableColHeading_employee_and_case: "Employee (Application ID)",
    tableColHeading_employer: "Organization (FEIN)",
    tableColHeading_leave_details: "Leave details",
    tableColHeading_review_status: "Review due date",
    title: "Applications",
    verificationBody: "$t(shared.unverifiedLeaveAdminAlertMessage)",
    verificationInstructions:
      "You have not verified any organizations. <your-organizations-link>Complete this process</your-organizations-link> to review applications.",
  },
  employersCannotVerify: {
    body: "We can't verify this account because this organization hasn't submitted contributions through MassTaxConnect. Call the Department of Revenue at <dor-phone-link>$t(shared.departmentOfRevenuePhoneNumber)</dor-phone-link> to make arrangements to submit contributions. Once you do that, you'll be able to review leave applications on the next business day. Learn more about <learn-more-link>verifying your account</learn-more-link> on Mass.gov.",
    companyNameLabel: "<strong>Organization:</strong> {{employerDba}}",
    employerIdNumberLabel:
      "<strong>Employer ID number (EIN):</strong> {{employerFein}}",
    title: "We can't verify this organization",
  },
  employersClaimsReview: {
    absenceIdLabel: "Application ID: {{absenceId}}",
    instructionsAmendment:
      "Please review the details of this application carefully. If anything is incorrect or incomplete, you can add an amendment or include specific comments at the end. Changes are not saved until you submit your review.",
    instructionsFollowUpDate: "$t(shared.employerInstructions_followUpDate)",
    otherLeavesBody_v1:
      "<p>Review the benefits and dates your employee reported in the tables below.</p><ul><li><strong>Amend</strong> any leave/benefits or dates that your employee reported incorrectly</li><li><strong>Add</strong> any leave/benefits that your employee forgot to report</li><li><strong>Remove</strong> any leave/benefits that should not have been reported by leaving a comment at the bottom of this page</li></ul>",
    otherLeavesBody_v2: `<p>Review the benefits and dates your employee reported in <strong>previous leave and other disability, family, or medical leave benefits</strong> in the sections below.</p><ul><li><strong>Amend</strong> any leave/benefits or dates that your employee reported incorrectly with that row's "Amend" button.</li><li><strong>Add</strong> any leave/benefits that your employee forgot to report with the "Add" button below the appropriate table.</li><li><strong>Remove</strong> any leave/benefits that should not have been reported by checking that row's "Remove" button.</li></ul>`,
    otherLeavesEmployeeReportedWarning: "$t(shared.claimsReportedWarning)",
    otherLeavesEmployeeReportedWarningTitle:
      "$t(shared.otherLeavesEmployeeReportedWarningTitle)",
    otherLeavesEmployerReportingWarning: "$t(shared.claimsReportedWarning)",
    otherLeavesEmployerReportingWarningTitle:
      "$t(shared.otherLeavesEmployerReportingWarningTitle)",
    otherLeavesReportWarning: "$t(shared.claimsReportedWarning)",
    otherLeavesReportWarningTitle:
      "Information you report may impact your employee’s benefits. ",
    otherLeavesSummaryBoxBody:
      "<ul><li>Previous leave for a qualifying reason that they took before their paid leave from PFML</li><li>Accrued paid leave they plan to use during their paid leave from PFML</li><li>Employer-sponsored benefits they plan to use during their paid leave from PFML</li></ul>",
    otherLeavesSummaryBoxTitle: "Employees are asked to report:",
    otherLeavesTitle: "Other leaves and benefits",
    previouslyReviewed:
      "This application has changed since it was last reviewed.",
    previouslyReviewed_withDate:
      "This application has changed since it was reviewed on {{date}}.",
    ptoBody: `
    <ul-unstyled><li className="usa-icon-list__item"><div className="usa-icon-list__icon text-secondary padding-right-1"><checked></checked></div><div className="ususa-icon-list__content"><p className="usa-icon-list__title">During your review, make sure the employee <strong>has not reported</strong> any paid time off (PTO) used to supplement their PFML payments.</p></div></li><ul className="usa-list padding-left-8 padding-top-1"><li>After an employee applies and is approved for PFML, they can then use PTO (including vacation, sick days, and personal time) to supplement their benefit from DFML as long as the combined benefit amount (DFML and PTO) does not exceed their IAWW. <learn-more-pto-link>Learn more about using Paid Time Off during PFML leave.</learn-more-pto-link></li></ul><li className="usa-icon-list__item"><div className="usa-icon-list__icon text-secondary padding-right-1"><checked></checked></div><div className="ususa-icon-list__content"><p className="usa-icon-list__title">Let us know if an employee has already been paid their <strong>full wage</strong> through PTO by calling the Contact Center at 833-344-7365. Employers are responsible for any overpayments.</p></div></li></ul-unstyled>
    `,
    ptoHeader:
      "<strong>Employees can supplement PFML payments with Paid Time Off</strong>",
    submitButton: "Submit",
    submitLoadingMessage: "Submitting… Do not refresh or go back.",
    title: "Review application from {{name}}",
  },
  employersClaimsStatus: {
    lead: "No action is required of you. You can view this page at any time to understand how to supplement your employee’s PFML payments with paid time off (PTO), download notices, see decisions, or access leave details for this application. Your employee has the right to appeal decisions under Massachusetts regulations (<dfml-regulations-link>458 CMR 2.14</dfml-regulations-link>).",
    noticesLabel: "Notices",
    noticesLanguageDescription:
      "The notice(s) below may be in your employee’s preferred language. Scroll to the bottom of the document to view the English translation of the notice.",
    payments: "Payments",
    title: "Application status for {{name}}",
  },
  employersClaimsSuccess: {
    applicationIdLabel: "<strong>Application ID:</strong> {{absenceId}}",
    backToApplicationsLabel: "Back to Applications",
    employeeNameLabel: "<strong>Employee name:</strong> {{employeeName}}",
    instructions_processingApplication:
      "We’ll begin processing this application and your employee should hear from us within 14 calendar days. Once we’ve made a decision, you’ll receive an email with a direct link to details about the decision.",
    instructions_reimbursement:
      "If your organization has a paid disability, family, or medical leave policy for employees, you may qualify for <reimbursements-link>reimbursements for payments made during this leave</reimbursements-link>.",
    reviewedOnLabel: "<strong>Reviewed on:</strong>  {{date}}",
    title: "Thanks for reviewing the application",
  },
  employersLeaveAdmins: {
    added_at: "Added by {{user}} on {{date}}",
    back: "Back to Organizations",
    body: "You can add or remove leave administrators to manage who has access to this organization.",
    ein: "Employer ID number (EIN): {{ein}}",
    mfaAlertDescription:
      "To add or remove leave administrators, you must add a cell phone number to verify it's you when you log in. Go to the <settings-link>Settings</settings-link> page to enable additional login verification.",
    mfaAlertHeading: "Additional login verification required",
    tableColHeading_email: "Email",
    tableColHeading_name: "Leave administrator",
    tableColHeading_verification: "Verification method",
    verified_at: "Verified MassTaxConnect data on {{date}}",
  },
  employersOrganizations: {
    addOrganizationButton: "Add organization",
    convertDescription: "Your account type is now converted.",
    convertHeading: "Success",
    einTableHeader: "Employer ID number (EIN)",
    lead: "You can manage leave for the following organizations by going to <applications-link>Applications</applications-link>. To see a list of leave administrators, select an organization from the list below.",
    leadOld:
      "You can manage leave for these organizations. In the future, you’ll be able to invite other members of your team to review applications.",
    organizationsTableHeader: "Organization",
    title: "Organizations",
    verificationTag_blocked: "Verification blocked",
    verificationTag_required: "Verification required",
  },
  employersOrganizationsAddOrganization: {
    continueButton: "Continue",
    employerIdNumberLabel: "Employer ID number (EIN)",
    instructions:
      "If you manage leave for multiple organizations, you can add them to your account. You’ll need to verify recent paid leave contributions from MassTaxConnect in the following step.",
    instructions_v2:
      "If you manage leave for multiple organizations, you can add them to your account. You'll need to verify your access to this organization.",
    title: "Add an organization",
  },
  employersOrganizationsSuccess: {
    companyNameLabel: "<strong>Organization:</strong> {{company}}",
    continueButton: "Manage leave",
    employerIdNumberLabel: "<strong>Employer ID number (EIN):</strong> {{ein}}",
    instructions:
      "Your account has been verified. If anyone else on your team needs to review applications, they’ll also need to complete the <learn-more-link>verification process</learn-more-link>.",
    title: "You can now manage leave for this organization",
  },
  employersOrganizationsVerifyContributions: {
    companyNameLabel: "<strong>Organization:</strong> {{company}}",
    detailsLabel: "Where to find your paid leave contributions",
    detailsList:
      "Log in to <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> or contact your payroll department to complete these steps:<ol><li>On the <strong>Summary</strong> page, scroll down to the <strong>Paid Family and Medical Leave</strong> section on the left. In the <strong>Account</strong> portion, select <strong>Returns</strong>.</li><li>Choose <strong>the last period</strong> for which a return has been <strong>received</strong>. For example, if you sent your contributions for 3-31-2021, and the return has been processed and designated as ‘received’, you can use the amount from that period to verify your account. If you have not yet sent your contributions or it is still being processed, use the amount from the most recent period for which you filed that has been processed.</li><li>Go into the return. Click <strong>View or Amend Return</strong>. Then select <strong>Next</strong> at the bottom. Look to <strong>line 6</strong> and you will find the <strong>Total Contributions Due</strong>.</li><li>Copy the <strong>Total Contributions Due</strong> amount for verification.</li></ol>If you have any questions about your paid leave contributions, please contact the Department of Revenue at <dor-phone-link><strong>$t(shared.departmentOfRevenuePhoneNumber)</strong></dor-phone-link> from 9am-4pm ET.",
    employerIdNumberLabel: "<strong>Employer ID number (EIN):</strong> {{ein}}",
    haveAnAccount: "Have an account? <log-in-link>Log in</log-in-link>",
    lead: "We need more information to verify your identity. We require every employer to verify recent <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> data when creating an account. This helps protect your employees and your organization's information.",
    submitButton: "Submit",
    title: "Verify your paid leave contributions from MassTaxConnect",
    withholdingAmountHint: "Include the full amount with dollars and cents.",
    withholdingAmountLabel: "Paid leave contributions from {{date}}",
  },
  employersOrganizationsVerifyMTC: {
    companyNameLabel: "<strong>Organization:</strong> {{company}}",
    detailsLabel: "Where to find your MassTaxConnect PFML Account ID",
    detailsList:
      "If you are not the tax administrator registered to manage the employer's <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> account, identify them and provide the following instructions to get the data needed to verify access to your organization as a leave administrator. <ol><li>Log in to <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link></li><li>From the <strong>Summary</strong> tab, scroll down to locate the <strong>Paid Family and Medical Leave</strong> panel.</li><li>Under <strong>Account</strong>, you will see an Acoount ID beginning with the letters PFM followed by numbers. This is the Account ID that will be used to verify access. Enter it below to verify your organization.</li></ol><br> If you have questions about your MassTaxConnect account, contact the Department of Revenue at (617) 466-3950 from 9am-4pm ET.",
    employerIdNumberLabel: "<strong>Employer ID number (EIN):</strong> {{ein}}",
    haveAnAccount: "Have an account? <log-in-link>Log in</log-in-link>",
    lead: "We need more information to verify your identity. Provide the MassTaxConnect PFML account ID below to access this organization. This helps protect your employees and your organization's information.",
    mtcHint: "Provide the 14-character Account ID (example: PFM-xxxxxxxx-xxx)",
    mtcLabel: "MassTaxConnect PFML Account ID",
    submitButton: "Save and continue",
    title: "Verify your access to this organization",
  },
  employersRemoveLeaveAdmin: {
    back: "Back to {{company}}",
    buttonText: "Remove leave administrator",
    checkboxLabel: "Yes, remove leave administrator",
    lead: "You are about to remove the following leave administrator. They will no longer be able to access or review applications for this organization.",
    pending: "PENDING",
    remove: "Remove",
    success: "{{email}} no longer has access to {{company}}.",
    successHeading: "Leave administrator removed",
    sure: "Are you sure you want to remove this leave administrator?",
    title: "Remove a leave administrator from {{company}}",
    warningBody:
      "You are the only leave administrator for this organization. Before removing yourself, make sure another leave administrator has been added.",
    warningHeading:
      "This organization will be left without anyone to review applications.",
  },
  employersWelcome: {
    checkEmailBody:
      "When an employee applies for leave, you’ll receive updates about their application status and any steps you need to take. We’ll include everyone who is a verified leave administrator with your organization in case you’re out of the office. You and other designated leave administrators may also receive official notices from DFML, including significant, time-sensitive legal notices related to <compliance-link>MGL c. 175M, sec 4</compliance-link> employer compliance audits.",
    checkEmailTitle: "Check your email regularly",
    contactInfo: "Contact information",
    contactInfoEditLabel: "Edit",
    learnMoreLinks:
      "<ul><li><mass-employer-role-link>Your role as a Massachusetts leave administrator</mass-employer-role-link></li><li><reimbursements-link>Request to be reimbursed for paid leave benefits</reimbursements-link></li></ul>",
    learnMoreTitle: "Learn more",
    respondBody:
      "You will receive an email with a direct link when an employee submits an application. You have 10 business days to open the link and review the application online. Responding quickly helps employees reduce time spent waiting on our application decision. If we don't hear from anyone at your organization by the deadline, we'll process the application based only on the information the employee provided.",
    respondTitle: "Respond to applications quickly",
    settingsTitle: "Settings",
    viewApplicationsBody:
      "When you log in to your account you can <applications-link>see all the applications</applications-link> submitted by employees from your organization.",
    viewApplicationsTitle: "View all applications",
    viewFormsBody:
      "You’ll get an email about our application decision with a link to download the notice your employee received. Your employee's notice may be in their preferred language, however there will be an English translation included at the bottom of the document.<br><br> For medical leave to manage their serious health condition, you can download the <healthcare-provider-form-link>$t(shared.certificationFormMedical) form</healthcare-provider-form-link> during the review process. For leave to care for a family member you can download the <caregiver-certification-form-link>Certification to Care for a Family Member</caregiver-certification-form-link> during the review process. ",
    viewFormsTitle: "View forms and notices online",
    welcomeBody:
      "Thanks for joining the paid leave program. Your employees in Massachusetts can apply for Paid Family and Medical Leave. You can review and respond to their applications, and you can see the status of their leave.",
    welcomeTitle: "Welcome",
  },
  getReady: {
    addApplication: "$t(shared.addApplication)",
    alertHeading: "Most people can apply online",
    alertOnline:
      "<p>If you are currently employed in Massachusetts, you can apply online or by phone for the following types of paid leave:</p><ul><li>Medical leave due to your own illness, injury, pregnancy, or recovery from birth</li><li>Family leave to bond with your child after birth, adoption, or foster placement — whether you are applying before or after the child arrives</li><li>Family leave to care for a family member with a serious medical condition</li></ul>",
    alertOnlineHeading: "Apply online",
    alertPhone:
      "<p>Apply by calling the Department of Family and Medical Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> if:</p><ul><li>You are unemployed and you are applying for paid leave</li><li>You need paid family leave to care for a service member with a serious health condition related to military service</li><li>You need paid family leave to manage a <leave-types-link>qualifying family need (or exigency)</leave-types-link> related to a family member’s deployment</li></ul>",
    alertPhoneHeading: "Apply by phone",
    applicationsLink: "View all applications",
    convertDescription: "Your account type is now converted.",
    convertHeading: "Success",
    createClaimButton: "Create an application",
    pfmlCertificationAlertBody:
      "We’ll ask about information on your PFML Certification form. DFML will also accept FMLA Certification forms, but any other type of document may cause your application to be delayed or denied.",
    pfmlCertificationAlertHeading:
      "If your leave requires a PFML Certification form, complete it before starting your application.",
    pfmlCertificationAlertInvalidDocumentTypesList:
      "<ul><li>Screenshots of medical records (example: MyChart, FollowMyHealth)</li><li>Hospital discharge papers</li><li>Provider documentation (prescription, test results, x-ray results, doctor’s note, etc.)</li></ul>",
    pfmlCertificationAlertInvalidDocumentTypesSubhead:
      "We won’t accept any other form of documentation including:",
    startByPhoneDescription: "$t(shared.applicationImportDescription)",
    startByPhoneLabel: "$t(shared.startByPhoneLabel)",
    stepOne:
      "<p>If you can, tell your employer at least 30 days before your leave begins. If you need to take leave right away, tell your employer as soon as possible.</p><p>Once you tell your employer, you have the right to apply and your job is protected. Make a note of when you notified your employer. You will need to provide this date in your leave application.</p>",
    stepOneHeading: "1. Tell your employer that you need to take paid leave",

    stepThree:
      "<p>Applying takes around 15 minutes. Your information will save as you go, so you can finish your application later if you need to.</p><p>If you plan to take medical leave related to pregnancy or during recovery from birth and also family leave to bond with your newborn, apply for medical leave first. <medical-bonding-link>Family leave to bond with your child</medical-bonding-link> can be added after your medical leave is approved.</p><p>You need to create multiple leave applications if you are:</p><ul><li>Taking leave from multiple employers.</li><li>Taking time off in uneven blocks of time (intermittent leave), <strong>and</strong> taking time off completely or on a reduced schedule. You’ll need a separate application for the intermittent leave.</li></ul><p>PFML benefits are subject to reporting for tax purposes and may be subject to taxation. Your decision to have taxes withheld from your benefit may affect your personal tax liability. If you’re unsure whether you want to withhold taxes, we recommend speaking with a <tax-guide-link>tax professional</tax-guide-link>. Learn more about the <tax-liability-link>possible tax implications</tax-liability-link> of paid leave benefits.</p><p>The maximum benefit a person can receive per week is ${{maxBenefitAmount}}. Learn more about <benefits-amount-details-link>how benefits are calculated.</benefits-amount-details-link></p>", // eslint-disable-line no-template-curly-in-string
    stepThreeHeading: "3. Apply",
    stepThree_one:
      "<p>Applying takes around 15 minutes. Your information will save as you go, so you can finish your application later if you need to.</p><p>If you plan to take medical leave related to pregnancy or during recovery from birth and also family leave to bond with your newborn, apply for medical leave first. <medical-bonding-link>Family leave to bond with your child</medical-bonding-link> can be added after your medical leave is approved.</p><p>You need to create multiple leave applications if you are:</p><ul><li>Taking leave from multiple employers.</li><li>Taking time off in uneven blocks of time (intermittent leave), <strong>and</strong> taking time off completely or on a reduced schedule. You’ll need a separate application for the intermittent leave.</li></ul><p>PFML benefits are subject to reporting for tax purposes and may be subject to taxation. Your decision to have taxes withheld from your benefit may affect your personal tax liability. If you’re unsure whether you want to withhold taxes, we recommend speaking with a <tax-guide-link>tax professional</tax-guide-link>. Learn more about the <tax-liability-link>possible tax implications</tax-liability-link> of paid leave benefits.</p><p>The maximum benefit a person can receive per week is ${{maxBenefitAmount}} for a benefit year starting in 2023 and ${{maxNewBenefitAmount}} starting in 2024, even if you have been approved for leave benefits from multiple employers. Learn more about <benefits-amount-details-link>how benefits are calculated.</benefits-amount-details-link></p>", // eslint-disable-line no-template-curly-in-string

    stepTwoAllLeaveTypesBody:
      "You will need to prove your identity. The easiest way to do that is to provide a color copy of a Massachusetts driver’s license or ID card, but there are <required-documents-all-leave-types-link>other options available</required-documents-all-leave-types-link>.",
    stepTwoAllLeaveTypesSubhead: "For all leave types",
    stepTwoBondingLeaveBody:
      "<p>You need a <required-documents-bonding-leave-link>document that confirms your child’s date of birth or placement</required-documents-bonding-leave-link>. You can apply before your child is born or arrives in your home. You will need to provide proof of birth or placement for your application to be approved.</p>",
    stepTwoBondingLeaveSubhead:
      "For family leave to bond with a child after birth, foster placement, or adoption",
    stepTwoCaringLeaveBody:
      "<p>You’ll need a completed $t(shared.certificationFormCare) form.</p>",
    stepTwoCaringLeaveBodyDownload:
      "<p>Download a <family-member-serious-health-condition-form-link>$t(shared.certificationFormCare)</family-member-serious-health-condition-form-link> form (PDF)</p>",
    stepTwoCaringLeaveSubhead:
      "For family leave to care for a family member with a serious health condition",
    stepTwoHeading: "2. Get required documents",
    stepTwoMedicalLeaveBody:
      "<p>You’ll need a completed $t(shared.certificationFormMedical) form.</p>",
    stepTwoMedicalLeaveBodyDownload:
      "<p>Download a <serious-health-condition-form-link>$t(shared.certificationFormMedical)</serious-health-condition-form-link> form (PDF)</p>",
    stepTwoMedicalLeaveSubhead:
      "For medical leave due to your own illness, injury, pregnancy, or recovery from birth",
    title: "Get ready to apply",
  },
  oauth: {
    button_logInBusiness: "Log in with MyMassGov — Leave Administrator",
    button_logInPersonal: "Log in with MyMassGov — Employee",
    button_tryAgainBusiness: "Try again with MyMassGov — Leave Administrator",
    button_tryAgainPersonal: "Try again with MyMassGov — Employee",
  },
  paymentDetails: {
    lumpSumAccordionHeading:
      "{{amount, currency}} for {{formattedStart}} to {{formattedEnd}}",
    lumpSumCollapseAllAccordionsText: "Hide all details",
    lumpSumExpandAllAccordionsText: "Show all details",
    tableAmountHeader: "Amount",
    tableCategoryHeader: "Category",
    tableDescriptionHeader: "Description",
    tablePaymentLineDescription:
      "Other adjustments we needed to make to this payment.",
    "tablePaymentLineDescription_Accrued Paid Leave":
      "Reduction due to accrued paid leave you or your employer reported.",
    "tablePaymentLineDescription_Auto Gross Entitlement":
      "Payment amount for hours you used leave before any adjustments, taxes and/or reductions were taken out.",
    "tablePaymentLineDescription_Cross Benefit Maximum Threshold Adjustment_CrossBenefitYear":
      "Adjustment to match the <max-weekly-benefits-link>maximum weekly benefit</max-weekly-benefits-link> amount of your new benefit year, {{benefitYearStartDate}} to {{benefitYearEndDate}}. ",
    "tablePaymentLineDescription_Cross Benefit Maximum Threshold Adjustment_CrossBenefitYearNull":
      "Adjustment to match the <max-weekly-benefits-link>maximum weekly benefit</max-weekly-benefits-link> amount of your new benefit year. ",
    tablePaymentLineDescription_DIA:
      "Reduction due to worker’s compensation benefits you have received.",
    "tablePaymentLineDescription_DUA reduction":
      "Reduction due to unemployment benefits you have received.",
    "tablePaymentLineDescription_Earnings from another employment/self-employment":
      "Reduction due to earnings from another employment or self-employment you reported.",
    "tablePaymentLineDescription_Employer Reimbursement":
      "Reduction due to your employer receiving a reimbursement for a qualifying non-PFML paid leave plan provided to you.",
    "tablePaymentLineDescription_FIT Amount":
      "10% of your gross payment amount was withheld for federal income tax.",
    "tablePaymentLineDescription_Family or medical leave insurance":
      "Reduction due to family or medical leave insurance you or your employer reported.",
    "tablePaymentLineDescription_Gross Payment Amount":
      "$t(pages.paymentDetails.tablePaymentLineDescription_Auto Gross Entitlement)",
    "tablePaymentLineDescription_Jones Act benefits":
      "Reduction because you have multiple applications which exceed the maximum weekly benefit.",
    "tablePaymentLineDescription_Main Payment Line":
      "The payment amount you’ll receive after all withholdings and reductions are taken out.",
    "tablePaymentLineDescription_Maximum Threshold Adjustment":
      "$t(pages.paymentDetails.tablePaymentLineDescription_Auto Gross Entitlement)",
    "tablePaymentLineDescription_Net payment amount_withAdjustment":
      "The final payment amount you’ll receive <strong>after</strong> all withholdings and reductions are taken out",
    "tablePaymentLineDescription_Net payment amount_withOutAdjustment":
      "The final payment amount you’ll receive for this pay period. This payment didn’t have any reductions due to tax withholdings, reported other leave, income or benefits or other adjustments.",
    "tablePaymentLineDescription_Offset Recovery":
      "The Department reduced your payment amount to recover an overpayment.",
    "tablePaymentLineDescription_Other leave, income and benefits":
      "$t(pages.paymentDetails.tablePaymentLineDescription_Default)",
    "tablePaymentLineDescription_State Income Tax":
      "5% of your gross payment amount was withheld for state income tax.",
    tablePaymentLineDescription_lineType_Adjustments:
      "$t(pages.paymentDetails.tablePaymentLineDescription_lineType_Default)",
    tablePaymentLineDescription_lineType_Cross:
      "Weekly benefit amount exceeded",
    tablePaymentLineDescription_lineType_Default: "Adjustments",
    tablePaymentLineDescription_lineType_Employer: "Employer Reimbursements",
    "tablePaymentLineDescription_lineType_Gross payment amount":
      "Gross payment amount",
    tablePaymentLineDescription_lineType_Jones:
      "Weekly benefit amount exceeded",
    "tablePaymentLineDescription_lineType_Other leave, income and benefits":
      "Other leave, income and benefits",
    tablePaymentLineDescription_lineType_Overpayment: "Overpayment",
    tablePaymentLineDescription_lineType_Tax: "Tax withholding",
    tablePaymentLineDescription_netPayment: "Net payment amount",
    tablePaymentLineOtherBenefitsLink:
      "Learn more about <other-leave-income-benefits-link>how other leave, income and benefits affect payments</other-leave-income-benefits-link>.",
  },
  payments: {
    // Please note that most of these keys are shared by the payments page for the leave admin portal and the claimant portal
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    backToPaymentsButtonLabel: "Back to your payments",
    changesToAmountAnswer:
      "<p>To see the weekly benefit amount, view the most recent <view-notices-link>approval or change notice</view-notices-link>.</p><p>The payment amount seen on this page may be less than the weekly benefit amount due to <using-other-leave-link>other leave, income and benefits</using-other-leave-link> reported. Another notice will be processed if we reduce the benefit amount for any reason other than tax withholding. Please note that tax withholding status cannot be changed at this point, since the application has been approved.</p><p><strong>Other scenarios that may change the received payment amount:</strong></p><ul><li>Other leave, income, and benefits reported</li><li>The maximum total amount of receivable PFML benefits has been reached across multiple applications</li><li>A payment for a pay period less than a full week was processed and received</li><li>Elected to have taxes withheld</li><li>Received an overpayment</li></ul>",
    changesToAmountQuestion:
      "Why the payment may be less than the maximum weekly benefit amount",
    changesToAmountQuestionPtoTopOff:
      "Why a payment may be less than the weekly benefit amount",
    changesToDelaysAnswer:
      "<strong>What may cause a delayed payment</strong><p>The exact day a payment is issued can be delayed because of:</p><ul><li>State and federal holidays</li><li>Pay periods that end on weekends</li><li>Bank processes</li><li>Changes to the leave start and end dates</li><li>Changes to the benefit amount due to additional income or benefits reported</li><li>Other processing issues such as issues with bank account information or mailing addresses</li></ul><p><strong>What may cause a cancelled payment</strong></p><p>Payments can be cancelled because of:</p><ul><li>Paid time off taken on the same day PFML benefits are received</li><li>Changes to the leave start and end dates</li><li>Changes to the benefit amount due to additional income or benefits reported</li><li>Hours worked outside of the schedule set up with the employer or during reported intermittent hours</li></ul>",
    changesToDelaysAnswerPtoTopOff:
      "<strong>What may cause a delayed payment</strong><p>The exact day a payment is issued can be delayed because of:</p><ul><li>State and federal holidays</li><li>Pay periods that end on weekends</li><li>Bank processes</li><li>Changes to the leave start and end dates</li><li>Changes to the benefit amount due to additional income or benefits reported</li><li>Other processing issues such as issues with bank account information or mailing addresses</li></ul><p><strong>What may cause a cancelled payment</strong></p><p>Payments can be cancelled because of:</p><ul><li>Changes to the leave start and end dates</li><li>Changes to the benefit amount due to additional income or benefits reported</li><li>Hours worked outside of the schedule set up with the employer or during reported intermittent hours</li></ul>",
    changesToDelaysQuestion: "What may cause a delayed or cancelled payment",
    changesToHeading: "Changes to payments",
    changesToMaximumWeeklyAnswer:
      "To see the maximum weekly benefit amount, view the <view-notices-link>approval notice.</view-notices-link><p>Another notice will be issued if we reduce the benefit amount for any reason other than tax withholding.</p>",
    changesToMaximumWeeklyAnswerPtoTopOff:
      "To see the weekly benefit amount, view the most recent <view-notices-link>approval or change notice.</view-notices-link><p>A new notice will be sent if the benefit amount is changed for any reason other than tax withholding. Learn more about <how-weekly-benefit-amounts-calc-link>how PFML weekly benefit amounts are calculated and/or changed</how-weekly-benefit-amounts-calc-link></p>",
    changesToMaximumWeeklyQuestion:
      "Where to find the maximum weekly benefit amount",
    changesToMaximumWeeklyQuestionPtoTopOff: "Weekly benefit amount",
    changesToPaymentPreferencesAnswer:
      "<p>To make changes to the payment method or banking information, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",
    changesToPaymentPreferencesQuestion: "Change payment preferences",
    changesToPaymentReductionAnswer:
      "If it appears that we’ve reduced or cancelled payments for an incorrect reason, <appeals-section-link>the DFML decision can be appealed.</appeals-section-link>",
    changesToPaymentReductionQuestion:
      "How to appeal a reduced or cancelled payment",
    estimatedDate:
      "This is the date range we expect to process your direct deposit payment or send your check in the mail. Depending on your bank, expect to receive direct deposit payments in your account 2-3 days after this date. Checks may take 7-11 days to arrive in the mail. Once your payment is processed or your check is sent, that date will be shown in the “date processed” column.",
    estimatedDateHeading: "What does the estimated date mean?",
    infoAlertBody_bonding: "$t(shared.infoAlertBody_bonding)",
    infoAlertBody_pregnancy: "$t(shared.infoAlertBody_pregnancy)",
    infoAlertHeading_bonding: "$t(shared.infoAlertHeading_bonding)",
    infoAlertHeading_pregnancy: "$t(shared.infoAlertHeading_pregnancy)",
    loadingClaimDetailLabel: "$t(shared.loadingClaimDetailLabel)",
    netPaymentAmount: "Net payment amount",
    netPaymentAmountLumpSum: "Net payment amount for all leave periods",
    paymentDetails: "Payment details for each leave period",
    paymentDetailsTitle:
      "Payment for {{startDate}} $t(shared.dateRangeDelimiter) {{endDate}}",
    paymentHowToGetFullSalary: "How to get your full salary while taking PFML",
    paymentHowToGetFullSalary_lineItem1:
      "Most employers offer paid time off (PTO). This includes vacation days, sick days, and personal time",
    paymentHowToGetFullSalary_lineItem2:
      "Some employers also offer temporary disability or paid family and medical leave benefits",
    paymentHowToGetFullSalary_lineItem3:
      "You can choose to use PTO, temporary disability or paid family and medical leave provided by your employer during PFML leave",
    paymentHowToGetFullSalary_lineItem4:
      "Your combined payment amount from PFML and employer payments should not exceed your Individual Average Weekly Wage",
    paymentHowToGetFullSalary_lineItem5:
      "Find your PFML benefit amount and Individual Average Weekly Wage in your <approval-and-change-notice-link>latest approval or change notice</approval-and-change-notice-link>",
    paymentHowToGetFullSalary_lineItem6:
      "Talk to your employer if you want to use PTO during your leave to supplement your PFML weekly benefit",
    paymentHowToGetFullSalary_lineItem7:
      "Learn more about <using-paid-time-off-during-leave-link>using Paid Time Off during PFML leave</using-paid-time-off-during-leave-link>",
    paymentInformationHeading: "Payment information",
    paymentsIntro: "",
    paymentsIntro_Continuous_ApprovalPreStartDate_PostMondayPaymentSchedule:
      "The first payment will be processed on <strong>{{checkbackDate}}</strong>. Payments are processed to be paid each week of leave on Mondays, or on Tuesday if Monday is a holiday.",
    paymentsIntro_Continuous_PostFourteenthClaimDate:
      "Check back on <strong>{{checkbackDate}}</strong> to see when to expect a first payment. The first payment will include multiple weeks of leave. After the first payment is issued, expect to be paid weekly.",
    paymentsIntro_Continuous_PostFourteenthClaimDate_PostMondayPaymentSchedule:
      "The first payment will be processed on <strong>{{checkbackDate}}</strong>. This payment will include multiple weeks of leave. Payments are processed to be paid each week of leave on Mondays, or on Tuesday if Monday is a holiday.",
    paymentsIntro_Continuous_PostFourteenthClaimDate_PostMondayPaymentSchedule_Retroactive:
      "The first payment will be processed on <strong>{{checkbackDate}}</strong>. Since the application was approved after the leave ended, one payment will be issued for the entire leave.",
    paymentsIntro_Continuous_PostFourteenthClaimDate_Retroactive:
      "$t(pages.payments.paymentsIntro_Continuous_Retroactive_Default)",
    paymentsIntro_Continuous_PreFourteenthClaimDate:
      "Check back on <strong>{{checkbackDate}}</strong> to see when to expect the first payment. The first payment will be processed after the second week of leave ends. After the first payment is processed, expect to be paid weekly.",
    paymentsIntro_Continuous_Retroactive_Default:
      "Since the application was approved after the leave ended, there will be one payment for the entire leave. Check back on <strong>{{checkbackDate}}</strong> to see when the payment will be processed.",
    paymentsIntro_Intermittent:
      "After at least 8 hours have been reported, expect to receive a payment from DFML in 2-3 business days. Call the Hours Reporting line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link> each week to report used intermittent leave hours.",
    paymentsIntro_IntermittentV2_Unpaid:
      "<p>This application has an unpaid 7-day waiting period that begins the first day leave is reported to be taken. During the 7-day waiting period, paid time off (PTO) can be used and job protection will be afforded.</p><p>Once the unpaid 7-day waiting period is completed and at least 8 hours of leave have been reported, expect to receive a payment from DFML within 7-10 business days. More than one payment per week may be processed depending on how hours are reported. You can report your leave hours <report-hours-link>online</report-hours-link>, or you can call the Hours Reporting Line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link>.</p>",
    paymentsIntro_IntermittentV2_Unpaid_PostMondayPaymentSchedule:
      "<p>This application has an unpaid 7-day waiting period that begins the first day leave is reported to be taken. During the 7-day waiting period, paid time off (PTO) can be used and job protection will be afforded.</p><p>Once the unpaid 7-day waiting period is completed and at least 8 hours of leave have been reported, expect to receive a payment from DFML within 7-10 business days. More than one payment per week may be processed depending on how hours are reported. You can report your leave hours <report-hours-link>online</report-hours-link>, or you can call the Hours Reporting Line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link>.</p>",
    paymentsIntro_Intermittent_PostMondayPaymentSchedule:
      "After at least 8 hours have been reported, expect to receive a payment from DFML in 2-3 business days. Call the Hours Reporting line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link> each week to report used intermittent leave hours.",
    paymentsIntro_Intermittent_Unpaid:
      "<p>This application has an unpaid 7-day waiting period that begins the first day leave is reported to be taken. During the 7-day waiting period, paid time off (PTO) can be used and job protection will be afforded.</p><p>Once the unpaid 7-day waiting period is completed and at least 8 hours of leave have been reported, expect to receive a payment from DFML within 2-3 business days. More than one payment per week may be processed depending on how hours are reported. Call the Hours Reporting line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link> each week to report used intermittent leave hours.</p>",
    paymentsIntro_Intermittent_Unpaid_PostMondayPaymentSchedule:
      "<p>This application has an unpaid 7-day waiting period that begins the first day leave is reported to be taken. During the 7-day waiting period, paid time off (PTO) can be used and job protection will be afforded.</p><p>Once the unpaid 7-day waiting period is completed and at least 8 hours of leave have been reported, expect to receive a payment from DFML within 2-3 business days. More than one payment per week may be processed depending on how hours are reported. Call the Hours Reporting line at <contact-center-report-phone-link>$t(shared.contactCenterReportHoursPhoneNumber)</contact-center-report-phone-link> each week to report used intermittent leave hours.</p>",
    paymentsIntro_NonIntermittent:
      "Payments are processed to be paid each week of leave. Check back weekly to see when the next payment will be processed.",
    paymentsIntro_NonIntermittent_PostMondayPaymentSchedule:
      "Payments are processed to be paid each week of leave on Mondays, or on Tuesday if Monday is a holiday.<br/><br/>Learn more about <how-benefit-amounts-are-calculated-link>how weekly benefit amounts are calculated</how-benefit-amounts-are-calculated-link>.",
    paymentsIntro_NonIntermittent_Retroactive:
      "Since the application was approved after the leave ended, one payment will be issued for the entire leave.",
    paymentsIntro_NonIntermittent_Retroactive_PostMondayPaymentSchedule:
      "Since the application was approved after the leave ended, one payment has been processed for the entire leave.",
    paymentsIntro_ReducedSchedule_ApprovalPreStartDate_PostMondayPaymentSchedule:
      "$t(pages.payments.paymentsIntro_Continuous_ApprovalPreStartDate_PostMondayPaymentSchedule)",
    paymentsIntro_ReducedSchedule_PostFourteenthClaimDate:
      "$t(pages.payments.paymentsIntro_Continuous_PostFourteenthClaimDate)",
    paymentsIntro_ReducedSchedule_PostFourteenthClaimDate_PostMondayPaymentSchedule:
      "$t(pages.payments.paymentsIntro_Continuous_PostFourteenthClaimDate_PostMondayPaymentSchedule)",
    paymentsIntro_ReducedSchedule_PostFourteenthClaimDate_PostMondayPaymentSchedule_Retroactive:
      "$t(pages.payments.paymentsIntro_Continuous_PostFourteenthClaimDate_PostMondayPaymentSchedule_Retroactive)",
    paymentsIntro_ReducedSchedule_PostFourteenthClaimDate_Retroactive:
      "$t(pages.payments.paymentsIntro_Continuous_Retroactive_Default)",
    paymentsIntro_ReducedSchedule_PreFourteenthClaimDate:
      "Check back on <strong>{{checkbackDate}}</strong> to see when to expect the first payment. The first payment will be processed after the second week of leave ends. After the first payment is processed, expect to be paid weekly.",
    paymentsTitle: "Payments",
    questionsDetails:
      "<p>Call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>.</p>",
    questionsHeader: "Questions?",
    seeDetailsLink:
      "See details for {{startDate}} $t(shared.dateRangeDelimiter) {{endDate}}",
    tableAmountHeader: "Amount",
    tableAmountSent: "{{amount, currency}}",
    tablePayPeriodHeader: "Pay period",
    tablePaymentStatus_Cancelled:
      "This payment was cancelled. See <delays-accordion-link>what may cause a delayed or cancelled payment</delays-accordion-link>.",
    tablePaymentStatus_Check: "Check was mailed on {{sentDate}}.",
    "tablePaymentStatus_Delayed_Address Validation Error":
      "This payment is delayed due to an error with the provided mailing address. Call <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to resolve this issue.",
    "tablePaymentStatus_Delayed_Bank Processing Error":
      "$t(pages.payments.tablePaymentStatus_Delayed_Banking_Error)",
    tablePaymentStatus_Delayed_Banking_Error:
      "This payment has been rejected by the bank. Call <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to resolve this issue.",
    "tablePaymentStatus_Delayed_DUA Additional Income":
      "<p>This payment is delayed because we're making adjustments due to unemployment benefits issued during PFML leave.</p><p>No need to take action at this time.  Most delays are resolved within 3 to 5 business days.</p>",
    tablePaymentStatus_Delayed_Default:
      "This payment is delayed. See <delays-accordion-link>what may cause a delayed or cancelled payment</delays-accordion-link> for details. Most delays are resolved within 3 to 5 business days. The Contact Center will be in touch if they require more information.",
    "tablePaymentStatus_Delayed_EFT Account Information Error":
      "$t(pages.payments.tablePaymentStatus_Delayed_Banking_Error)",
    tablePaymentStatus_Delayed_Processing:
      "The payment is being processed. The {{paymentMethod}} dates will be available in a few days. No action is required at this time.",
    tablePaymentStatus_Delayed_ProcessingWithDate:
      "$t(pages.payments.tablePaymentStatus_Pending)",
    "tablePaymentStatus_Delayed_SelfReported Additional Income":
      "<p>This payment is delayed because we're making adjustments due to <other-leave-income-benefits-link>other leave, income, or benefits</other-leave-income-benefits-link> reported.</p><p>No need to take action at this time. Most delays are resolved within 3 to 5 business days.</p>",
    tablePaymentStatus_Pending:
      "This payment will be sent by {{paymentMethod}} between {{startDate}} and {{endDate}}.",
    "tablePaymentStatus_Sent to bank":
      "Direct deposit was sent on {{sentDate}}. Depending on the bank’s processes, expect to see this payment in the account 2-3 days after this date.",
    tablePaymentStatus_ZeroDollar:
      "Due to other leave, income, and benefits previously reported, no {{paymentMethod}} will be issued for this pay period.",
    tableStatusHeader: "Status",
    tableWaitingWeekGeneric: "Unpaid Waiting Period",
    tableWaitingWeekGeneric_BenefitYear: "Unpaid Waiting Period",
    tableWaitingWeekGeneric_BenefitYear_DateSpan:
      "{{waitingPeriodStart}} to {{waitingPeriodEnd}}",
    tableWaitingWeekGeneric_DateSpan:
      "{{waitingWeekStart}} to {{waitingWeekEnd}}",
    tableWaitingWeekGeneric_IntermittentLeave:
      "The first seven (7) days of approved leave are unpaid",
    tableWaitingWeekHeader: "Waiting period",
    tableWaitingWeekText:
      "There is an unpaid 7-day waiting period at the start of this leave. Payments are not scheduled during this time. Job protection will be afforded. Learn more about the <waiting-week-link>7-day waiting period</waiting-week-link>.",
    tableWaitingWeekText_BenefitYear:
      "Your application has an unpaid 7-day waiting period at the start of each benefit year. You won’t receive any PFML payments for leave you take during this time.",
    tableWaitingWeekText_IntermittentLeave:
      "There is an unpaid 7-day waiting period at the start of this leave. Payments are not scheduled during this time. Job protection will be afforded. Learn more about the <waiting-week-link>7-day waiting period</waiting-week-link>.",
    yourPayments: "Your payments",
  },
  reportIntermittentLeave: {
    changeIntermittentLeaveScheduleFooterBody:
      "Call the contact center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumber)</contact-center-phone-link>. You may need a new certification document for your new leave schedule.",
    changeIntermittentLeaveScheduleFooterHeading:
      "Need to change your intermittent leave schedule?",
    reportIntermittentLeaveHeading: "Report leave hours",
    reportIntermittentLeaveHeadingV2: "View and report leave hours",
    reportIntermittentLeaveTitle: "Report leave",
    reportLeaveHoursApprovedSchedule: "Your approved leave schedule",
    reportLeaveHoursBody:
      "Once you go on leave, you’ll need to report your leave hours as you take them each day to receive payments.",
    reportLeaveHoursButton: "Report your leave hours",
    reportLeaveHoursButtonV2: " View and report your leave hours",
    reportLeaveHoursDateQuestion: "When did you take this leave?",
    reportLeaveHoursError_DateInvalid:
      "Date of leave must include a valid month, day and year",
    reportLeaveHoursError_TimeIsZero: "Enter the hours of leave you took",
    reportLeaveHoursError_TimeMoreThan24Hours:
      "Hours of leave taken in a day can’t be more than 24 hours. If your leave lasted multiple days, enter each day separately.",
    reportLeaveHoursLeaveHoursAndStatusHeading: "Leave hours and status",
    reportLeaveHoursResponseText_Success:
      "We'll review your hours and send you a notice when they've been approved or denied. You can come back here to report additional leave hours as needed.",
    reportLeaveHoursResponseTitle_Error: "An error occurred",
    reportLeaveHoursResponseTitle_Success: "Your leave hours were submitted",
    reportLeaveHoursSubmitButton: "Submit hours",
    reportLeaveHoursTimeQuestion: "How many hours of leave did you take?",
    reportedLeaveHoursTable_DateColumn: "Date",
    reportedLeaveHoursTable_StatusColumn: "Status",
    reportedLeaveHoursTable_TimeColumn: "Time",
    reportedLeaveHoursTable_Title: "Reported leave hours",
  },
  taxDocuments: {
    accordion_item_form_not_provided_body:
      "The DFML may not be able to provide electronic 1099-Gs under the following conditions: <ul><li>If the DFML is investigating a report of fraudulent activity on your account</li><li>If you revoke your consent to digital downloads via the Contact Center or on this website</li><li>If the DFML discovers they are unable to issue your forms electronically</li></ul>",
    accordion_item_form_not_provided_header:
      "When 1099-G tax forms may not be provided",
    accordion_item_hardware_problem_body:
      "To access your 1099-G tax-form electronically, you will need: <ul><li>An internet connection</li><li>Web browser</li><li>Adobe Acrobat reader or similar PDF reader software</li></ul>You will also be asked to verify your login credentials with your phone number, otherwise known as multi-factor authentication.",
    accordion_item_hardware_problem_header:
      "Hardware and software requirements",
    accordion_item_provide_consent_body:
      "<ul><li>You can provide your consent at any time by clicking ‘Yes, I consent’ at the bottom of this page.</li><li>If, after you consent, you decide you no longer want to receive your 1099-G electronically, you can remove your consent at any time by clicking ‘Remove my consent’ at the bottom of this page.</li><li>Giving your consent to receive your IRS Form 1099-G electronically will grant consent indefinitely unless and until you remove your consent.</li><li>If you provide your consent, you will continue to receive IRS Form 1099-Gs from the DFML electronically unless and until you remove your consent.</li><li>Each 1099-G form will remain available on this site for a minimum of three (3) calendar years after its posted date.</li></ul>",
    accordion_item_provide_consent_header: "Providing and removing consent",
    accordion_item_report_fraud_body:
      "If you think your 1099-G tax form is inaccurate, please call the DFML tax line at <dfml-tax-phone-link>$t(shared.dfmlTaxPhoneNumber)</dfml-tax-phone-link>.<br/><learn-more-about-taxes>Learn more about the taxes you may need to pay while collecting PFML benefits.</learn-more-about-taxes>",
    accordion_item_report_fraud_header: "Reporting an error or suspected fraud",
    accordion_item_request_amendments_body:
      "To request an amendment or a new paper copy of your 1099-G, call the DFML tax line at <dfml-tax-phone-link>$t(shared.dfmlTaxPhoneNumber)</dfml-tax-phone-link>.",
    accordion_item_request_amendments_header:
      "Request amendments or new paper copy",
    accordion_item_update_contact_body:
      "You can change your account’s email address in your <account-settings-link>account settings</account-settings-link>. If you need to update your mailing address, call the Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumber)</contact-center-phone-link>",
    accordion_item_update_contact_header: "Update your contact information",
    backButtonLabel: "$t(shared.backToYourApplicationsLink)",
    enableMFAButtonText: "Enable multi-factor authentication",
    noTaxDocuments:
      "If you received payments or credits in {{currentYear}}, you can expect to receive a 1099-G tax form in January {{nextYear}}.",
    noTaxDocumentsHeader: "Your account doesn't have any tax documents",
    taxDocumentAvailable:
      "The Department of Family and Medical Leave issues 1099-G forms if you have received PFML payments or credits in the previous calendar year. The DFML will mail you your 1099-G tax form to the address we have on file by",
    taxDocumentAvailableDate: "January 31st",
    taxDocumentDownloadBody:
      "You can download your tax documents to your device below. Make sure you are downloading and storing your forms to a private and secure location.",
    taxDocumentDownloadHeader: "Download your tax forms",
    taxDocumentMFADisabled:
      "In order to keep your documents secure, you must enable multi-factor authentication (MFA) for digital download of your tax forms.",
    taxDocumentPageHeader: "Your 1099-G Tax forms",
  },
  uploadCertificationNotAccepted: {
    continueToUploadButton: "Continue to upload",
    invalidDocuments:
      "<ul><li>Screenshots of medical records (example: MyChart or FollowMyHealth)</li><li>Hospital discharge papers</li><li>Provider documentation (example: prescription, test results, x-ray results or doctor’s notes)</li><li>Any other document besides a Certification of Your Serious Health Condition form or FMLA form</li></ul>",
    invalidDocumentsHeading:
      "If you upload these documents, your application may be delayed or denied:",
    returnToApplicationButton: "Return to your application",
    title: "DFML may not accept the type of document you’re uploading",
    validDocuments:
      "<ul><li><health-condition-form-link>{{health_condition_form_name}} form</health-condition-form-link></li><li>Your employer’s Family and Medical Leave Act (FMLA) form</li></ul>",
    validDocumentsHeading:
      "You can submit one of the following required documents:",
    whatToDo:
      "<ol><li>Stop filling out this application. Your employer has already been notified that you’ve started an application, and your progress will be saved.</li><li>Download a <health-condition-form-link>{{health_condition_form_name}} form</health-condition-form-link></li><li>Fill out the form with your health care provider</li><li>Return to your application to upload the completed form</li></ol>",
    whatToDoHeading: "What to do if you don’t have the right document",
  },
  userConsentToDataSharing: {
    agreementBody:
      "By continuing, I am indicating that I have read and understood the above user agreements. I give the Department of Family and Medical Leave permission to collect, share, and use my information consistent with the terms of the agreements linked$t(chars.nbsp)above.",
    applicationUsage: "",
    applicationUsageHeading_employer: "Reviewing paid leave applications",
    applicationUsageHeading_user: "Applying for benefits",
    applicationUsageIntro: "We need this information to:",
    applicationUsageList_employer:
      "<ul><li>Check eligibility for coverage</li><li>Determine your employee’s benefit amount</li><li>Administer the program and meet reporting requirements</li><li>Give you the best service possible</li></ul>",
    applicationUsageList_user:
      "<ul><li>Check your eligibility for coverage</li><li>Determine your benefit amount</li><li>Give you the best service possible</li></ul>",
    continueButton: "Agree and continue",
    dataUsageBody_employer:
      "We’ll keep your information private as required by law. As a part of the application process, we may check the information you give with other state agencies. We may share information related to an application with health care providers and contracted private partners.",
    dataUsageBody_user:
      "We’ll keep your information private as required by law. As a part of the application process, we may check the information you give with other state agencies. We may share information related to your application with your employer, employer’s affiliates, health care provider(s), and contracted private partners.",
    dataUsageHeading: "How we use your data",
    fullUserAgreementBody:
      "To find out more about how the Commonwealth might use the information you share with the Department of Family and Medical Leave, please read the <informed-consent-link>DFML Informed Consent Agreement</informed-consent-link> and the <privacy-policy-link>Privacy Policy for Mass.gov</privacy-policy-link>.",
    fullUserAgreementHeading: "Read the full user agreements",
    intro:
      "The information you provide on this website will be used to administer the Paid Family and Medical Leave program. To continue using this website, you must agree to the terms of the user agreements updated as of June$t(chars.nbsp)25,$t(chars.nbsp)2021.",
    title: "How this website uses your information",
  },
  userSettings: {
    accountInformationHeading: "Account information",
    addPhoneNumberButtonText: "Add additional verification",
    additionalVerificationHeading: "Additional login verification",
    additionalVerificationNoMfaText:
      "<p>Make your account more secure by providing a phone number to associate with your account. We'll send you a 6-digit code to verify it's really you. You’ll need to enter the code in order to log in to your account.</p><p>You will need to provide a phone number that can receive text messages (SMS) and that you’ll have consistent access to in case we need to verify your login.</p><p><strong>Additional login verification is not enabled</strong></p>",
    additionalVerificationWithMfaText:
      "To protect your security, we'll send you a 6-digit code to verify it's really you. You’ll need to enter the code in order to log in to your account.",
    cancelEditLoginVerificationLinkText: "Cancel",
    deviceRemovalSuccessDescription:
      "When you sign in with that device, we'll send you a 6-digit code you'll need to enter to verify it's really you.",
    deviceRemovalSuccessHeading: "Trusted device removed",
    editLoginVerificationLabel: "Edit login verification preferences",
    emailEditText: "email change",
    emailLabel: "Email address",
    goToMyMassgov: "Go to MyMassGov",
    lmgSettingsinfo1:
      "Your account settings are managed by your profile on MyMassGov.",
    lmgSettingsinfo2:
      "To change settings, you'll need to go to MyMassGov, make the desired changes, and return back to Paid Leave. You may be asked to log in again.",
    loadingLabel: "Loading",
    mfaChoiceDisable: "Disable additional login verification",
    mfaChoiceEnable: "Enable additional login verification",
    mfaEditText: "mfa change",
    mfaEnabledLabel: "Additional login verification is enabled",
    mfaLabel: "Change Your MFA Settings",
    mfaPhoneNumberLabel: "Phone number",
    mmgProfileEditText:
      "Your MyMassGov Profile saves you time when filling out applications. Profile information can be managed at MyMassGov.",
    mmgProfileLabel: "MyMassGov Profile",
    nameEditText: "NAME CHANGE",
    nameLabel: "Name",
    passwordEditText: "password change",
    passwordLabel: "Change Your Password",
    rowEditText: "Edit",
    saveLoginVerificationButtonText: "Save preference",
    title: "Settings",
    trustedDevicesAddedOn: "Added on {{date}}",
    trustedDevicesHeading: "Trusted devices",
    trustedDevicesLastLogin: "Last login: {{date}}",
    trustedDevicesNone: "You do not have any trusted devices.",
    trustedDevicesRemoveText: "Remove",
  },
  verifyNewEmail: {
    backButton: "$t(shared.changeEmailBackButtonLabel)",
    codeResent:
      "We sent a 6-digit code to your new email address. If you don’t see that email, check your spam folder.",
    codeResentHeading: "Check your email",
    fallbackLead:
      "We sent a 6 digit verification code to your new email address. Enter the code to verify your new email address.",
    lead: "We sent a 6 digit verification code to {{email}}. Enter the code to verify your new email address.",
    resendCodeLink: "Send a new code",
    subTitle: "Verify your new email address",
    submitButton: "Continue",
    textLabel: "6-digit code",
    title: "$t(shared.changeEmailTitle)",
  },
  verifySMS: {
    backButton: "$t(shared.changeEmailBackButtonLabel)",
    lead: "Before you can change your email address, you need to enter the 6-digit code we sent to the phone number we have on file for verification.",
    subTitle: "Enter your security code",
    submitButton: "Continue",
    textLabel: "6-digit code",
    title: "$t(shared.changeEmailTitle)",
  },
};

const components: {
  // Keys are limited to three levels deep, for example `components.spinner.label`.
  // This makes the structure easier to navigate and the process of finding a specific element more consistent
  [componentKey: string]: I18nContentGroup;
} = {
  absencePeriodStatusTag: {
    label_approved: "Approved",
    label_cancelled: "Cancelled",
    label_denied: "Denied",
    label_inReview: "Pending",
    label_pending: "Pending",
    label_projected: "Pending",
    label_voided: "Cancelled",
    label_withdrawn: "Withdrawn",
  },
  acceptableIdDocumentList: {
    content:
      "<p><strong>You can upload a copy of one of the following documents:</strong></p><ul><li>U.S. State or Territory Real ID, both front and back</li><li>U.S. passport or passport card</li><li>Permanent Resident Card issued by DHS or INS</li><li>Employment Authorization Document (EAD) issued by DHS</li><li>Foreign passport</li></ul>",
  },
  amendButton: {
    amend: "Amend",
  },
  amendmentForm: {
    cancel: "Cancel amendment",
  },
  appealsUpload: {
    loadingLabel: "$t(shared.loadingDocumentsLabel)",
  },
  applicationCard: {
    applicationID:
      "Application ID <br /><strong>{{fineos_absence_id}}</strong>",
    benefitYearAlert1: "Your application has been saved in progress.",
    benefitYearAlert2:
      "We are currently processing updates to your leave dates. Come back in 1 to 2 business days to continue your application.",
    claimAssociatedSuccessfully:
      "Application {{fineos_absence_id}} has been added to your account.",
    continueApplication: "Continue application",
    earliestSubmissionDateInFuture:
      "You will be able to submit this application on <strong>{{earliest_submission_date}}</strong>. This is 60 days before the start of your leave. Applications cannot be submitted earlier than 60 days before the start of leave.",
    employerEIN:
      "Employer Identification Number (EIN) <br /><strong>{{employer_fein}}</strong>",
    hasPassedManualReview: "You may complete the rest of your application.",
    heading_activeDutyFamily: "$t(shared.leaveReasonActiveDutyFamily)",
    heading_bonding: "$t(shared.leaveReasonBondingHeader)",
    heading_care: "$t(shared.leaveReasonCareHeader)",
    heading_medAndBonding: "$t(shared.leaveReasonMedAndBondingHeader)",
    heading_medical: "$t(shared.leaveReasonMedicalIllness)",
    heading_noReason: "In-progress application",
    heading_pregnancy: "$t(shared.leaveReasonPregnancyHeader)",
    heading_serviceMemberFamily: "$t(shared.leaveReasonServiceMemberFamily)",
    inProgressText:
      "Submit all three parts so that we can review your application.",
    isInManualReview:
      "We are reviewing your application with the information you provided. Please check back in this portal in 3-5 days to check your claim status. We may require additional documentation from you at that time.",
    leaveDates: "{{start}} to {{end}}",
    leaveDatesLabel: "Leave Dates",
    leavePeriodLabel_reduced: "$t(shared.claimDurationTypeReducedSchedule)",
    leaveStatusHeading_cancelled: "Cancelled application",
    leaveStatusHeading_denied: "Denied application",
    leaveStatusHeading_withdrawn: "Withdrawn application",
    loadingLabel: "$t(shared.loadingDocumentsLabel)",
    noticeOnClickDetails:
      "When you click the notice link, the file will download to your device.<br><br>Your notices appear in the language you selected for receiving written communications. An English translation of your notices is included in the same file. Call the DFML Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> to change your language preference for notices. You can also call to get help with translating notices if your language is not available.",
    otherActions: "Other actions",
    reportIntermittentLeave: "Report leave hours",
    savedInProgress: "Saved in Progress",
    viewAndReportIntermittentLeave: "View and report leave hours",
    viewNotices: "View your notices",
    viewStatusUpdatesAndDetails: "View status updates and details",
    withdrawLeave: "Withdraw your application",
  },
  authNav: {
    backButton: "Back to Mass.gov",
    logInLink: "Log in",
    logOutButton: "Log out",
  },
  backButton: {
    label: "Back",
  },
  benefitYearAlerts: {
    lead: "We are currently processing updates to your benefit year.",
    message_1:
      " Come back in 1 to 2 business days to continue your application.",
    message_2:
      "You do not need to call the contact center to correct the issue.",
  },
  betaBanner: {
    tag: "Beta",
    message:
      "We’re continuously improving and want to hear from you. Help us with <user-feedback-link>your$t(chars.nbsp)feedback</user-feedback-link>.",
  },
  brandBanner: {
    buttonCallToAction: "Here’s how you know",
    buttonMessage: "An official website of the Commonwealth of Massachusetts",
    officialMessage:
      "<p>Official websites use .mass.gov</p><p>A .mass.gov website belongs to an official government organization in Massachusetts.</p>",
    securityMessage:
      "<p>Secure websites use HTTPS certificate</p><p>A lock icon (<icon />) or https:// means you’ve safely connected to the official website. Share sensitive information only on official, secure websites.</p>",
  },
  cancelButton: {
    buttonLabel_amendment: "Cancel Amendment",
    buttonLabel_addition: "Cancel Addition",
  },
  certificationUploadMethodInput: {
    hintLead:
      "You can either upload your certification document page by page, or as a single file. If you upload individual pages, each page must be less than 4.5 MB. If you upload a single file, the whole file must be less than 4.5 MB.",
    label: "How will you upload this document?",
    multipleLabel: "I am uploading individual pages",
    singleLabel: "I am uploading one complete document",
  },
  certificationUploadPitfalls: {
    endDate:
      "We can’t certify leave without an end date. If a condition needs to be re-evaluated later, the provider should provide the re-evaluation date. You can extend your leave with a new certification after re-evaluation.",
    endDateHeading:
      "Make sure your health care provider has provided an end date",
    everyPage:
      "Every page of the certification should be uploaded, even if it’s blank. If you have already submitted a page, you do not have to upload that page again unless it has changed or was unclear.",
    everyPageHeading: "Upload every page",
    everySection:
      "If relevant questions are blank or incomplete, we can’t complete our review. You and the health care provider need to complete all the sections you are asked to complete.",
    everySectionHeading: "Complete every section of your certification",
    introduction:
      "These errors can lead to an additional request for a certification document or a denial.",
    label: "How to avoid common certification errors",
    leaveSchedule:
      "The leave dates you submit must be within the date range certified by the health care provider. If you are applying for continuous leave, intermittent leave, or a reduced leave schedule, the provider must certify you need that type of leave schedule.",
    leaveScheduleHeading:
      "Apply for the leave schedule that the health care provider certifies",
    matchInformationHeading:
      "The employee information on the certification form should match the information in your application",
    rightForm:
      "For medical leave to manage your own serious health condition, upload a form that certifies your own serious health condition. To care for a family member, upload a form that certifies your family member’s serious health condition. <health-form-link>Download a new certification form if you need one.</health-form-link>",
    rightFormHeading: "Submit the right form for your type of leave",
  },
  changeEmailSuccessAlert: {
    heading: "Email address changed",
    message:
      "We have updated your email address. Now you can sign in to this website using {{email}} and any future email notifications will be sent there.",
  },
  claimsLeaveSpansBenefitYears: {
    bothCanBeSubmittedBenefitsCalculatedSeparately:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCanBeSubmittedBenefitsCalculatedSeparately)",
    bothCanBeSubmittedDeterminationMayBeDifferent:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCanBeSubmittedDeterminationMayBeDifferent)",
    bothCanBeSubmittedReviewedSeparately:
      "We’ll review each application separately. This means that:",
    bothCanBeSubmittedReviewedSeparatelyOutOfSync:
      "If your application is split across two benefit years, we’ll review each application separately. This means that:",
    bothCanBeSubmittedSevenDayWaitingPeriod:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCanBeSubmittedSevenDayWaitingPeriod)",
    bothCannotBeSubmittedCurrent:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCannotBeSubmittedCurrent)",
    bothCannotBeSubmittedNew:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCannotBeSubmittedNew)",
    bothCannotBeSubmittedReminder:
      "$t(shared.claimsLeaveSpansBenefitYears.bothCannotBeSubmittedReminder)",
    currentBenefitYearLeaveDates:
      "$t(shared.claimsLeaveSpansBenefitYears.currentBenefitYearLeaveDates)",
    introduction: "$t(shared.claimsLeaveSpansBenefitYears.introduction)",
    newBenefitYearLeaveDates:
      "$t(shared.claimsLeaveSpansBenefitYears.newBenefitYearLeaveDates)",
    secondCannotBeSubmittedCurrent:
      "$t(shared.claimsLeaveSpansBenefitYears.secondCannotBeSubmittedCurrent)",
    secondCannotBeSubmittedCurrentFinalContentPart1:
      "Your in-progress application will be viewable by our Contact Center staff. If you need to make edits to Part 1, you’ll need to call our Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    secondCannotBeSubmittedCurrentFinalContentPart2:
      "We’ll also notify your employer that you’ve started an application for paid family and medical leave.",
    secondCannotBeSubmittedCurrentFinalContentPart3:
      "Next, you’ll be able to work on Parts 2 and 3, and submit your application.",
    secondCannotBeSubmittedNew:
      "$t(shared.claimsLeaveSpansBenefitYears.secondCannotBeSubmittedNew)",
  },
  confirmSignUp: {
    codeLabel: "$t(shared.verificationCodeLabel)",
    confirmButton: "Submit",
    resendCodeLink: "$t(shared.resendVerificationCodeLink)",
    signInFooterLink: "$t(shared.backToLoginLink)",
    title: "Verify your email address",
    verifyHint:
      "We sent a 6-digit verification code to {{emailAddress}}. Enter the code to verify your new email address.",
  },
  consentToViewDocuments: {
    consentBody_userHasConsented:
      "By selecting “Remove my consent”, I understand that I am removing electronic access to my 1099-G forms on this site. By removing my consent, I understand that previous 1099-G forms issued electronically will not be sent to my mailing address. I am aware that I can come back and provide my consent at any time.",
    consentBody_userHasNotConsented:
      "I understand that by selecting “Yes, I consent”, I consent to receiving my IRS Form 1099-G  electronically and in PDF format in addition to a paper copy sent to my mailing address. I understand that I can remove my consent at any time.",
    consentButton_userHasConsented: "Remove my consent",
    consentButton_userHasNotConsented: "Yes, I consent",
    consentQuestion_userHasConsented:
      "Would you like to remove consent to download electronic 1099-G's on this website?",
    consentQuestion_userHasNotConsented:
      "Do you consent to being provided electronic 1099-G's on this website?",
    provideConsentBody:
      "Before the DFML can issue your 1099-G tax form electronically, the IRS requires you to review the following information and provide your consent.",
    provideConsentHeading: "Provide your consent to download 1099-G tax forms",
    removeConsentAccordionHeader: "Remove consent for digital download",
    taxFormChangesAccordionHeader: "Changes to tax forms",
  },
  deleteButton: {
    delete: "Remove",
  },
  documentRequirements: {
    blurryId: "Must not be blurry",
    blurryIdAlt: "A blurry image of the front side of a Massachusetts ID.",
    body_appeal_support:
      "<ul><li>The document must be valid and unexpired</li><li>Any ID document must be in full color</li><li>The text must be clear and readable</li><li>The document can be in one file, or in separate files</li><li>Each file must be smaller than 4.5 MB</li></ul>",
    body_certification:
      "<ul><li>The text must be clear and readable</li><li>Upload every page of the document where you or your health care provider have entered information</li><li>Each file you upload must be smaller than 4.5 MB</li></ul>",
    body_healthCertification:
      "<ul><li>The text must be clear and readable</li><li>Any file you upload must be smaller than 4.5 MB</li></ul><p>To review an application we need all the pages of your certification document. Upload every page even if it does not require information from you or your health care provider. If there are pages you have already submitted, you don’t have to send those pages again unless they have been updated. For any updates, upload both the updated pages and the page with your provider’s information and signature.</p>",
    body_massId:
      "<ul><li>The image of the card must be in full color</li><li>The text must be clear and readable</li><li>The front and back must be uploaded</li><li>Each file must be smaller than 4.5 MB</li></ul>",
    body_otherId:
      "<ul><li>The document must be valid and unexpired</li><li>The image of the document must be in full color</li><li>The text must be clear and readable</li><li>Each file must be smaller than 4.5 MB</li></ul>",
    bothSides: "Must upload both sides",
    bothSidesAlt: "A clear image of both sides of a Massachusetts ID.",
    exampleHeader: "Example",
    header: "Document requirements",
  },
  downloadableDocument: {
    createdAtDate: "Posted {{date}}",
    noticeName: "Other notice $t(shared.pdfNoticeSuffix)", // it should fallback to this if we receive an unexpected or undefined enum
    noticeName_appealAcknowledgment:
      "Appeal Acknowledgment $t(shared.pdfNoticeSuffix)",
    noticeName_appealApproved: "Appeal Approved $t(shared.pdfNoticeSuffix)",
    noticeName_appealDismissedExempt:
      "Appeal Dismissed - Exempt Employer $t(shared.pdfNoticeSuffix)",
    noticeName_appealDismissedOther:
      "Appeal Dismissed - Other $t(shared.pdfNoticeSuffix)",
    noticeName_appealHearingVirtualFillable:
      "Appeal Hearing Virtual Fillable $t(shared.pdfNoticeSuffix)",
    noticeName_appealModifyDecision:
      "Modify Decision $t(shared.pdfNoticeSuffix)",
    noticeName_appealRFI: "Appeal RFI $t(shared.pdfNoticeSuffix)",
    noticeName_appealReturnedToAdjudication:
      "Appeal - Returned To Adjudication $t(shared.pdfNoticeSuffix)",
    noticeName_appealWithdrawn: "Appeal Withdrawn $t(shared.pdfNoticeSuffix)",
    noticeName_approvalNotice: "Approval notice $t(shared.pdfNoticeSuffix)",
    noticeName_approvalNoticeExplanationOfWages:
      "Approval notice $t(shared.pdfNoticeSuffix)",
    noticeName_approvalOfApplicationChange:
      "Approval of Application Change $t(shared.pdfNoticeSuffix)",
    noticeName_approvedLeaveDatesCancelled:
      "Approved Leave Dates Cancelled $t(shared.pdfNoticeSuffix)",
    noticeName_approvedTimeCancelled:
      "Approved Time Cancelled $t(shared.pdfNoticeSuffix)",
    noticeName_benefitAmountChangeNotice:
      "Benefit Amount Change Notice $t(shared.pdfNoticeSuffix)",
    noticeName_changeRequestApproved:
      "Change Request Approved $t(shared.pdfNoticeSuffix)",
    noticeName_changeRequestDenied:
      "Change Request Denied $t(shared.pdfNoticeSuffix)",
    noticeName_denialNotice: "Denial Notice $t(shared.pdfNoticeSuffix)",
    noticeName_denialNoticeExplanationOfWages:
      "Denial Notice $t(shared.pdfNoticeSuffix)",
    noticeName_denialOfApplication:
      "Denial of Application $t(shared.pdfNoticeSuffix)",
    noticeName_denialOfApplicationChange:
      "Denial of Application Change $t(shared.pdfNoticeSuffix)",
    noticeName_dismissalForFailureToAttendHearing:
      "Dismissal for Failure to Attend Hearing $t(shared.pdfNoticeSuffix)",
    noticeName_eftChangeRequest:
      "EFT Change Request $t(shared.pdfNoticeSuffix)",
    noticeName_explanationOfWages:
      "Explanation of Wages $t(shared.pdfNoticeSuffix)",
    noticeName_intermittentTimeApprovalNotice:
      "Intermittent Time Approved Notice $t(shared.pdfNoticeSuffix)",
    noticeName_intermittentTimeReported:
      "Intermittent Time Reported $t(shared.pdfNoticeSuffix)",
    noticeName_leaveAllotmentChangeNotice:
      "Leave Allotment Change Notice $t(shared.pdfNoticeSuffix)",
    noticeName_maximumWeeklyBenefitChangeNotice:
      "Maximum Weekly Benefit Change Notice $t(shared.pdfNoticeSuffix)",
    noticeName_noticeOfChildSupportWithholding:
      "Child Support $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullBalanceDemand:
      "Overpayment Notice - Full Balance Demand $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullBalanceRecovery:
      "Overpayment Notice - Full Balance Recovery $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullBalanceRecoveryManual:
      "Overpayment Notice - Full Balance Recovery - Manual $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandErBenefits:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandIntermittent:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandLeaveChange:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandPaidTimeOff:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandUi:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullDemandWorkersComp:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryErBenefits:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryIntermittent:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryLeaveChange:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryPaidTime_off:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryUi:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentFullRecoveryWorkersComp:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialBalance:
      "Overpayment Notice - Partial Recovery and Remaining Balance $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialDemandErBenefits:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialDemandIntermittent:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialDemandUi:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialDemandWorkersComp:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialLeaveChange:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPartialPaidTimeOff:
      "Overpayment Notice $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPaymentReceivedNewBalance:
      "Overpayment Payment Received $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPayoff:
      "Overpayment Paid in Full $t(shared.pdfNoticeSuffix)",
    noticeName_overpaymentPayoffNotice:
      "Overpayment Payoff Notice $t(shared.pdfNoticeSuffix)",
    noticeName_paymentReceivedUpdatedOverpaymentBalance:
      "Payment Received-Updated Overpayment Balance $t(shared.pdfNoticeSuffix)",
    noticeName_requestForInfoNotice:
      "Request for More Information $t(shared.pdfNoticeSuffix)",
    noticeName_w9TaxForm: "W9 Tax Form $t(shared.pdfNoticeSuffix)",
    noticeName_withdrawalNotice:
      "Pending Application Withdrawn $t(shared.pdfNoticeSuffix)",
  },
  dropdown: {
    emptyChoiceLabel: "- Select an answer -",
  },
  employerReviewForm: {
    confirmButtonLabel_add: "Confirm addition",
    confirmButtonLabel_amend: "Confirm amendment",
    destroyButtonLabel_add: "Cancel addition",
    destroyButtonLabel_amend: "Cancel amendment",
    heading_add_employerBenefits:
      "$t(shared.otherLeaveAndBenefits.addOtherLeavePlan)",
    heading_add_previousLeaves: "Add a previous leave",
    heading_amend_employerBenefits:
      "Amend an other disability, family, or medical leave plan",
    heading_amend_previousLeaves: "Amend previous leave",
  },
  employerReviewOtherLeavesAlert: {
    body: "Your employee's benefit amount and/or the amount of leave they're eligible to take may be reduced by leave taken outside of PFML, federal programs or other leave offered by an employer. Learn more about <benefit-reductions-link>how other leave and benefits affect payments.</benefit-reductions-link>",
    employeeTitle: "$t(shared.otherLeavesEmployeeReportedWarningTitle)",
    employerTitle: "$t(shared.otherLeavesEmployerReportingWarningTitle)",
    note: "$t(shared.ptoLegislation.note_v1)",
  },
  employerReviewPtoLegislationAlert: {
    body: "For applications filed prior to November 1, 2023:<ul><li>Report any Paid Time Off (PTO) your employee will use during this leave in the comment box at the bottom of this page.</li></li></ul>For applications filed on or after November 1, 2023: <ul><li>Employees' <strong>PTO used during PFML does not need to be reported</strong> as long as the combined total benefit amount is not greater than the Individual Average Weekly Wage (IAWW). </li><li>If an employee has already been paid their <strong>full wage</strong> through PTO, let us know in the comment box at the bottom of this page. Employers are responsible for any overpayments.</li></ul>",
    link: "$t(shared.ptoLegislation.link)",
    title: "Changes to Paid Time Off Reporting",
  },
  employerReviewTable: {
    dateRangeLabel_employerBenefits: "Dates wages are covered",
    dateRangeLabel_previousLeaves: "Previous leave",
    typeLabel_employerBenefits: "Benefit type",
    typeLabel_previousLeaves: "Leave type",
  },
  employerReviewTableRow: {
    addition: "Addition: ",
    amendment: "Amendment: ",
    benefitTypeValue_familyOrMedicalLeave: "Family or medical leave insurance",
    benefitTypeValue_imNotSure: "I'm not sure",
    benefitTypeValue_paidLeave: "Accrued paid leave",
    benefitTypeValue_permanentDisability: "Permanent disability insurance",
    benefitTypeValue_shortTermDisability: "Short-term disability insurance",
    benefitTypeValue_unknown: "Unknown",

    leaveReasonValue_activeDutyFamily:
      "Managing family affairs while a family member was on active duty in the armed forces",
    leaveReasonValue_bonding:
      "Bonding with their child after birth or placement",
    leaveReasonValue_care: "Caring for a family member",
    leaveReasonValue_medical: "Leave to manage their serious health condition",
    leaveReasonValue_pregnancy: "Pregnancy or recovery from childbirth",
    leaveReasonValue_serviceMemberFamily:
      "Caring for a family member who served in the armed forces",
    leaveReason_family: "Family leave",
    leaveReason_medical: "Medical leave",
    removing: "Removing: ",
    reported: "Reported: ",
  },
  employersAmendableEmployerBenefit: {
    benefitEndDateLabel:
      "What is the last day that this benefit will fully cover the employee’s wages?",
    benefitStartDateLabel:
      "What is the first day that this benefit will fully cover the employee’s wages?",
    benefitTypeLabel: "$t(shared.otherLeaveAndBenefits.typeLabel)",
    cancelAddition: "Cancel addition",
    choiceHint_benefitAmountDollars: "How much will your employee receive?",
    choiceHint_familyOrMedicalLeave:
      "$t(shared.choiceHint_familyOrMedicalLeave)",
    choiceHint_shortTermDisability: "$t(shared.choiceHint_shortTermDisability)",
    choiceLabel_familyOrMedicalLeave:
      "$t(shared.employerBenefitType_familyOrMedicalLeave)",
    choiceLabel_permanentDisability:
      "$t(shared.employerBenefitType_permanentDisability)",
    choiceLabel_shortTermDisability:
      "$t(shared.employerBenefitType_shortTermDisability)",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    destroyButtonLabel_add: "Cancel addition",
    destroyButtonLabel_amend: "Cancel amendment",
    heading_add: "$t(shared.otherLeaveAndBenefits.addOtherLeavePlan)",
    heading_amend: "Amend an other disability, family, or medical leave plan",
    hint: "This addition will get saved when you submit your review at the end of this page.",
    isFullSalaryContinuousHint:
      "This means that for the period of time your employee receives this benefit, it will pay them the same amount of money as their wages from while they're not on leave. If this benefit will pay them any other amount, select No.",
    isFullSalaryContinuousLabel:
      "Does this employer-sponsored benefit fully replace your employee's wages?",
    subtitle_add:
      "This addition will get saved when you submit your review at the end of the page.",
    subtitle_amend:
      "This amendment will get saved when you submit your review. To remove this benefit, include a comment at the end of the page.",
    title: "$t(shared.otherLeaveAndBenefits.addOtherLeavePlan)",
  },
  employersAmendablePreviousLeave: {
    addButton_first: "Add a previous leave",
    addButton_subsequent: "Add another previous leave",
    destroyButtonLabel_add: "Cancel addition",
    destroyButtonLabel_amend: "Cancel amendment",
    heading_add: "Add a previous leave",
    heading_amend: "Amend previous leave",
    leaveEndDateLabel: "When did the employee's leave end?",
    leaveReasonLabel: "Why did this employee need to take leave?",
    // these are similar to, but NOT exactly the same as claimsPreviousLeavesOtherReasonDetails
    leaveReasonValue_activeDutyFamily:
      "Managing family affairs while a family member was on active duty in the armed forces",
    leaveReasonValue_bonding:
      "Bonding with their child after birth or placement",
    leaveReasonValue_care: "Caring for a family member",
    leaveReasonValue_medical: "Leave to manage their serious health condition",
    leaveReasonValue_pregnancy: "Pregnancy or recovery from childbirth",
    leaveReasonValue_serviceMemberFamily:
      "Caring for a family member who served in the armed forces",
    leaveReason_family: "Family leave",
    leaveReason_medical: "Medical leave",
    leaveStartDateLabel: "When did the employee's leave start?",
    subtitle_add:
      "This addition will get saved when you submit your review at the end of this page.",
    subtitle_amend:
      "This amendment will get saved when you submit your review. To remove this leave, include a comment at the end of the page.",
  },
  employersCaringLeaveQuestion: {
    choiceNo: "No (comment required)",
    choiceUnknown: "I don't know",
    choiceYes: "Yes",
    commentHeading: "Tell us why you think this relationship is inaccurate.",
    familyMemberRelationshipHint:
      "This employee has attested the family member relationship is <eligible-relationship-link>a covered relationship</eligible-relationship-link>. If something on the form seems incorrect, add a comment at the end of the page.",
    familyMemberRelationshipLabel:
      "Do you believe the listed relationship is described accurately? (Optional)",
    inaccurateRelationshipAlertHeading:
      "The employee will be denied paid leave.",
    inaccurateRelationshipAlertLead:
      "We take allegations about false relationships seriously. Choosing No may trigger a denial of the employee's application for paid leave. The employee has the right to appeal if their application is denied.",
    unknownRelationshipAlertLead:
      "If you have not confirmed the accuracy of the family relationship the employee has attested to, please note that the Department of Family and Medical leave will adjudicate based on the attestation the employee made when submitting their application.",
  },
  employersCertsAndPeriods: {
    caringLeaveDocumentInstructions: "View the family relationship on page 3.",
    documentName: "Your employee's certification document.",
    documentationLabel: "Documentation",
    header: "Leave details",
    leaveDurationLabel: "Leave duration",
    // Fallback in case some unexpected leave reason is sent from Fineos:
    reasonHeading: "Leave periods",
    reasonHeading_activeDutyFamily: "$t(shared.leaveReasonActiveDutyFamily)",
    reasonHeading_bonding: "$t(shared.leaveReasonBonding)",
    reasonHeading_care: "$t(shared.leaveReasonCare)",
    reasonHeading_medical: "Leave to manage their serious health condition",
    reasonHeading_pregnancy: "$t(shared.leaveReasonPregnancy)",
    reasonHeading_serviceMemberFamily:
      "$t(shared.leaveReasonServiceMemberFamily)",
    recordkeepingInstructions:
      "To meet <mass-employer-role-link>federal laws and regulations</mass-employer-role-link>, you must keep medical records separate and confidential from any personnel files.",
  },
  employersEmployeeInformation: {
    addressLabel: "Mailing address",
    dobLabel: "Date of birth",
    employeeNameLabel: "Employee name",
    employerIdentifierLabel: "Employer ID number (EIN)",
    header: "Employee information",
    organizationNameLabel: "Organization",
    ssnOrItinLabel:
      "Social Security Number or Individual Taxpayer Identification Number",
  },
  employersEmployeeNotice: {
    choiceNo: "$t(shared.choiceNo) (explain below)",
    choiceYes: "$t(shared.choiceYes)",
    heading:
      "Did the employee give you at least 30 days notice about their leave?",
  },
  employersEmployerBenefits: {
    addButton_first_v1: "Add an insurance policy",
    addButton_first_v2: "Add a benefit",
    addButton_subsequent_v1: "Add another insurance policy",
    addButton_subsequent_v2: "Add another benefit",
    benefitTypeLabel: "Benefit type",
    caption:
      "Your employee reported the following benefits that will fully cover their wages during this leave. This includes temporary disability insurance, permanent disability insurance, and family or medical leave benefits such as a parental leave program.",
    dateRangeLabel: "Dates wages are covered",
    header: "Other disability, family, or medical leave plans",
    leaveEmployerWarning:
      "Reporting insurance policies may impact your employee’s benefits. Be sure this only includes benefits that fully cover your employee’s salary. If anything is reported in this section, it will result in the employee receiving $0 in PFML benefits for the dates reported.",
    tableName: "Other disability, family, or medical leave plan details",
  },
  employersEmployerDecision: {
    choiceApprove: "Approve",
    choiceDeny: "Deny (explain below)",
    explanation:
      "Answer based on your organization's policies and our <mass-employer-role-link>guidelines for employers</mass-employer-role-link>. Your recommendation is helpful to the Department in making its determination.",
    heading: "Have you approved or denied this leave request?",
  },
  employersFeedback: {
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes)",
    commentSolicitation: "Please tell us more.",
    commentSolicitation_employeeNotice:
      "Please tell us when your employee notified you about their leave.",
    commentSolicitation_employerDecision:
      "Please tell us why you denied this leave request.",
    commentSolicitation_fraud:
      "Please tell us why you believe this is fraudulent.",
    instructionsLabel: "Do you have any additional comments or concerns?",
  },
  employersFraudReport: {
    alertBody:
      "We take allegations about fraud seriously. Selecting this will begin further investigation. Please only select if you are convinced this is fraudulent. If you have questions, feel free to call us at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link> from 8:30 am to 4:30 pm ET.",
    alertHeading: "You are reporting fraud.",
    choiceNo: "$t(shared.choiceNo)",
    choiceYes: "$t(shared.choiceYes) (explain below)",
    detailsLabel: "What counts as paid leave fraud?",
    example_falseDocuments: "Providing forged or falsified documents",
    example_falseInfo:
      "Withholding information or providing false information to the Department of Family and Medical Leave",
    example_personalInfo:
      "Using someone’s personal information to fraudulently collect benefits",
    heading: "Do you have any reason to suspect this is fraud?",
  },
  employersNavigationTabs: {
    applications: "Applications",
    label: "Employer menu",
    organizations: "Organizations",
    welcome: "Welcome",
  },
  employersPaginationAbsencePeriodsTable: {
    claimDurationType_Continuous: "$t(shared.claimDurationTypeContinuous)",
    claimDurationType_Intermittent: "$t(shared.claimDurationTypeIntermittent)",
    "claimDurationType_Reduced Schedule":
      "$t(shared.claimDurationTypeReducedSchedule)",
    dateRangeLabel: "Date range",
    leaveFrequencyLabel: "Leave frequency",
    statusLabel: "Status",
  },
  employersPreviousLeaves: {
    addButton: "$t(shared.claimsPreviousLeaveDetails.addButton)",
    dateRangeLabel: "Previous leave",
    explanation:
      "<p>Verify the accuracy of your employee’s qualified leave dates. Any qualifying reason should only be reported if it was taken after {{other_reason_date}}.</p><p>This includes both paid leave (for example: paid vacation or sick days) and unpaid leave (for example: FMLA leave).</p>",
    explanationBody:
      "<p>Your employee must report any leave taken after {{day_year_before_leave_start}} for any qualifying reason. This includes both paid leave (such as PTO or sick days) and unpaid leave (such as FMLA). Do not include:</p>",
    explanation_differentDates:
      "<p>Verify the accuracy of your employee’s qualified leave dates. Leave to care for a family member should only be reported if it was taken after {{same_reason_date}}. Any other qualifying reason should only be reported if it was taken after {{other_reason_date}}. </p><p>This includes both paid leave (for example: paid vacation or sick days) and unpaid leave (for example: FMLA leave).</p>",
    header: "Previous leave",
    leaveEmployerWarning:
      "Reporting previous leave may reduce the amount of time your employee has available. Be sure this does not include leave provided by MA PFML.",
    leaveTypeLabel: "Leave type",
    pastApplications: "PTO that has been reported in past applications",
    previousLeave: "Previous PFML leave from past applications",
    previousLeaveListItem:
      "{{previous_leave_start_date}} to {{previous_leave_end_date}}",
    qualifyingReasonContent:
      "An employee or contractor can take paid or unpaid leave to:",
    qualifyingReasonDetailsLabel: "$t(shared.qualifyingReasonDetailsLabel)",
    qualifyingReason_activeDuty:
      "Manage family affairs when a family member is on active duty in the armed forces",
    qualifyingReason_bondWithChild:
      "Bond with a child after birth or placement",
    qualifyingReason_careForFamilyMedical:
      "Care for a family member with a <mass-benefits-guide-serious-health-condition-link>serious health condition</mass-benefits-guide-serious-health-condition-link>",
    qualifyingReason_careForFamilyMilitary:
      "Care for a family member who serves in the armed forces",
    qualifyingReason_manageHealth:
      "Manage a <mass-benefits-guide-serious-health-condition-link>serious health condition</mass-benefits-guide-serious-health-condition-link>, including illness, injury, inability to work during pregnancy, or recovery from birth",
    unqualifiedLeave:
      "Leave taken for something other than a qualifying reason (like vacation)",
  },
  // TODO (PFMLPB-15886) Remove FF for revising content for leave admins 90
  employersPtoLegislationAlert: {
    body: "$t(shared.ptoLegislation.body)",
    link: "$t(shared.ptoLegislation.link)",
    title: "$t(shared.ptoLegislation.title)",
  },
  employersPtoLegislationStatus: {
    body: "$t(shared.ptoLegislation.body)",
    example_v1:
      "Individual Average Weekly Wage - Weekly Benefit Amount = Maximum Supplemental Payment amount. For example: <ul><li>Mary has to take PFML leave for 12 weeks. Her Individual Average Weekly Wage is $1,300, and she receives a weekly benefit amount of $1,100 from PFML.</li><li>Mary’s employer can provide $200 of PTO ($1,300 - $1,100.)</li></ul>To see the employee's weekly benefit amount and Individual Average Weekly Wage, view their most recent approval or change notice.",
    example_v2:
      "Individual Average Weekly Wage - Weekly Benefit Amount = Maximum Supplemental Payment amount. For example: <ul><li>Mary has to take PFML leave for 12 weeks. Their Individual Average Weekly Wage is $1,300, and they receive a weekly benefit amount of $1,100 from PFML.</li><li>Mary’s employer can provide $200 of PTO ($1,300 - $1,100.)</li></ul>To see the employee's weekly benefit amount and Individual Average Weekly Wage, view their most recent approval or change notice.",
    link: "$t(shared.ptoLegislation.link)",
    subtitle: "How to calculate the maximum supplemental payment amount",
    tag: "NEW AS OF 11/01/2023",
    title: "$t(shared.ptoLegislation.title)",
  },
  employersReviewCheckbox: {
    body_employer_benefit_checkbox:
      "<p>If any of the other disability, family, or medical leave benefits information above doesn't match your records, make the necessary changes to your employee's data.</p>",
    body_previous_leave_checkbox:
      "<p>If any of the previous leave information above doesn't match your records, make the necessary changes to your employee's data.</p>",
    label_employer_benefit_checkbox:
      "<p><strong>Select this box to confirm you've reviewed the other disability, family, or medical leave benefits data.</strong></p>",
    label_previous_leave_checkbox:
      "<p><strong>Select this box to confirm you've reviewed the previous leave data.</strong></p>",
    title_employer_benefit_checkbox:
      "To the best of your ability, have you reviewed the other disability, family, or medical leave benefits data?",
    title_previous_leave_checkbox:
      "To the best of your ability, have you reviewed the previous leave data?",
  },
  employersWeeklyHoursWorkedRow: {
    heading_amend: "Amend weekly hours worked",
    leavePeriodDurationHint:
      "If their schedule varies, tell us the average number of hours worked over the past 52 weeks.",
    leavePeriodDurationLabel:
      "On average, how many hours does the employee work each week?",
    subtitle_amend:
      "This amendment will get saved when you submit your review.",
    weeklyHoursWorkedLabel: "Weekly hours worked",
  },
  errorBoundary: {
    message:
      "Sorry, we encountered an unexpected error. If this continues to happen, you may call the Paid Family Leave Contact Center at <contact-center-phone-link>$t(shared.contactCenterPhoneNumberNoBreak)</contact-center-phone-link>",
    reloadButton: "Reload this page",
  },
  errorsSummary: {
    genericHeading: "An error occurred",
    genericHeading_plural: "{{count}} errors occurred",
  },
  fieldsetAddress: {
    cityLabel: "City",
    line1Label_mailing: "Mailing address",
    line1Label_residential: "Address",
    line2Label_mailing: "Mailing address line 2",
    line2Label_residential: "Address line 2",
    stateLabel: "State",
    zipLabel: "ZIP",
  },
  fileCard: {
    remove: "Remove document",
    replace: "Replace document",
  },
  fileCardListItem: {
    fileCardHeading: "The file you selected:",
  },
  fileSizeDetails: {
    computerHeading: "On a desktop or laptop computer:",
    computerList:
      "<ul><li>Depending on your operating system, you will need to right-click, Ctrl+click, or tap with two fingers on the file.</li><li>In the menu that comes up when you click, select Properties or Get Info.</li></ul>",
    detailsHeading: "How do I find the size of my file?",
    phoneHeading: "If you have a photo on your phone:",
    phoneList:
      "<ul><li>On an Android phone, open the image menu and select Details.</li><li>On an iPhone, open the image, tap once, then click the info icon.</li></ul>",
  },
  fileUploadDetails: {
    attachmentHeading: "If your document is attached to an email:",
    attachmentList:
      "<ul><li>Open the file on your computer or phone</li><li>Save it as a PDF or image, and try uploading again</li></ul>",
    conversionHeading:
      "If your file is not in one of these formats, try one of the following:",
    conversionList:
      "<ul><li>Take a picture of the document</li><li>Take a screenshot of the document</li><li>Save the document as a PDF or image, and try uploading again</li></ul>",
    fileTypesHeading: "This website only accepts:",
    fileTypesList:
      "<ul><li>PDF documents</li><li>Images (.jpg, .jpeg, .png)</li></ul>",
    label: "Tips for uploading images or PDFs",
    sizeHeading: "If your image is larger than 4.5 MB:",
    sizeList:
      "<ul><li>If your camera settings allow, take a photo with a lower resolution</li><li>Reduce the image dimensions</li><li>Crop the image to remove extra space</li></ul>",
  },
  footer: {
    customerService_english:
      "Our Customer Service Representatives can answer your questions in <strong>English</strong>, <strong>Spanish</strong> and <strong>Portuguese</strong>. Translation services for up to <strong>240+ languages</strong> are also available to better serve employees, employers and medical providers.",
    dataSharingAgreement: "Data Sharing Agreement",
    description:
      "Paid Family and Medical Leave is a state-offered benefit for anyone who works in Massachusetts and is eligible to take up to 26 weeks of paid leave for medical or family reasons.",
    logoTitleDFML: "Go to DFML homepage",
    logoTitlePFML: "Go to PFML homepage",
    orgAddress: "PO Box 838 Lawrence, MA 01842",
    orgName: "Department of Family and Medical Leave (DFML)",
    orgPhoneNumber: "$t(shared.contactCenterPhoneNumber)",
    privacyPolicy: "Privacy$t(chars.nbsp)Policy",
    title: "Paid Family and Medical Leave (PFML)",
  },
  form: {
    continueButton: "$t(shared.saveAndContinue)",
    dateInputDayLabel: "Day",
    dateInputExample: "MM / DD / YYYY",
    dateInputMonthLabel: "Month",
    dateInputYearLabel: "Year",
    optional: "(optional)",
  },
  header: {
    appTitle: "Paid Family and Medical Leave",
    languageSelectionLinkText: "Languages",
    settingsLinkText: "Settings",
    skipToContent: "Skip to main content",
    taxDocumentsLinkText: "Tax Documents",
  },
  holidayAlert: {
    text: "Due to {{recentTodaysOrUpcoming}} banking holiday, payments may be delayed by one business day.",
  },
  idAlternativesAccordion: {
    content:
      "<p><strong>If you don’t have any of those, you can provide one of the following plus proof of your Social Security Number or Individual Tax Identification Number:</strong></p><ul><li>Valid, unexpired U.S. State or Territory License or ID, both front and back</li><li>Certified copy of a birth certificate filed with a State Office of Vital Statistics or equivalent agency in the individual’s state of birth. (You can only use a <puerto-rican-birth-certificate-link>Puerto Rican birth certificate</puerto-rican-birth-certificate-link> if it was issued on or after July 1, 2010.)</li><li>Certificate of Citizenship (Form N$t(chars.nbhyphen)560, or Form N$t(chars.nbhyphen)561)</li><li>Certificate of Naturalization (Form N$t(chars.nbhyphen)550 or N$t(chars.nbhyphen)570)</li></ul><p><strong>You can provide proof of your Social Security Number using one of the following documents displaying your complete Social Security Number:</strong></p><ul><li>Social Security card</li><li>W$t(chars.nbhyphen)2 Form</li><li>SSA$t(chars.nbhyphen)1099 Form</li><li>Non$t(chars.nbhyphen)SSA$t(chars.nbhyphen)1099 Form</li><li>Pay stub with your name on it</li></ul><p><identity-proof-link>Learn more about verifying your identity with different documents.</identity-proof-link></p>",
    heading: "If you don’t have any of those documents:",
  },
  idUploadPitfalls: {
    commonErrorsHeading:
      "These errors will lead to a denied application or another request to upload your ID:",
    commonErrorsList:
      "<ul><li>The document does not meet the ID requirements to receive Paid Family and Medical Leave benefits. A valid Massachusetts driver’s license or ID card meets the requirements, but there are other options. <identity-proof-link>Learn more about verifying your identity with different documents.</identity-proof-link></li><li>The ID is submitted in black and white.</li><li>A state ID is missing either the front or back.</li><li>An image of the ID is blurry. If the photo or information on the ID aren’t clear, it can’t be used to verify identity.</li></ul>",
    label: "Common errors in submitting ID documents",
  },
  inputFile: {
    loadingLabel_multiple: "Loading files",
    loadingLabel_single: "Loading file",
  },
  inputPassword: {
    toggleLabel: "Show password",
  },
  intermittentLeaveEpisodeStatusDefinitions: {
    approvedStatus: "Approved",
    approvedStatusBody:
      "These hours were approved. After the 7-day waiting period that follows your first reported day of leave. View <payments-page-link>payment status</payments-page-link> for more details. Payments are processed to be paid each week of leave on Mondays, or on Tuesday if Monday is a holiday.",
    approvedStatusLabel: "Approved next steps",
    deniedStatus: "Denied",
    deniedStatusBody:
      "If you reported leave time and were denied, it could be for a few reasons. You can go online to <appeals-page-link> appeal DFML’s decision on your submitted hours.</appeals-page-link>",
    deniedStatusLabel: "Denied next steps",
    heading: "What comes next for each status",
    pendingReviewStatus: "Pending Review",
    pendingReviewStatusBody:
      "DFML needs to review these hours. We normally review reported hours within 3-5 business days. You will receive a notice when we make a decision to approve or deny your hours.",
    pendingReviewStatusLabel: "Pending next steps",
  },
  irs1099Alert: {
    heading: "1099-G tax forms are being prepared",
    learnMoreAboutTaxesLinkText: "Learn more about taxes on PFML benefits.",
    requiredToIssue:
      "If you received Paid Family Medical Leave payments or credits, we are required to issue you a 1099-G tax form.",
    taxProfessional:
      "How you report this income for tax purposes is up to you, or you can",
    taxProfessionalConclusion: "to help with your taxes.",
    taxProfessionalLinkText: "find a tax professional",
    willMailForm:
      "The Department of Family and Medical Leave will mail your 1099-G tax form by <strong>January 31st {{mailDateYear}}</strong>.",
  },
  leaveDatesAlert: {
    heading: "Your leave dates for paid leave from PFML",
    leaveDatesHeading: "Your 7-day waiting period dates",
  },
  leaveDatesInEachBenefitYear: {
    currentBenefitYear:
      "<p><strong>Your current benefit year:</strong> <benefitYearDates /></p><ul><li><strong>Your leave dates:</strong> <leaveDates /></li></ul>",
    currentBenefitYearWithWaitingDayPeriod:
      "<p><strong>Your current benefit year:</strong> <benefitYearDates /></p><ul><li><strong>Your leave dates:</strong> <leaveDates /></li><li><strong>Your 7-day waiting period:</strong> <waitingPeriodDates /></li></ul>",
    newBenefitYear:
      "<p><strong>Your new benefit year:</strong> <benefitYearDates /></p><ul><li><strong>Your leave dates:</strong> <leaveDates /></li></ul></p>",
    newBenefitYearWithWaitingDayPeriod:
      "<p><strong>Your new benefit year:</strong> <benefitYearDates /></p><ul><li><strong>Your leave dates:</strong> <leaveDates /></li><li><strong>Your anticipated 7-day waiting period:</strong> <waitingPeriodDates /></li></ul>",
  },
  leaveSchedules: {
    title:
      "{{duration}} {{durationBasis, raw}} of leave {{frequency}} {{frequencyBasis, raw}} per {{frequencyIntervalBasis, raw}} from {{startDate, dateFull}} to {{endDate, dateFull}}",
  },
  logInLink: {
    text: "<strong>Have an account?</strong> <log-in-link>Log in</log-in-link>",
  },
  maintenanceAlertBar: {
    message_withEndTime:
      "We will be performing some maintenance on our system from <strong>{{start}}</strong> to <strong>{{end}}</strong>.",
    message:
      "We will be performing some maintenance on our system starting <strong>{{start}}</strong>.",
  },
  maintenanceTakeover: {
    noSchedule:
      "We’re performing some maintenance on our system and some features may currently be unavailable. Please check back in a few hours.",
    scheduledWithEndAndNoStart:
      "We're performing some maintenance on our system, so you can't log in or work on any applications right now. The system will be back online and available on <strong>{{end}}</strong>.",
    scheduledWithStartAndEnd:
      "We're performing some maintenance on our system, so you can't log in or work on any applications right now. The system will be offline from <strong>{{start}}</strong> to <strong>{{end}}</strong>.",
    title: "We’re undergoing maintenance",
  },
  massIdUploadControls: {
    backChangeText: "Remove back",
    backInputButtonText: "Choose back",
    frontChangeText: "Remove front",
    frontInputButtonText: "Choose front",
    idBackHeading: "Upload the back of your ID",
    idFrontHeading: "Upload the front of your ID",
  },
  mfaSetupSuccessAlert: {
    heading: "Phone number confirmed",
    message:
      "To protect your security, we'll send you a 6-digit code when you log in to verify it's really you.",
  },
  multipleFileCertificationUploadControl: {
    choosePage: "Choose page {{number}}",
    fileCardHeading: "The file you selected:",
    pageNumber: "Page {{number}}",
    removePage: "Remove page {{number}}",
    replacePage: "Replace page {{number}}",
  },
  multipleFileCertificationUploadControlGroup: {
    addPage: "Add another page",
    directions:
      "Upload all the pages of your certification document that you completed, as well as all the pages the health care provider completed.",
    heading: "New documents you’re uploading",
    switchMethod: "Upload one complete document instead",
  },
  multipleInputFileGroup: {
    additionalFilesButtonText: "Choose additional files",
    fileCardListHeading: "New documents you’re uploading",
    initialFilesButtonText: "Choose files",
  },
  noOrganizationsAlert: {
    gainAccess:
      "The easiest way to gain access is to ask an existing leave administrator to add you to their organization.",
    heading: "Get access to your organization(s)",
    learnMore:
      "Learn more about <add-organization-link>how to add an organization.</add-organization-link>",
    noLeaveAdministrators:
      "If your organization does not have any leave administrators, or if you need to add yourself, you’ll need to get information from your <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> account or work with the person who manages that account to complete the process.",
  },
  pageNotFound: {
    body: "<p>The page you’re looking for might have been removed, have a new name, or is otherwise unavailable.</p><p>If you typed the URL directly, check your spelling and capitalization. Our URLs look like this: <url-example>{{ url }}</url-example></p>",
    homepageButton: "Visit homepage",
    title: "Page not found",
  },
  pagination: {
    nextLabel: "Next",
    previousLabel: "Previous",
    summary:
      "Viewing {{firstRecordNumber}} to {{lastRecordNumber}} of {{totalRecords}} results",
    summary_empty: "0 results",
  },
  previousDocumentList: {
    explanation_appeal:
      "This includes any supporting documents you have previously uploaded for this appeal.",
    explanation_certification:
      "This includes any certifications or other documents you have previously uploaded for this application.",
    explanation_massId:
      "This includes any IDs you have previously uploaded for this application.",
    explanation_otherId:
      "This includes any proofing documents you have previously uploaded for this application.",
    header: "Previous documents",
  },
  previousDocumentListItem: {
    documentNumber: "Document {{number}}",
    uploadDate: "Date of upload: {{date}}",
  },
  signUp: {
    createAccountButton: "Create an account to apply for paid leave",
    haveAnAccountFooterLabel: "Have an account?",
    passwordHint: "$t(shared.passwordHint)",
    passwordLabel: "$t(shared.passwordLabel)",
    signInFooterLink: "Log in",
    title: "Create an account",
    usernameLabel: "$t(shared.usernameLabel)",
  },
  singleFileCertificationUploadControls: {
    heading: "New document you’re uploading",
    inputButtonText: "Choose file",
    switchMethod: "Upload individual pages instead",
  },
  singleInputFileGroup: {
    fileCardHeading: "The file you selected:",
  },
  siteLogo: {
    dfmlAlt: "Department of Family and Medical Leave logo",
    pfmlAlt: "Paid Family and Medical Leave program logo",
    sealAlt: "Massachusetts State Seal",
  },
  spinner: {
    label: "Loading",
  },
  statusItems: {
    actionRequired: "Action required: ",
    completed: "Completed: ",
    inProgress: "In progress: ",
    notStarted: "Next: ",
  },
  statusNavigationTabs: {
    payments: "Payments",
    reportIntermittentLeave: "Report Leave",
    reportIntermittentLeaveV2: "View and report leave hours",
    statusDetails: "Application",
  },
  submittedChangeRequestList: {
    body: "Submitted on {{submittedDate}}",
    description_applicationWithdrawal: "Withdraw application",
    description_cancellation: "End leave early on {{changeEndDate}}",
    description_extension: "Extend leave to {{changeEndDate}}",
    description_medicalToBonding:
      "Add leave to bond with a child from {{changeStartDate}} to {{changeEndDate}}",
    description_withdrawal: "Cancel entire leave",
    label: "Change requests you've submitted",
  },
  tag: {
    state_approved: "Approved",
  },
  taxDocuments: {
    taxDocumentsAvailableBanner:
      "You can now download your Paid Family and Medical Leave {{previousYear}} 1099-G tax form. Learn more about <tax-documents-link>Tax Documents</tax-documents-link>.",
  },
  unsupportedBrowserBanner: {
    message:
      "You are using {{browser}} {{version}}, which is no longer supported by {{vendor}}. <update-link>Upgrading your browser</update-link> is more secure, and it may also improve your experience on this website.",
    oldMessage:
      "<strong>Your browser is out of date</strong>. Please <update-link>update your browser</update-link> for more security, speed and the best experience on our site.",
  },
  unverifiedOrganizationsAlert: {
    askAnAdmin:
      "The easiest way to verify access is to ask an existing leave administrator to add you to their organization.",
    body: "Leave administrators must have verified access to each of their organizations. To manage leave, you$t(chars.nbsp)must <org-verification>complete the verification process</org-verification> or be added to the organization by another leave$t(chars.nbsp)administrator.",
    learnAdmin:
      "Learn <learn-how-link>how to add/remove leave administrators</learn-how-link>.",
    learnVerification:
      "Learn how to complete the <verification-link>verification process.</verification-link>",
    noAdmin:
      "If the organization does not already have a leave administrator, or you need to add yourself, go to <organizations-link>Organizations</organizations-link> and select the organization to complete verification. You’ll need to get information from your <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> account or work with the person who manages that account to complete this step.",
    noAdmin_organizations:
      "If your organization does not have a leave administrator, or if you need to add yourself, select the organization below to complete verification. You’ll need to get information from your <mass-tax-connect-link>MassTaxConnect</mass-tax-connect-link> account or work with the person who manages that account to complete this step.",
    title: "You need to finish verifying access to one of your organizations",
  },
  updateLeaveDatesBanner: {
    updateLeaveDatesAlertBody:
      "If your delivery date differs from your due date, your requested leave dates will be updated to match the delivery date on your proof of birth documentation. DFML will review the adjusted leave dates when processing your application.",
    updateLeaveDatesAlertTitle: "DFML will update your leave dates",
  },
  uploadCertificationMethodPage: {
    buttonLoadingMessage:
      "Confirming your selection… Do not refresh or go back.",
    continueButtonLabel: "Save and continue",
    title: "Upload certification",
  },
  weeklyTimeTable: {
    dayHeader: "Day",
    day_Friday: "$t(shared.day_Friday)",
    day_Monday: "$t(shared.day_Monday)",
    day_Saturday: "$t(shared.day_Saturday)",
    day_Sunday: "$t(shared.day_Sunday)",
    day_Thursday: "$t(shared.day_Thursday)",
    day_Tuesday: "$t(shared.day_Tuesday)",
    day_Wednesday: "$t(shared.day_Wednesday)",
    hoursHeader: "Hours",
    time: "{{minutes, hoursMinutesDuration}}",
  },
  withAppeal: {
    loadingLabel: "Loading appeal",
  },
  withBenefitsApplications: {
    loadingLabel: "Loading applications",
  },
  withChangeRequests: {
    loadingLabel: "Loading requests",
  },
  withClaimDocuments: {
    loadingLabel: "$t(shared.loadingDocumentsLabel)",
  },
  withClaims: {
    loadingLabel: "Loading claims",
  },
  withEmployerClaim: {
    loadingLabel: "Loading claim",
  },
  withIntermittentLeaveSchedule: {
    loadingLabel: "Loading leave schedule",
  },
  withLeaveAdmins: {
    loadingLabel: "Loading organizations",
  },
  withPayments: {
    loadingLabel: "Loading payments",
  },
  withTaxDocuments: {
    loadingLabel: "$t(shared.loadingDocumentsLabel)",
  },
  withUser: {
    loadingLabel: "Loading account",
  },
};

const englishLocale = {
  translation: Object.assign(
    {},
    { chars, components, data, errors, pages, shared }
  ),
};

export default englishLocale;

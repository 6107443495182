import BaseApi from "./BaseApi";
import RiskAssessment from "../models/RiskAssessment";
import { isFeatureEnabled } from "../services/featureFlags";
import routes from "../routes";

export default class FraudProtectionApi extends BaseApi {
  get basePath() {
    return routes.api.fraudProtection;
  }

  /**
   * Figure out which feature flag headers to send to the back-end based on which feature flags
   * are enabled on the front-end.
   */
  get featureFlagHeaders() {
    const headers: { "X-FF-ENABLE-FRAUD-PROTECTION"?: string } = {};

    return headers;
  }

  get namespace() {
    return "fraud-protection";
  }

  /**
   * Conduct Fraud Protection Check
   * @param requestData - Fraud Protection Check fields
   * This function will intentionally swallow any errors returned from the Fraud Protection API.
   * This is the desired functionality during Phase 0 to prevent impacts to end users.
   */
  assessRisk = async (requestData: { [key: string]: unknown }) => {
    const enableFraudProtection = isFeatureEnabled("enableFraudProtection");
    const enableDFPRejectAction = isFeatureEnabled("enableDFPRejectAction");

    const getFeatureFlagHeaders = () => ({
      "X-FF-ENABLE-FRAUD-PROTECTION": enableFraudProtection.toString(),
      "X-FF-ENABLE-DFP-REJECT-ACTION": enableDFPRejectAction.toString(),
    });

    try {
      const { data } = await this.request<RiskAssessment>(
        "POST",
        "",
        requestData,
        {
          excludeAuthHeader: true,
          additionalHeaders: getFeatureFlagHeaders(),
        }
      );

      return {
        fraud: new RiskAssessment(data),
      };
    } catch (error) {
      // TODO (PFMLPB-13190): Implement Reject
      // Intentionally swallowing errors for now to prevent end user impact while in data collection mode (Phase 0)
    }
  };
}

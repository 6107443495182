import React from "react";

export interface ExternalLinkProps {
  readonly children?: React.ReactNode;
  readonly href: string;
}

export default function ExternalLink(props: ExternalLinkProps) {
  return (
    <a href={props.href} rel="noopener noreferrer" target="_blank">
      {props.children}
    </a>
  );
}

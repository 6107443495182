/* eslint sort-keys: ["error", "asc"] */

class RiskAssessment {
  device_context_id: string;
  email_address: string;
  ip_address: string;
  locale: string;
  user_id: string;
  first_name: string;
  last_name: string;
  display_name: string;
  language: string;
  decision: string;
  login_id: string;
  sign_up_id: string;

  constructor(attrs: Partial<RiskAssessment>) {
    Object.assign(this, attrs);
  }
}

export const FraudProtectionType = {
  createAccount: "CREATE_ACCOUNT",
  createAccountStatus: "CREATE_ACCOUNT_STATUS",
  loginAccount: "LOGIN_ACCOUNT",
  loginAccountStatus: "LOGIN_ACCOUNT_STATUS",
} as const;

export const FraudDecisionType = {
  approve: "Approve",
  reject: "Reject",
};

export default RiskAssessment;

import {
  BenefitsApplicationDocument,
  ClaimDocument,
  getLegalNotices,
} from "../models/Document";

import DocumentList from "./DocumentList";
import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../locales/i18n";

interface LegalNoticeListProps {
  documents: Array<BenefitsApplicationDocument | ClaimDocument>;
  onDownloadClick: (
    document: BenefitsApplicationDocument
  ) => Promise<Blob | undefined>;
}

/**
 * Legal notices list and content
 */
export default function LegalNoticeList(props: LegalNoticeListProps) {
  const { t } = useTranslation();
  const { documents, onDownloadClick } = props;

  const legalNotices = getLegalNotices(documents);
  /**
   * If application is not submitted or has
   * no documents, don't display section
   */
  if (!legalNotices.length) return null;

  return (
    <React.Fragment>
      <p className="padding-bottom-2 margin-top-05">
        <Trans
          i18nKey="components.applicationCard.noticeOnClickDetails"
          components={{
            "contact-center-phone-link": (
              <a href={`tel:${t("shared.contactCenterPhoneNumber")}`} />
            ),
          }}
        />
      </p>
      <DocumentList
        documents={legalNotices}
        downloadBenefitsApplicationDocument={onDownloadClick}
        showCreatedAt
      ></DocumentList>
    </React.Fragment>
  );
}

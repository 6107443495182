import ApiResourceCollection from "src/models/ApiResourceCollection";
import DevicesApi from "src/api/DevicesApi";
import { ErrorsLogic } from "./useErrorsLogic";
import { UserDevice } from "src/models/User";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useDevicesLogic = ({ errorsLogic }: { errorsLogic: ErrorsLogic }) => {
  const devicesApi = new DevicesApi();
  const [isLoadingDevices, setIsLoading] = useState(false);

  const { collection: devices, setCollection: setDevices } = useCollectionState(
    new ApiResourceCollection<UserDevice>("device_key")
  );

  const loadDevices = async () => {
    if (isLoadingDevices) return;
    setIsLoading(true);
    errorsLogic.clearErrors();
    try {
      const { devices } = await devicesApi.list();
      setDevices(new ApiResourceCollection<UserDevice>("device_key", devices));
      setIsLoading(false);
    } catch (error) {
      errorsLogic.catchError(error);
      setIsLoading(false);
    }
  };

  const removeDevice = async (deviceKey: string) => {
    errorsLogic.clearErrors();

    try {
      const { devices } = await devicesApi.remove(deviceKey);
      setDevices(new ApiResourceCollection<UserDevice>("device_key", devices));
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  return {
    loadDevices,
    isLoadingDevices,
    devices,
    removeDevice,
  };
};

export default useDevicesLogic;

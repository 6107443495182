import {
  getMfaValidationErrors,
  setMFAPreference,
  updateMFAPhoneNumber,
} from "src/services/mfa";
import routes, { isApplicationsRoute, isEmployersRoute } from "../routes";
import { useMemo, useState } from "react";

import ChangeEmailApi from "../api/ChangeEmailApi";
import { ErrorsLogic } from "./useErrorsLogic";
import { PortalFlow } from "./usePortalFlow";
import RolesApi from "../api/RolesApi";
import User from "../models/User";
import UsersApi from "../api/UsersApi";
import { ValidationError } from "../errors";
import combineValidationIssues from "src/utils/combineValidationIssues";
import tracker from "../services/tracker";
import validateUsername from "../utils/validateUsername";

/**
 * Hook that defines user state
 */
const useUsersLogic = ({
  errorsLogic,
  isLoggedIn,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  isLoggedIn: boolean;
  portalFlow: PortalFlow;
}) => {
  const usersApi = useMemo(() => new UsersApi(), []);
  const rolesApi = useMemo(() => new RolesApi(), []);
  const changeEmailApi = useMemo(() => new ChangeEmailApi(), []);
  const [user, setUser] = useState<User>();

  /**
   * Update user through a PATCH request to /users
   * @param user_id - ID of user being updated
   * @param patchData - User fields to update
   */
  const updateUser = async (
    user_id: User["user_id"],
    patchData: Partial<User>
  ) => {
    errorsLogic.clearErrors();

    try {
      // Extract mfa related pieces
      const { mfa_delivery_preference, mfa_phone_number } = patchData;
      // Before triggering backend validations and updating the user, do mfa service level validation
      if (mfa_phone_number) {
        getMfaValidationErrors(mfa_phone_number?.phone_number);
      }
      // Get api validation errors and update the user
      const { user } = await usersApi.updateUser(user_id, patchData);

      // Update Cognito
      if (mfa_delivery_preference) {
        await setMFAPreference(mfa_delivery_preference);
      }
      if (mfa_phone_number?.phone_number)
        await updateMFAPhoneNumber(mfa_phone_number.phone_number);
      // Change internal state
      setUser(user);
      // Return the user only if the update did not throw any errors
      return user;
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const accountStatus = async (email_id: string) => {
    errorsLogic.clearErrors();
    const trimmedUsername = email_id.trim();

    const validationIssues = combineValidationIssues(
      validateUsername(trimmedUsername)
    );

    if (validationIssues) {
      errorsLogic.catchError(new ValidationError(validationIssues));
      return;
    }

    const status = await usersApi.accountStatus(trimmedUsername);

    return status;
  };

  /**
   * Fetch the current user through a PATCH request to users/current
   * and add user to application's state
   */
  const loadUser = async () => {
    if (!isLoggedIn) {
      throw new Error("Cannot load user before logging in to Cognito");
    }
    // Caching logic: if user has already been loaded, just reuse the cached user
    if (user) return;

    errorsLogic.clearErrors();
    try {
      const { user } = await usersApi.getCurrentUser();
      setUser(user);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Enforce that a user has the necessary data and role(s) to view the target route.
   * Users lacking data or permissions get redirected to a route to fix the issue.
   */
  const requireCompleteUserWithPermissions = () => {
    checkAndRedirectForIncompleteUser();
    checkAndRedirectBasedOnRole();
  };

  /**
   * Redirect user if their account is missing required fields.
   */
  const checkAndRedirectForIncompleteUser = () => {
    if (!user) throw new Error("User not loaded");
    let redirectTo: string | undefined;
    const redirects = {
      consent: routes.user.consentToDataSharing,
      contactInfo: routes.user.contactInfo,
      oauthNotice: routes.auth.oAuthNotice,
    };

    // Avoid an endless loop by checking if the user is already on the route they need to be on
    if (Object.values(redirects).includes(portalFlow.pathname)) return;

    if (!user.consented_to_data_sharing) {
      redirectTo = redirects.consent;
    } else if (user.hasEmployerRole && !user.first_name && !user.last_name) {
      redirectTo = redirects.contactInfo;
    }

    if (redirectTo) {
      portalFlow.goTo(redirectTo, {}, { redirect: true });
    }
  };

  /**
   * Redirect to Employer Portal if role is employer and current page is claims route
   * redirect to Claimant Portal if role is not employer and current page is employers route
   */
  const checkAndRedirectBasedOnRole = () => {
    //  Allow roles to view data sharing consent page
    const pathname = portalFlow.pathname;
    if (pathname === routes.user.consentToDataSharing) return;
    let redirectTo: string | undefined;

    if (!user) {
      redirectTo = routes.index;
    } else if (!user.hasEmployerRole && isEmployersRoute(pathname)) {
      // Portal currently does not support hybrid account (both Employer AND Claimant account)
      // If user has Employer role, they cannot access Claimant Portal regardless of multiple roles
      redirectTo = routes.applications.index;
    } else if (user.hasEmployerRole && isApplicationsRoute(pathname)) {
      // Inverse of the above. Employers shouldn't have access to Claimant-only flows.
      redirectTo = routes.employers.welcome;
    }

    if (redirectTo) {
      portalFlow.goTo(redirectTo, {}, { redirect: true });
    }
  };

  /**
   * Convert user role through a POST request to /users/{user_id}/convert-employer
   * @param user_id - ID of user being converted
   * @param postData - User fields to update - role and leave admin
   */
  const convertUserToEmployer = async (
    user_id: User["user_id"],
    postData: { employer_fein: string }
  ) => {
    errorsLogic.clearErrors();

    try {
      const { user } = await usersApi.convertUserToEmployer(user_id, postData);
      portalFlow.goTo(
        routes.employers.organizations,
        {
          account_converted: "true",
        },
        { redirect: true }
      );
      setUser(user);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Delete the user's employer role and leave admin associations through a DELETE to /roles
   * @param user_id - ID of user being converted
   * @param postData - User fields to update - role and leave admin
   */
  const convertUserToEmployee = async (user_id: User["user_id"]) => {
    errorsLogic.clearErrors();

    try {
      await rolesApi.deleteEmployerRole(user_id);
      const { user } = await usersApi.getCurrentUser();
      portalFlow.goTo(
        routes.applications.getReady,
        {
          account_converted: "true",
        },
        { redirect: true }
      );
      setUser(user);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  /**
   * Send the current user's new email address through a POST request to change-email/requests
   * @param email_address - requested new Email for the user
   * @param resend - boolean to determine if this is a first time code request or a requested resend of code
   */
  const changeUserEmail = async (email_address: string, resend?: boolean) => {
    errorsLogic.clearErrors();
    const trimmedEmail = email_address.trim();

    if (!isLoggedIn) {
      portalFlow.getRouteFor("REAUTH", undefined, {
        next: portalFlow.pageRoute,
      });
    }

    try {
      const { user } = await changeEmailApi.changeEmail(trimmedEmail);
      tracker.trackEvent("Change Email - Requested email change", {
        mfaEnabled: user.mfa_delivery_preference !== "SMS" ? "false" : "true",
      });
      if (!resend) {
        portalFlow.goToNextPage({}, { new_email: email_address });
      }
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };
  /**
   * Send the current user's verification code sent to new email address POST request to change-email/verification
   * @param verification_code - requested new Email code for the user
   */
  const verfyNewUserEmail = async (verification_code: string) => {
    errorsLogic.clearErrors();

    try {
      const { user } = await changeEmailApi.verifyEmail(verification_code);
      setUser(user);
      tracker.trackEvent("Change Email - Verified email change", {
        mfaEnabled: user.mfa_delivery_preference !== "SMS" ? "false" : "true",
      });
      portalFlow.goToNextPage({}, { emailCodeConfirmed: "true" });
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  return {
    accountStatus,
    convertUserToEmployer,
    convertUserToEmployee,
    user,
    updateUser,
    loadUser,
    requireCompleteUserWithPermissions,
    setUser,
    changeUserEmail,
    verfyNewUserEmail,
  };
};

export default useUsersLogic;
export type UsersLogic = ReturnType<typeof useUsersLogic>;

import BaseApi from "./BaseApi";
import User from "../models/User";
import routes from "../routes";

export default class ChangeEmailApi extends BaseApi {
  get basePath() {
    return routes.api.changeEmail;
  }

  get namespace() {
    return "change_email";
  }

  changeEmail = async (email_address: string) => {
    const { data } = await this.request<User>("POST", "/requests", {
      email_address,
    });
    return Promise.resolve({
      user: new User({ ...data }),
    });
  };

  verifyEmail = async (verification_code: string) => {
    const { data } = await this.request<User>("POST", "/verifications", {
      verification_code,
    });

    return Promise.resolve({
      user: new User({ ...data }),
    });
  };
}

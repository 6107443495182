/**
 * @file Configuration for building an xstate state machine for routing
 * @see https://xstate.js.org/docs/about/concepts.html#finite-state-machines
 * Each state represents a page in the portal application, keyed by the
 * page's url route. The CONTINUE transition represents the next page in the
 * the auth flow.
 */

import routes from "../routes";

export default {
  states: {
    [routes.index]: {
      on: {
        LOGGED_IN: routes.applications.index,
      },
    },
    [routes.auth.login]: {
      on: {},
    },
    [routes.auth.oAuthError]: {
      on: {},
    },
    [routes.auth.oAuthNotice]: {
      on: {
        CONTINUE: routes.index,
      },
    },
    [routes.auth.oAuthLaunch]: {
      on: {
        LOGIN: routes.auth.oAuthStart,
      },
    },
    [routes.auth.oAuthStart]: {
      on: {},
    },
    [routes.auth.oAuthReturn]: {
      on: {
        CONTINUE: routes.index,
        LOGIN: routes.auth.oAuthStart,
        NOTICE: routes.auth.oAuthNotice,
        SETTINGS: routes.user.settings,
        ERROR: routes.auth.oAuthError,
      },
    },
    [routes.twoFactor.smsVerify]: {
      on: {
        CONTINUE: routes.applications.index,
        LOG_IN: routes.auth.oAuthStart,
        NO_USER: routes.auth.oAuthStart,
      },
    },
    [routes.twoFactor.smsIndex]: {
      on: {
        CONTINUE: routes.applications.index,
        EDIT_MFA_PHONE: routes.twoFactor.smsSetup,
      },
    },
    [routes.twoFactor.smsSetup]: {
      on: {
        CONTINUE: routes.twoFactor.smsConfirm,
      },
    },
    [routes.twoFactor.smsConfirm]: {
      on: {
        CONTINUE: routes.applications.index,
        RETURN_TO_SETTINGS: routes.user.settings,
      },
    },
  },
};

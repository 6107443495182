import {
  AddressFormatResponse,
  AddressResponse,
  Addresses,
} from "../../models/Experian";
import React, { useEffect, useState } from "react";
import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import AddressModel from "../../models/Address";
import ConditionalContent from "../../components/ConditionalContent";
import FieldsetAddress from "../../components/FieldsetAddress";
import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import QuestionPage from "../../components/QuestionPage";
import { isFeatureEnabled } from "src/services/featureFlags";
import { pick } from "lodash";
import routes from "../../routes";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = [
  "claim.has_mailing_address",
  "claim.residential_address.line_1",
  "claim.residential_address.line_2",
  "claim.residential_address.city",
  "claim.residential_address.state",
  "claim.residential_address.zip",
  // Include `mailing_address` so validation error shows for completely empty mailing address.
  // We don't need this for `residential_address` since that defaults to a blank object, rather than null.
  "claim.mailing_address",
  "claim.mailing_address.line_1",
  "claim.mailing_address.line_2",
  "claim.mailing_address.city",
  "claim.mailing_address.sate",
  "claim.mailing_address.zip",
];

export const Address = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const { formState, getField, updateFields, clearField } = useFormState(
    pick(props, fields).claim
  );

  const [backButtonLabel, setBackButtonLabel] = useState<string>("Back");
  const [backButtonLink, setBackButtonLink] = useState<string>("");
  const [submitButtonLabel, setSubmitButtonLabel] = useState<string>(
    t("pages.claimsAddress.validateAddress")
  );
  const [defaultAddressSelectionLabel, setDefaultAddressSelectionLabel] =
    useState<string>(t("pages.claimsAddress.defaultAddressWithResults"));
  const [isReadyToSelectAddress, setIsReadyToSelectAddress] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState<Addresses[]>([]);
  const [selectedAddressKey, setSelectedAddressKey] = useState<string>("");
  const [useDefaultAddress, setUseDefaultAddress] = useState<boolean>(false);

  const { has_mailing_address } = formState;

  useEffect(() => {
    const existingMailingAddress = formState.mailing_address;
    if (formState.has_mailing_address && !existingMailingAddress) {
      updateFields({ mailing_address: {} });
    }
  }, [formState, updateFields]);

  const handleSave = async () => {
    if (isFeatureEnabled("enableAddressValidation")) {
      try {
        if (useDefaultAddress) {
          // User decided to continue with entered address, no need of Address Format
          setIsAddressVerified(true);
        } else if (selectedAddressKey) {
          // User selected an address from the list
          const addressFormatResponse: AddressFormatResponse | undefined =
            await appLogic.benefitsApplications.addressFormat(
              selectedAddressKey
            );

          if (addressFormatResponse) {
            const formattedAddress = addressFormatResponse.address;

            updateFields({
              "residential_address.line_1": formattedAddress.address_line_1,
              "residential_address.line_2": formattedAddress.address_line_2,
              "residential_address.city": formattedAddress.locality,
              "residential_address.state": formattedAddress.region,
              "residential_address.zip": formattedAddress.postal_code,
            });

            setIsAddressVerified(true);
          }
        } else if (!isAddressVerified) {
          // If address is not yet verified
          const { line_1, line_2, city, state, zip } =
            formState.residential_address;
          const fullAddressString = `${line_1}, ${line_2}, ${city}, ${state} ${zip}`;

          // Call address search API
          const addressResponse: AddressResponse | undefined =
            await appLogic.benefitsApplications.addressSearch(
              fullAddressString
            );

          if (addressResponse && addressResponse.addresses.length > 0) {
            setAvailableAddresses(addressResponse.addresses);
          } else {
            setAvailableAddresses([]);
            setDefaultAddressSelectionLabel(
              t("pages.claimsAddress.defaultAddressWithoutResults")
            );
            console.error("No addresses returned from the search.");
          }

          setIsReadyToSelectAddress(true);
          setBackButtonLink(
            `${routes.applications.address}?claim_id=${claim.application_id}`
          );
          setBackButtonLabel("Refine Search");
          setSubmitButtonLabel(t("components.form.continueButton"));
        } else {
          // Continue with next step after address verification

          await appLogic.benefitsApplications.update(
            claim.application_id,
            formState
          );
        }
      } catch (error) {
        console.error("Error in address verification or update:", error);
      }
    } else {
      appLogic.benefitsApplications.update(claim.application_id, formState);
    }
  };

  useEffect(() => {
    const updateForm = async () => {
      if (isAddressVerified) {
        try {
          await appLogic.benefitsApplications.update(
            claim.application_id,
            formState
          );
        } catch (error) {
          console.error("Error in updating the form:", error);
        }
      }
    };
    updateForm();
  }, [
    isAddressVerified,
    appLogic.benefitsApplications,
    claim.application_id,
    formState,
  ]);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const residentialAddressProps = getFunctionalInputProps(
    "residential_address"
  );
  if (!residentialAddressProps.value) {
    residentialAddressProps.value = new AddressModel({});
  }

  const mailingAddressProps = getFunctionalInputProps("mailing_address");
  if (!mailingAddressProps.value) {
    mailingAddressProps.value = new AddressModel({});
  }

  const handleAddressSelection = (key: string) => {
    setSelectedAddressKey(key);
    setUseDefaultAddress(false);
  };

  const handleDefaultAddressSelection = () => {
    setSelectedAddressKey("default_address");
    setUseDefaultAddress(true);
  };

  return isFeatureEnabled("enableAddressValidation") ? (
    <QuestionPage
      title={t("pages.claimsAddress.title")}
      onSave={handleSave}
      continueButtonLabel={submitButtonLabel}
      backButtonLink={backButtonLink}
      backButtonLabel={backButtonLabel}
    >
      {!isReadyToSelectAddress ? (
        <React.Fragment>
          <FieldsetAddress
            errors={appLogic.errors}
            label={t("pages.claimsAddress.sectionLabel")}
            hint={t("pages.claimsAddress.hint")}
            {...residentialAddressProps}
          />
          <InputChoiceGroup
            {...getFunctionalInputProps("has_mailing_address")}
            choices={[
              {
                checked: has_mailing_address === false,
                label: t("pages.claimsAddress.choiceYes"),
                value: "false",
              },
              {
                checked: has_mailing_address === true,
                label: t("pages.claimsAddress.choiceNo"),
                value: "true",
              },
            ]}
            label={t("pages.claimsAddress.hasMailingAddressLabel")}
            hint={t("pages.claimsAddress.hasMailingAddressHint")}
            type="radio"
          />
          <ConditionalContent
            fieldNamesClearedWhenHidden={["mailing_address"]}
            getField={getField}
            clearField={clearField}
            updateFields={updateFields}
            visible={has_mailing_address}
          >
            <FieldsetAddress
              errors={appLogic.errors}
              label={t("pages.claimsAddress.mailingAddressLabel")}
              hint={t("pages.claimsAddress.mailingAddressHint")}
              addressType="mailing"
              {...mailingAddressProps}
            />
          </ConditionalContent>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {availableAddresses.length > 0 && (
            <InputChoiceGroup
              name="selectedAddress"
              choices={availableAddresses.map((address) => ({
                label: address.address,
                value: address.global_address_key,
                checked: selectedAddressKey === address.global_address_key,
              }))}
              label={t("pages.claimsAddress.addressSuggestion")}
              onChange={(e) => handleAddressSelection(e.target.value)}
              type="radio"
            />
          )}
          <InputChoiceGroup
            name="selectedAddress"
            choices={[
              {
                label: `${residentialAddressProps.value.line_1}, ${residentialAddressProps.value.city}, ${residentialAddressProps.value.state} ${residentialAddressProps.value.zip}`,
                value: "default_address",
                checked: selectedAddressKey === "default_address",
              },
            ]}
            label={defaultAddressSelectionLabel}
            onChange={(_e) => handleDefaultAddressSelection()}
            type="radio"
          />
        </React.Fragment>
      )}
    </QuestionPage>
  ) : (
    <QuestionPage title={t("pages.claimsAddress.title")} onSave={handleSave}>
      <FieldsetAddress
        errors={appLogic.errors}
        label={t("pages.claimsAddress.sectionLabel")}
        hint={t("pages.claimsAddress.hint")}
        {...residentialAddressProps}
      />
      <InputChoiceGroup
        {...getFunctionalInputProps("has_mailing_address")}
        choices={[
          {
            checked: has_mailing_address === false,
            label: t("pages.claimsAddress.choiceYes"),
            value: "false",
          },
          {
            checked: has_mailing_address === true,
            label: t("pages.claimsAddress.choiceNo"),
            value: "true",
          },
        ]}
        label={t("pages.claimsAddress.hasMailingAddressLabel")}
        hint={t("pages.claimsAddress.hasMailingAddressHint")}
        type="radio"
      />
      <ConditionalContent
        fieldNamesClearedWhenHidden={["mailing_address"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={has_mailing_address}
      >
        <FieldsetAddress
          errors={appLogic.errors}
          label={t("pages.claimsAddress.mailingAddressLabel")}
          hint={t("pages.claimsAddress.mailingAddressHint")}
          addressType="mailing"
          {...mailingAddressProps}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(Address);
